import React, { useState } from 'react';
import { ViewSvgIcon } from '../../Assets/Svgs';
import ApplicationString from '../../Constants/applicationString';
import { IAllContactData } from '../../Interfaces/Contact';
import { IColumn } from '../../Interfaces/form';
import {
  IPaginationValues,
  ISortParamValues,
} from '../../Interfaces/interfaces';
import Table from '../Common/Table';
import TableHeading from '../Common/TableHeading';
import AttachmentModal from './AttachmentModal';
import { renderNAonEmpty } from '../../Utils/utils';

const ContactComponent = ({
  contactData,
  pagination,
  setPagination,
  setPaginationSortAndSearchTrigger,
  setSortParams,
  handlePageChange,
  handlePageSizeChange,
  sortParams,
}: {
  contactData: IAllContactData[];
  pagination: IPaginationValues;
  setPagination: (val: IPaginationValues) => void;
  setPaginationSortAndSearchTrigger: (val: boolean) => void;
  setSortParams: (val: ISortParamValues) => void;
  handlePageChange: (value: number) => void;
  handlePageSizeChange: (value: number) => void;
  sortParams: ISortParamValues;
}): JSX.Element => {
  const [showConfirm, setShowConfirm] = useState<{
    show: boolean;
    path: string;
  }>({ show: false, path: '' });
  const [zIndex, setZIndex] = useState<boolean>(false);

  const handleCancel = (): void => {
    setShowConfirm({ ...showConfirm, show: false });
  };

  const column: IColumn<IAllContactData>[] = [
    {
      title: ApplicationString.module.contact.table.columns.id,
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: ApplicationString.module.contact.table.columns.name,
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: ApplicationString.module.contact.table.columns.email,
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: ApplicationString.module.contact.table.columns.message,
      dataIndex: 'message',
      key: 'message',
      sorter: true,
      render: (data) => {
        return renderNAonEmpty(data.message);
      },
    },
    {
      title: ApplicationString.module.contact.table.columns.attachmentPath,
      dataIndex: 'attachmentPath',
      key: 'action',
      sorter: false,
      render: (data) => {
        return (
          <div
            className="flex justify-center items-center"
            aria-label="attachmentPath"
            role="button"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setShowConfirm({ show: true, path: data.attachmentPath });
              }
            }}
            tabIndex={0}
            onClick={() => {
              if (data.attachmentPath === '') {
                setShowConfirm({ show: false, path: '' });
              } else {
                setShowConfirm({ show: true, path: data.attachmentPath });
              }
            }}
          >
            <ViewSvgIcon />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <TableHeading
        hideFilter
        title={ApplicationString.table.heading.contactManagement}
      />

      <Table<IAllContactData>
        dataSource={contactData}
        columns={column}
        pageSize={pagination.pageSize}
        onPageSizeChange={handlePageSizeChange}
        totalItems={pagination.totalItems}
        onPageChange={handlePageChange}
        totalRecords={pagination.totalItems}
        setSortParams={setSortParams}
        setPaginationSortAndSearchTrigger={setPaginationSortAndSearchTrigger}
        setPagination={setPagination}
        pagination={pagination}
        sortParams={sortParams}
        zIndex={zIndex}
        setZIndex={setZIndex}
      />
      {showConfirm.show && (
        <AttachmentModal onCancel={handleCancel} path={showConfirm.path} />
      )}
    </>
  );
};

export default ContactComponent;
