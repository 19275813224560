import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Eye, EyeOff, Mail } from 'react-feather';
import Logo from '../../Assets/Svgs/Group 1.svg';
import cssUtils from '../../Utils/cssUtils';
import { commonConfig } from '../../Constants/commonConfig';
import regexConfig from '../../Constants/RegexConfig';
import { LoginPasswordMinLength } from '../../Utils/enums';
import ApplicationString from '../../Constants/applicationString';

interface LoginContainerProps {
  handleSubmit: (values: LoginFormInitialValues) => void;
}

interface LoginFormInitialValues {
  email: string;
  password: string;
}

const initialValues: LoginFormInitialValues = {
  email: '',
  password: '',
};

const loginFormSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      regexConfig.email,
      ApplicationString.module.login.validationMessages.email.regexMatch
    )
    .required(ApplicationString.module.login.validationMessages.email.required),
  password: Yup.string()
    .min(
      LoginPasswordMinLength,
      ApplicationString.module.login.validationMessages.password.min
    )
    .matches(
      regexConfig.loginPassword,
      ApplicationString.module.login.validationMessages.password.regexMatch
    )
    .required(
      ApplicationString.module.login.validationMessages.password.required
    ),
});

const LoginComponent = ({ handleSubmit }: LoginContainerProps): JSX.Element => {
  const [togglePassword, setTogglePassword] = useState(false);
  return (
    <div className="flex h-screen min-h-full  flex-col justify-center  px-6 py-12 lg:px-8">
      <div>
        <div className="flex flex-wrap items-center justify-center">
          <div className="w-full xl:w-1/2 ">
            <div className="w-full h-fit p-4 pt-0 sm:pt-0 xl:pt-0 sm:p-12.5 xl:p-17.5 rounded-smborder  border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <img
                className="mx-auto h-40 w-auto"
                src={Logo}
                alt={commonConfig.CompanyName}
              />
              <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
                {ApplicationString.module.login.title}
              </h2>

              <Formik
                initialValues={initialValues}
                validationSchema={loginFormSchema}
                validateOnChange
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="mb-4">
                    <label htmlFor="email" className={cssUtils.labelClasses}>
                      {ApplicationString.module.login.label.email}
                    </label>
                    <div className="relative">
                      <Field
                        name="email"
                        type="text"
                        placeholder={
                          ApplicationString.module.login.placeholder.email
                        }
                        className={cssUtils.inputClasses}
                      />
                      <Mail className="absolute right-4 top-4" />
                    </div>
                    <span className="mt-1 text-red-500">
                      <ErrorMessage name="email" />
                    </span>
                  </div>

                  <div className="mb-6">
                    <label htmlFor="password" className={cssUtils.labelClasses}>
                      {ApplicationString.module.login.label.password}
                    </label>
                    <div className="relative">
                      <Field
                        name="password"
                        type={togglePassword ? 'text' : 'password'}
                        placeholder={
                          ApplicationString.module.login.placeholder.password
                        }
                        className={cssUtils.inputClasses}
                      />
                      <button
                        type="button"
                        className="absolute right-4 top-4"
                        onClick={() => setTogglePassword(!togglePassword)}
                      >
                        {togglePassword ? <Eye /> : <EyeOff />}
                      </button>
                    </div>
                    <span className="mt-1 text-red-500">
                      <ErrorMessage name="password" />
                    </span>
                  </div>

                  <button type="submit" className={`w-full ${cssUtils.button}`}>
                    {ApplicationString.module.login.title}
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
