/* eslint-disable consistent-return */
// Todo : Need to refactor the upload return code
/* eslint-disable react-hooks/exhaustive-deps */
// TODO: When I add this dependency specified in eslint warning, my API is calling in infinite time.
import React, { useEffect, useState } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';
import { useDispatch } from 'react-redux';
import UpdateProfileComponent from '../../Components/UpdateProfile';
import { ApiRequest } from '../../Api/api';
import { URLS } from '../../Constants/urls';
import {
  getLocalStorageAccountInfo,
  handleApiError,
  updateLocalStorageAccountInfoMultiple,
} from '../../Utils/utils';
import { useToast } from '../../Services/ToastService';
import { IApiErrorResponse } from '../../Interfaces/Common/api.interface';
import { LoggedInUserInfoDataType } from '../../Interfaces/Login/login.interface';
import { IUpdateProfileValues } from '../../Interfaces/UpdateProfile';
import {
  localStorageEnums,
  updateProfilePhotoFilesFormats,
  uploadMaxFileSize,
} from '../../Utils/enums';
import ApplicationString from '../../Constants/applicationString';
import { HIDE_LOADER, SHOW_LOADER } from '../../Constants/actionType';

const UpdateProfileContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const tost = useToast();
  const [updateProfileData, setUpdateProfileData] =
    useState<IUpdateProfileValues>({
      id: '',
      name: '',
      email: '',
      mobile: '',
      isOwner: false,
      avatarPath: '',
      isEnabled: true,
      address: '',
      address2: '',
      country: '',
      city: '',
      state: '',
      zip: '',
    });
  const [avatarPath, setAvatarPath] = useState<string>('');
  const [isUploadingProfilePhoto, setIsUploadingProfilePhoto] =
    useState<boolean>(false);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);

  // Get user details
  const getUpdateProfileUserDetails = async (): Promise<void> => {
    const loggedInUserData =
      getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
        localStorageEnums.userInfo
      );
    setIsFormTouched(false);
    try {
      dispatch({ type: SHOW_LOADER });
      const res = await ApiRequest.get(
        URLS.UPDATE_PROFILE.replace(
          '{accountId}',
          `${loggedInUserData?.accountId}`
        )
      );
      if (
        res?.status === HttpStatusCode.Ok &&
        res?.data &&
        JSON.stringify(res.data) !== '{}'
      ) {
        const { data } = res;
        setUpdateProfileData(data);
        if (data.avatarPath) {
          setAvatarPath(data.avatarPath);
        }
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, tost);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };

  // Update profile photo
  const updateProfilePhoto = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = event.target.files && event.target.files[0];
    const formdata = new FormData();

    if (!file) return;

    const isRightFormat = updateProfilePhotoFilesFormats.includes(file.type);

    if (!isRightFormat) {
      tost.error(ApplicationString.module.updateProfile.tostMsg.fileFormat);
      return;
    }

    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > uploadMaxFileSize) {
      tost.error(ApplicationString.module.updateProfile.tostMsg.fileMaxSize);
      return;
    }

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async () => {
        formdata.append('file', file);
        formdata.append('mediaUploadedFor', 'profilePicture');
        try {
          setIsUploadingProfilePhoto(true);
          const res = await ApiRequest.post(
            URLS.UPLOAD_PROFILE_PHOTO,
            formdata
          );
          if (res?.status === HttpStatusCode.Created) {
            setAvatarPath(res.data.path);
            resolve();
          }
        } catch (error) {
          setIsUploadingProfilePhoto(false);
          const axiosError = error as AxiosError<IApiErrorResponse>;
          handleApiError(axiosError, tost);
          reject(error);
        }
      };
      img.onerror = reject;
    });
  };

  // Update user details
  const updateUserDetails = async (
    values: IUpdateProfileValues
  ): Promise<void> => {
    const loggedInUserData =
      getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
        localStorageEnums.userInfo
      );
    try {
      dispatch({ type: SHOW_LOADER });
      const res = await ApiRequest.put(
        URLS.UPDATE_PROFILE.replace(
          '{accountId}',
          `${loggedInUserData?.accountId}`
        ),
        {
          ...values,
          avatarPath,
        }
      );
      setIsFormTouched(false);
      dispatch({ type: HIDE_LOADER });
      if (res?.status === HttpStatusCode.NoContent) {
        tost.success(ApplicationString.module.updateProfile.tostMsg.success);
        getUpdateProfileUserDetails();
        updateLocalStorageAccountInfoMultiple(dispatch, {
          userName: values.name,
          email: values.email,
          avatarPath,
        });
      }
    } catch (error) {
      dispatch({ type: HIDE_LOADER });
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, tost);
    }
  };
  useEffect(() => {
    getUpdateProfileUserDetails();
  }, []);

  return (
    <UpdateProfileComponent
      data={updateProfileData}
      avatarPath={avatarPath}
      setIsUploadingProfilePhoto={setIsUploadingProfilePhoto}
      isUploadingProfilePhoto={isUploadingProfilePhoto}
      updateUserDetails={updateUserDetails}
      getUserDetails={getUpdateProfileUserDetails}
      updateProfilePhoto={updateProfilePhoto}
      isFormTouched={isFormTouched}
      setIsFormTouched={setIsFormTouched}
    />
  );
};

export default UpdateProfileContainer;
