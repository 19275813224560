/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * TODO: Disabling this error because I tried to solve it and it was solved by adding the button inside a link.
 * However, I then found a new error in the link where it was not accepting the '#' in the path.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronDown, LogOut, User } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLocalStorageAccountInfo,
  removeLocalStorageAccountInfo,
  toCamelCase,
} from '../../../Utils/utils';
import InternalRoute from '../../../Utils/internalRoutes';
import { commonConfig } from '../../../Constants/commonConfig';
import { RootState } from '../../../Interfaces/interfaces';
import { LoggedInUserInfoDataType } from '../../../Interfaces/Login/login.interface';
import TooltipComponent from '../Tooltip';
import { localStorageEnums } from '../../../Utils/enums';
import ApplicationString from '../../../Constants/applicationString';

// Book,Settings, User
const DropdownUser = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const loggedInUserDataRedux = useSelector(
    (state: RootState) => state.auth.userData
  );

  const [loggedInUserInfo, setLoggedInUserInfo] =
    useState<LoggedInUserInfoDataType | null>(null);

  useEffect(() => {
    if (
      loggedInUserDataRedux &&
      JSON.stringify(loggedInUserDataRedux) !== '{}'
    ) {
      setLoggedInUserInfo(loggedInUserDataRedux);
    } else {
      const userData = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
        localStorageEnums.userInfo
      );
      setLoggedInUserInfo(userData);
    }
  }, [loggedInUserDataRedux]);

  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const handleLogoutClick = () => {
    removeLocalStorageAccountInfo(dispatch, navigate);
  };

  return (
    <div className="relative">
      {/* <!-- Dropdown Start --> */}
      <Link
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="flex items-center gap-4"
        to="#"
      >
        <span className="hidden text-right lg:block">
          <TooltipComponent
            message={
              loggedInUserInfo?.userName
                ? toCamelCase(loggedInUserInfo.userName)
                : ApplicationString.common.notAvailable
            }
            additionalClass="top-0 left-0 transform -translate-x-full"
          >
            <span className="block text-sm font-medium text-black dark:text-white w-40 overflow-ellipsis overflow-hidden whitespace-nowrap">
              {loggedInUserInfo?.userName
                ? toCamelCase(loggedInUserInfo.userName)
                : ApplicationString.common.notAvailable}
            </span>
          </TooltipComponent>
          <span className="block text-xs  w-40 overflow-ellipsis overflow-hidden whitespace-nowrap">
            {loggedInUserInfo?.email || ApplicationString.common.notAvailable}
          </span>
        </span>
        <span className="h-10 w-10  flex items-center justify-center rounded-full bg-slate-400 text-white font-bold text-xl ">
          {loggedInUserInfo?.avatarPath && (
            <img
              src={`${loggedInUserInfo.avatarPath.includes('http') ? loggedInUserInfo.avatarPath : `${commonConfig.UploadUrl}/${loggedInUserInfo.avatarPath}`}`}
              alt={loggedInUserInfo?.userName}
              className="h-10 w-10 rounded-full"
            />
          )}
        </span>
        <ChevronDown />
      </Link>
      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`absolute right-0 mt-4 flex w-62.5 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark ${
          dropdownOpen === true ? 'block' : 'hidden'
        }`}
      >
        <button
          type="button"
          className="flex items-center z-100 gap-3.5 px-6 py-4 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
          aria-label="Update Profile"
        >
          <Link
            to={InternalRoute.UpdateProfile}
            className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
          >
            <User />
            {ApplicationString.module.dropdownUser.options.updateProfile}
          </Link>
        </button>
        <button
          type="button"
          className="flex items-center z-100 gap-3.5 px-6 py-4 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
          onClick={handleLogoutClick}
        >
          <Link
            to={InternalRoute.Logout}
            className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
          >
            <LogOut />
            {ApplicationString.module.dropdownUser.options.logout}
          </Link>
        </button>
      </div>
      {/* <!-- Dropdown End --> */}
    </div>
  );
};

export default DropdownUser;
