import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HomeSvgIcon, RightArrowSvgIcon } from '../../../Assets/Svgs';
import { CapitalizeWords } from '../../../Utils/utils';

const Breadcrumb: React.FC = () => {
  const { pathname } = useLocation();
  const [layout, page] = pathname.split('/').filter((el) => el !== '');

  const formatBreadcrumbName = (name: string) => {
    return name
      .split('-')
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ');
  };

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        <li className="inline-flex items-center">
          <Link
            to="/"
            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
          >
            <HomeSvgIcon />
            Home
          </Link>
        </li>
        <li>
          {layout && (
            <div className="flex items-center">
              <Link
                to={`/${layout}`}
                className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
              >
                <RightArrowSvgIcon />
                <span
                  // href="#"
                  className="ms-1 text-sm font-medium text-gray-70 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                >
                  {formatBreadcrumbName(layout)}
                </span>
              </Link>
            </div>
          )}
        </li>
        <li aria-current="page">
          {page && (
            <div className="flex items-center">
              <RightArrowSvgIcon />
              <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                {CapitalizeWords(page)}
              </span>
            </div>
          )}
        </li>
      </ol>
    </nav>
  );
};

export default Breadcrumb;
