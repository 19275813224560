/* eslint-disable @typescript-eslint/default-param-last */
// -- TODO: disabling this error as redux is not accepting the state as 2nd parameter

import {
  GLOBAL_HIDE_LOADER,
  GLOBAL_SHOW_LOADER,
} from '../../Constants/actionType';
import { ILoaderStateInterface } from '../../Interfaces/interfaces';

const initialState: ILoaderStateInterface = {
  isLoading: false,
};

interface IReduxAction {
  type: string;
}

const SpinnerReducer = (
  state = initialState,
  action: IReduxAction
): ILoaderStateInterface => {
  switch (action.type) {
    case GLOBAL_SHOW_LOADER:
      return { ...state, isLoading: true };
    case GLOBAL_HIDE_LOADER:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default SpinnerReducer;
