/* eslint-disable @typescript-eslint/default-param-last */
// -- TODO: disabling this error as redux is not accepting the state as 2nd parameter
import { IApiResponseData } from '../../Interfaces/Common/api.interface';
import { IAllUsersData } from '../../Interfaces/Users';
import {
  DELETE_USERS,
  SET_ALL_USERS,
  SET_USER_ENABLED,
  SET_USER_OWNER_APPROVED,
} from '../../Constants/actionType';

interface IUserStateInterface {
  allUsers: boolean | IApiResponseData<IAllUsersData> | undefined;
}

interface IReduxAction {
  type: string;
  payload?: IApiResponseData<IAllUsersData>;
}

const initialState: IUserStateInterface = {
  allUsers: false,
};

const UsersReducer = (
  state: IUserStateInterface = initialState,
  action: IReduxAction
): IUserStateInterface => {
  switch (action.type) {
    case SET_ALL_USERS:
      return { ...state, allUsers: action.payload };
    case SET_USER_OWNER_APPROVED:
      return { ...state, allUsers: false };
    case SET_USER_ENABLED:
      return { ...state, allUsers: false };
    case DELETE_USERS:
      return { ...state, allUsers: false };
    default:
      return state;
  }
};
export default UsersReducer;
