import axios, { AxiosInstance, AxiosResponse, HttpStatusCode } from 'axios';

import React from 'react';

import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../Constants/urls';
import {
  getLocalStorageAccountInfo,
  getUserAvatarpath,
  removeLocalStorageAccountInfo,
  setLocalStorageAccountInfo,
} from '../Utils/utils';
import { commonConfig } from '../Constants/commonConfig';
import { localStorageEnums, refreshTokenCallTime } from '../Utils/enums';
import { LoggedInUserInfoDataType } from '../Interfaces/Login/login.interface';
import { useToast } from '../Services/ToastService';
import ApplicationString from '../Constants/applicationString';

export const ApiRequest: AxiosInstance = axios.create({
  baseURL: commonConfig.ApiUrl,
  headers: {
    Accept: '*/*',
  },
});

interface AxiosInterceptorProps {
  children: React.ReactNode;
}

const getRefreshToken = async () => {
  const refreshToken = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  )?.refreshToken;
  const res = await ApiRequest.post(URLS.REFRESH_TOKEN, {
    refreshToken,
  });
  if (
    res?.status !== HttpStatusCode.Ok ||
    !res?.data ||
    JSON.stringify(res.data) === '{}'
  ) {
    throw new Error(ApplicationString.API.refreshToken.error);
  } else {
    return res.data;
  }
};

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function convertToString(c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

let isRefreshTokenFetching = false;

const AxiosInterceptor = ({
  children,
}: AxiosInterceptorProps): React.ReactElement | null => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  // Request interceptor
  ApiRequest.interceptors.request.use(
    async (config) => {
      const ApiConfig = config;
      const accessToken = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
        localStorageEnums.userInfo
      )?.accessToken;
      if (accessToken) {
        ApiConfig.headers.Authorization = `Bearer ${accessToken}`;
        const jwtData = parseJwt(accessToken);
        const differenceInSeconds = dayjs(jwtData.exp * 1000).diff(
          dayjs(),
          'seconds'
        );
        if (differenceInSeconds <= refreshTokenCallTime) {
          if (!isRefreshTokenFetching) {
            isRefreshTokenFetching = true;
            try {
              const refreshedLoggedInUserData = await getRefreshToken();
              setLocalStorageAccountInfo(
                dispatch,
                refreshedLoggedInUserData,
                localStorageEnums.userInfo
              );
              getUserAvatarpath(dispatch, toast, ApiRequest);
            } catch (error) {
              removeLocalStorageAccountInfo(dispatch, navigate);
            } finally {
              isRefreshTokenFetching = false;
            }
          }
        }
      }
      return ApiConfig;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );

  // let isRefreshTokenFetching = false;
  // let failedApis: AxiosRequestConfig<unknown>[] = [];
  /**
 * Interceptor for handling responses from all requests made using the ApiRequest instance.
 * This interceptor simply returns the response object without any modifications, acting as a pass-through.
 
 * @param {AxiosResponse} response - The response object from the Axios request.
 * @returns {AxiosResponse} The unmodified response object.
 */

  // Response interceptor
  ApiRequest.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error) => {
      if (error.response?.status === HttpStatusCode.Unauthorized) {
        removeLocalStorageAccountInfo(dispatch, navigate);
      }
      return Promise.reject(error);
    }
  );

  return React.isValidElement(children) ? children : null;
};
export default AxiosInterceptor;
