import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Interfaces/interfaces';

const Loader: React.FC = () => {
  const { isLoading } = useSelector((state: RootState) => state.loader);
  return isLoading ? (
    <div className="fixed top-0 left-0 w-full min-h-screen flex items-center justify-center bg-slate-900/20 bg-opacity-50 z-50">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-500" />
    </div>
  ) : null;
};

export default Loader;
