// login
export const LoginPasswordMinLength = 6;

// refresh token - if token expiry time is near to this time, will refresh the token
export const refreshTokenCallTime = 900; // in seconds

// local storage user enum
export const localStorageEnums = {
  userInfo: 'userInfo',
  settings: 'settings',
};

// roles
export const userRoleEnums = {
  ADMIN: 'admin',
  USER: 'user',
};

export const minMaxYear = {
  minYear: 1990,
  maxYear: new Date().getFullYear(),
};

// Update Profile
export const updateProfileEnums = {
  fullName: {
    min: 2,
    max: 255,
  },
  address: {
    min: 5,
    max: 255,
  },
  city: {
    min: 2,
    max: 50,
  },
  state: {
    min: 2,
    max: 50,
  },
  country: {
    min: 2,
    max: 50,
  },
  zipCode: {
    min: 2,
    max: 10,
  },
};
// update profile country options
export const countryOptions = [
  { value: 'US', label: 'United States' },
  { value: 'CA', label: 'Canada' },
];
export const vehicleStatusOptions = [
  { value: 'true', label: 'Activate' },
  { value: 'false', label: 'Deactivate' },
];
export const bookingStatusOptions = [
  { value: 'requested', label: 'Requested' },
  { value: 'active', label: 'Active' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'completed', label: 'Completed' },
];

export const enableStatusOptions = [
  { value: 'true', label: 'Enable' },
  { value: 'false', label: 'Disable' },
];

export const approveStatusOptions = [
  { value: 'true', label: 'Approve' },
  { value: 'false', label: 'Disapprove' },
];

// update profile photo formats
export const updateProfilePhotoFilesFormats = [
  'image/png',
  'image/jpeg',
  'image/jpg',
];

// upload max file size
export const uploadMaxFileSize = 5; // in MB

export const arr = ['avatarPath', 'attachmentPath'];
