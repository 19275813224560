import React from 'react';
import NotAuthorized from '../Screen/ErrorPages/notAuthorize/notAuthorized';
import Notfound from '../Screen/ErrorPages/notFound/notFound';
import HomeScreen from '../Screen/homeScreen/homeScreen';
import LoginScreen from '../Screen/Auth/authScreen';
import UserScreen from '../Screen/UserListing';
// import VehicleScreen from '../Screen/VehicleListing';
// import BookingScreen from '../Screen/Bookings';
// import TransactionScreen from '../Screen/Transactions';
import MaintenancePage from '../Components/Common/MaintenancePage';
import WelcomePage from '../Components/Common/WelcomePage';
import InternalRoute from '../Utils/internalRoutes';
import { userRoleEnums } from '../Utils/enums';
import UpdateProfileScreen from '../Screen/UpdateProfile';
import VehicleScreen from '../Screen/VehicleListing';
import VehicleUpdateScreen from '../Screen/VehicleListing/VehicleUpdate';
import ContactScreen from '../Screen/Contact';
import BookingScreen from '../Screen/Bookings';

type RouteConfigType = {
  path: string;
  element: React.ReactElement;
  isProtectedRoute: boolean;
  allowedLayout: string;
  allowedRoles: string[];
};

const RouteConfig: RouteConfigType[] = [
  {
    path: InternalRoute.Dashboard,
    element: <HomeScreen />,
    isProtectedRoute: true,
    allowedLayout: userRoleEnums.ADMIN,
    allowedRoles: [userRoleEnums.ADMIN],
  },
  {
    path: InternalRoute.UserManagement,
    element: <UserScreen />,
    isProtectedRoute: true,
    allowedLayout: userRoleEnums.ADMIN,
    allowedRoles: [userRoleEnums.ADMIN],
  },
  {
    path: InternalRoute.VehicleManagement,
    element: <VehicleScreen />,
    isProtectedRoute: true,
    allowedLayout: 'admin',
    allowedRoles: ['admin'],
  },
  {
    path: InternalRoute.VehicleUpdate,
    element: <VehicleUpdateScreen />,
    isProtectedRoute: true,
    allowedLayout: userRoleEnums.ADMIN,
    allowedRoles: [userRoleEnums.ADMIN],
  },
  {
    path: InternalRoute.BookingManagement,
    element: <BookingScreen />,
    isProtectedRoute: true,
    allowedLayout: userRoleEnums.ADMIN,
    allowedRoles: [userRoleEnums.ADMIN],
  },
  {
    path: InternalRoute.ContactManagement,
    element: <ContactScreen />,
    isProtectedRoute: true,
    allowedLayout: userRoleEnums.ADMIN,
    allowedRoles: [userRoleEnums.ADMIN],
  },
  {
    path: InternalRoute.Transactions,
    element: <MaintenancePage />,
    isProtectedRoute: true,
    allowedLayout: userRoleEnums.ADMIN,
    allowedRoles: [userRoleEnums.ADMIN],
  },
  {
    path: InternalRoute.Home,
    element: <WelcomePage />,
    isProtectedRoute: true,
    allowedLayout: 'main',
    allowedRoles: [userRoleEnums.ADMIN],
  },
  {
    path: InternalRoute.UpdateProfile,
    element: <UpdateProfileScreen />,
    isProtectedRoute: true,
    allowedLayout: userRoleEnums.ADMIN,
    allowedRoles: [userRoleEnums.ADMIN],
  },
  {
    path: InternalRoute.Login,
    element: <LoginScreen />,
    isProtectedRoute: false,
    allowedLayout: '',
    allowedRoles: [userRoleEnums.ADMIN],
  },
  {
    path: InternalRoute.Unauthorized,
    element: <NotAuthorized />,
    isProtectedRoute: false,
    allowedLayout: 'main',
    allowedRoles: [userRoleEnums.ADMIN],
  },
  {
    path: '*',
    element: <Notfound />,
    isProtectedRoute: false,
    allowedLayout: '',
    allowedRoles: [userRoleEnums.ADMIN],
  },
];

export default RouteConfig;
