import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError, HttpStatusCode } from 'axios';
import { useDispatch } from 'react-redux';
import LoginComponent from '../../Components/Auth/authComponent';
import { ApiRequest } from '../../Api/api';
import {
  getUserAvatarpath,
  handleApiError,
  setLocalStorageAccountInfo,
} from '../../Utils/utils';
import InternalRoute from '../../Utils/internalRoutes';
import { useToast } from '../../Services/ToastService';
import { IApiErrorResponse } from '../../Interfaces/Common/api.interface';
import { URLS } from '../../Constants/urls';
import { IUserSettingsData } from '../../Interfaces/Settings';
import { localStorageEnums } from '../../Utils/enums';
import {
  GLOBAL_HIDE_LOADER,
  GLOBAL_SHOW_LOADER,
  SET_USER_SETTINGS,
} from '../../Constants/actionType';

interface LoginFormInitialValues {
  email: string;
  password: string;
}

const LoginContainer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const getUserSettings = async () => {
    try {
      const response = await ApiRequest.get(URLS.GET_USER_SETTINGS);
      if (response?.status === HttpStatusCode.Ok) {
        const data: IUserSettingsData = response.data[0];
        setLocalStorageAccountInfo(dispatch, data, 'settings');
        dispatch({ type: SET_USER_SETTINGS, payload: data });
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    }
  };

  const handleSubmit = async (values: LoginFormInitialValues) => {
    try {
      dispatch({ type: GLOBAL_SHOW_LOADER });
      const res = await ApiRequest.post(URLS.ACCOUNT_LOGIN, {
        userName: values.email,
        password: values.password,
      });
      dispatch({ type: GLOBAL_HIDE_LOADER });
      if (
        res?.status === HttpStatusCode.Ok &&
        res?.data &&
        JSON.stringify(res.data) !== '{}'
      ) {
        const { data } = res;
        setLocalStorageAccountInfo(dispatch, data, localStorageEnums.userInfo);
        getUserSettings();
        getUserAvatarpath(dispatch, toast, ApiRequest);
        navigate(InternalRoute.Home);
      }
    } catch (error) {
      dispatch({ type: GLOBAL_HIDE_LOADER });
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    }
  };

  return <LoginComponent handleSubmit={handleSubmit} />;
};

export default LoginContainer;
