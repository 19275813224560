/* eslint-disable @typescript-eslint/default-param-last */
// -- TODO: disabling this error as redux is not accepting the state as 2nd parameter

import {
  AUTH_GET_USER_DATA,
  AUTH_REMOVE_USER_DATA,
} from '../../Constants/ActionTypes';

import { LoggedInUserInfoDataType } from '../../Interfaces/Login/login.interface';

interface IActionType {
  type: string;
  payload: LoggedInUserInfoDataType;
}
const initialState = {
  userData: {},
};

const authReducer = (
  state = initialState,
  action: IActionType
): typeof initialState => {
  switch (action.type) {
    case AUTH_GET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case AUTH_REMOVE_USER_DATA:
      return {
        ...state,
        userData: false,
      };
    default:
      return state;
  }
};

export default authReducer;
