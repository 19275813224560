import React from 'react';
import { Link } from 'react-router-dom';
import notFoundIllustration from '../../../Assets/Images/404.png';
import ApplicationString from '../../../Constants/applicationString';

const Notfound: React.FC = () => {
  return (
    <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 min-h-screen items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
      <div className=" w-full xl:w-1/2 relative pb-12 lg:pb-0">
        <div className="relative">
          <h1 className="mb-4 text-5xl tracking-tight font-extrabold lg:text-7xl text-primary-600 dark:text-primary-500 flex items-end gap-3">
            {ApplicationString.module.notFoundPage.title}
          </h1>
          <h1 className="my-2 text-gray-800 font-bold text-2xl">
            {ApplicationString.module.notFoundPage.description1}
          </h1>
          <p className="my-2 text-gray-800">
            {ApplicationString.module.notFoundPage.description2}
          </p>
          <Link to="/">
            <button
              type="button"
              className="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50"
            >
              {ApplicationString.module.notFoundPage.button}
            </button>
          </Link>
        </div>
      </div>
      <div>
        <img src={notFoundIllustration} alt="" />
      </div>
    </div>
  );
};

export default Notfound;
