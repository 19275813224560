import { ReactNode, useEffect, useState } from 'react';
import { AxiosError, AxiosInstance, HttpStatusCode } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';
import { ToastContextType } from '../Services/ToastService';
import { IApiErrorResponse } from '../Interfaces/Common/api.interface';
import InternalRoute from './internalRoutes';
import {
  AUTH_GET_USER_DATA,
  AUTH_REMOVE_USER_DATA,
} from '../Constants/ActionTypes';
import { REMOVE_USER_SETTINGS } from '../Constants/actionType';
import { URLS } from '../Constants/urls';
import { localStorageEnums } from './enums';
import { LoggedInUserInfoDataType } from '../Interfaces/Login/login.interface';
import ApplicationString from '../Constants/applicationString';

// this function will return true if value is empty,undefine,length zero, object null other wise false
export const isNullOrEmpty = (value: unknown): boolean => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true;
  }

  return false;
};

export const isJson = (jsonstring: string): boolean => {
  try {
    JSON.parse(jsonstring);
    return true;
  } catch (error) {
    return false;
  }
};

export const getLocalStorageAccountInfo = <T>(key: string): T | null => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : null;
};
// export const getLocalStorageUserId = ():string | null => {
//   return localStorage.getItem("userID");
// };

// export const getLocalStorageUserRole = ():string | null => {
//   return localStorage.getItem("Role");
// };

export const setLocalStorageAccountInfo = <T>(
  dispatch: Dispatch,
  data: T,
  localStorageKey: string
): void => {
  localStorage.setItem(localStorageKey, JSON.stringify(data));

  if (localStorageKey === localStorageEnums.userInfo && dispatch) {
    dispatch({ type: AUTH_GET_USER_DATA, payload: data });
  }
};
export const removeLocalStorageAccountInfo = (
  dispatch: Dispatch | undefined,
  navigate: NavigateFunction | undefined
): void => {
  localStorage.removeItem(localStorageEnums.userInfo);
  if (dispatch) {
    dispatch({ type: AUTH_REMOVE_USER_DATA });
    dispatch({ type: REMOVE_USER_SETTINGS });
  }
  if (navigate) {
    navigate(InternalRoute.Login);
  }
};

export const updateLocalStorageAccountInfo = (
  dispatch: Dispatch,
  key: string,
  value: string | null
): void => {
  const userInfo = JSON.parse(
    localStorage.getItem(localStorageEnums.userInfo) || '{}'
  );
  userInfo[key] = value;
  localStorage.setItem(localStorageEnums.userInfo, JSON.stringify(userInfo));
  if (dispatch) {
    dispatch({ type: AUTH_GET_USER_DATA, payload: userInfo });
  }
};

export const updateLocalStorageAccountInfoMultiple = (
  dispatch: Dispatch,
  values: Record<string, string | null>
): void => {
  const userInfo = JSON.parse(
    localStorage.getItem(localStorageEnums.userInfo) || '{}'
  );

  Object.keys(values).forEach((key) => {
    userInfo[key] = values[key];
  });

  localStorage.setItem(localStorageEnums.userInfo, JSON.stringify(userInfo));
  if (dispatch) {
    dispatch({ type: AUTH_GET_USER_DATA, payload: userInfo });
  }
};

export const toCamelCase = (str: string): string => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Converts a sorting order string to a common sorting order string.
 *
 * @param {string} sortOrder - The sorting order string to be converted.
 * @return {string} The converted sorting order string. If the input is 'ascend', it returns 'asc'. If the input is 'descend', it returns 'desc'. Otherwise, it returns an empty string.
 */
export const commonSortingOrder = (sortOrder: string): string => {
  let sortDirection = sortOrder;
  if (sortOrder === 'ascend') {
    sortDirection = 'asc';
    return sortOrder;
  }
  if (sortOrder === 'descend') {
    sortDirection = 'desc';
    return sortOrder;
  }
  sortDirection = '';
  return sortDirection;
};

/**
 * Formats a given price as a USD currency string.
 *
 * @param {number} price - The price to be formatted.
 * @return {string} The formatted price as a USD currency string.
 */
export const UsdCurrency = (price: number): string => {
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);
  return formattedPrice;
};
/**
 * Formats a given date into a string representation of the date in the format "Month Day, Year".
 *
 * @param {Date} date - The date to be formatted.
 * @return {string} The formatted date string.
 */
export const DateFormat = (date: Date): string => {
  const dateString = new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return dateString;
};
/**
 * Formats a given list of items into an array of objects with 'value' and 'label' properties.
 *
 * @param {ListItems[]} list - The list of items to be formatted.
 * @return {Array<{ value: string; label: string }>} The formatted array of objects.
 */
// export const OptionValuesFormat = (
//   list: ListItems[]
// ): { value: string; label: string } => {
//   const options = list?.map((item) => ({
//     ...item,
//     value: item.key,
//     label: item.key,
//   }));
//   return options;
// };+
/**
+ * Returns a function that checks if a given date is before the current date.
+ *
+ * @param {Date} currentDate - The current date.
+ * @return {Function} A function that takes a date and returns true if the date is before the current date, false otherwise.
+ */
export const disablePrevDates = (
  currentDate: Date
): ((date: Date) => boolean) => {
  return (date: Date) => {
    return date && date < currentDate;
  };
};
/**
 * Capitalizes the first letter of each word in a given string.
 *
 * @param {string} str - The input string to capitalize.
 * @return {string} The string with the first letter of each word capitalized.
 */
export const CapitalizeWords = (str: string): string => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};
/**
 * Debounces the given value by delaying the update of the debounced value.
 *
 * @param {string} value - The value to be debounced.
 * @param {number} delay - The delay in milliseconds before updating the debounced value.
 * @return {string} The debounced value.
 */
export const Debounce = (value: string, delay: number): string => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handle = setTimeout(() => {
      setDebouncedValue(value);
    });
    return () => {
      clearTimeout(handle);
    };
  }, [value, delay]);
  return debouncedValue;
};

/**
 * Handles errors from the API and dispatches an action to display the error message.
 *
 * @param error - The error object from the API response.
 * @param defaultErrorMessage - The default error message to display if the API response does not contain one.
 * @param dispatch - The Redux dispatch function.
 */

export const handleApiError = (
  error: AxiosError<IApiErrorResponse>,
  toast: ToastContextType,
  defaultMessage = ''
): void => {
  const errorMessage = defaultMessage || error?.response?.data?.message || '';

  const message = errorMessage?.trim();
  if (error?.response?.status !== 401) {
    toast.error(message);
  }
};

export const formatCurrency = (
  value: number,
  currencyCode?: string
): string => {
  const options = {
    style: 'currency',
    currency: currencyCode || 'USD',
    minimumFractionDigits: 0, // Ensure no decimal digits are displayed
    maximumFractionDigits: 0, // Ensure no decimal digits are displayed
  };
  const formatter = new Intl.NumberFormat('en-US', options);
  return formatter.format(value ?? 0);
};

/**
 * Converts an object of search values into a query string.
 *
 * @param {Object} searchValues - The object containing the search values.
 * @returns {string} The generated query string.
 */
export const buildQueryString = (searchValues: object): string => {
  // Convert the modified search values object into an array of key-value pairs
  const searchQuery = Object.entries(searchValues || '')
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return searchQuery;
};

/**
 * Adds 'https://' to a URL if it doesn't already have it.
 *
 * @param {string} url - The URL to be rendered.
 * @return {string} The rendered URL with 'https://' if needed.
 */
export const addHttpsToUrl = (url: string): string => {
  if (!url?.startsWith('https://')) {
    return `https://onelife.blobstation.dev/${url}`;
  }
  return url;
};

export const maxCharString = (str: string, maxLength: number): string => {
  return `${str.substring(0, maxLength)}...`;
};

/**
 * Populates an array of years starting from a given year up to the current year.
 *
 * @param {number} startYear - The starting year for populating the array.
 * @return {{ label: number; value: number }[]} The array of years with label and value pairs.
 */
export const populateYears = (
  startYear = 2000
): { label: number; value: number; key: number }[] => {
  const years: { label: number; value: number; key: number }[] = [];
  const currentYear = new Date().getFullYear();
  for (let year = startYear; year <= currentYear; year += 1) {
    years.push({ label: year, value: year, key: year });
  }
  return years;
};

export const createSelectOptions = <
  T extends object,
  I extends Array<T>,
  V extends keyof T,
  L extends keyof T,
>(
  itemList: I,
  value: V,
  label: L
): { value: T[V]; label: string }[] => {
  const options = itemList?.map((item) => ({
    value: item[value],
    label: `${item[label]}`,
  }));
  return options;
};

export const checkIfArrayIsTruthyOrNot = <T>(
  data: T | undefined | []
): T | [] => {
  if (data && Array.isArray(data) && data.length > 0) {
    return data;
  }
  return [];
};

// export const formatCurrency = (value, currencyCode) => {
//   const options = {
//     style: 'currency',
//     currency: currencyCode ? currencyCode : 'USD',
//     minimumFractionDigits: 0, // Ensure no decimal digits are displayed
//     maximumFractionDigits: 0, // Ensure no decimal digits are displayed
//   };
//   const formatter = new Intl.NumberFormat('en-US', options);
//   return formatter.format(value ?? 0);
// };

// Get user's avatar path from update profile api
export const getUserAvatarpath = async (
  dispatch: Dispatch,
  toast: ToastContextType,
  ApiRequest: AxiosInstance
): Promise<void> => {
  const loggedInUserData = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  );
  try {
    const res = await ApiRequest.get(
      URLS.UPDATE_PROFILE.replace(
        '{accountId}',
        `${loggedInUserData?.accountId}`
      )
    );
    if (
      res?.status === HttpStatusCode.Ok &&
      res?.data &&
      JSON.stringify(res.data) !== '{}'
    ) {
      const { data } = res;
      updateLocalStorageAccountInfo(dispatch, 'avatarPath', data.avatarPath);
    }
  } catch (error) {
    const axiosError = error as AxiosError<IApiErrorResponse>;
    handleApiError(axiosError, toast);
  }
};

export const renderNAonEmpty = (
  data: string | string[] | number
): ReactNode => {
  if (Array.isArray(data)) {
    return data.length > 0
      ? data.join('')
      : ApplicationString.module.booking.bookingViewDetails.bookingDetails
          .notApplicable;
  }
  return (
    data ||
    ApplicationString.module.booking.bookingViewDetails.bookingDetails
      .notApplicable
  );
};

export const greaterThanZero = (data: number): ReactNode => {
  let value;
  if (data > 0) {
    value = `${ApplicationString.module.booking.bookingViewDetails.bookingDetails.dollarSign}${data}`;
  } else if (data === 0) {
    value = `${ApplicationString.module.booking.bookingViewDetails.bookingDetails.dollarSign}${data}`;
  } else {
    value =
      ApplicationString.module.booking.bookingViewDetails.bookingDetails
        .notApplicable;
  }
  return value;
};

export const greaterThanOne = (data: number): ReactNode => {
  let value;
  if (data > 1) {
    value = `${data}${ApplicationString.module.vehicle.vehicleViewDetails.viewContent.hrs}`;
  } else if (data === 1) {
    value = `${data}${ApplicationString.module.vehicle.vehicleViewDetails.viewContent.hr}`;
  } else {
    value =
      ApplicationString.module.booking.bookingViewDetails.bookingDetails
        .notApplicable;
  }
  return value;
};
