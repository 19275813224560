import { useEffect, useRef } from 'react';

type Timer = ReturnType<typeof setTimeout>;
type SomeFunction = (...args: never[]) => void;

function useDebouncedCallback<T extends SomeFunction>(
  callback: T,
  delay: number
): T {
  const timerRef = useRef<Timer>();

  useEffect(() => {
    return () => {
      if (!timerRef.current) return;
      clearTimeout(timerRef.current);
    };
  }, []);

  const debouncedFunction = ((...args: never[]) => {
    const newTimer = setTimeout(() => {
      callback(...args);
    }, delay);
    clearTimeout(timerRef.current);
    timerRef.current = newTimer;
  }) as T;

  return debouncedFunction;
}
export default useDebouncedCallback;
