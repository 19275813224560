import React from 'react';
import DrawerWrapper from '../../Common/DrawerWrapper';
import { IAllUsersData } from '../../../Interfaces/Users';
import { addHttpsToUrl, renderNAonEmpty } from '../../../Utils/utils';
import ApplicationString from '../../../Constants/applicationString';

const UserView: React.FC<{
  onDrawerClose: () => void;
  usersData: IAllUsersData;
  title: string;
}> = ({ onDrawerClose, usersData, title }) => {
  const email = renderNAonEmpty(usersData.email);

  const address = renderNAonEmpty(usersData.address);

  const address2 = renderNAonEmpty(usersData.address2);

  return (
    <DrawerWrapper visible onClose={onDrawerClose} title={title}>
      <div className="w-full flex flex-col gap-5 mt-4">
        <div className="flex gap-2">
          <h3 className="text-base font-semibold text-gray-900">
            {ApplicationString.module.user.userViewDetails.viewContent.name}
          </h3>
          <span className="text-md text-gray-500 mr-60">{usersData?.name}</span>
        </div>
        <div className="flex gap-2">
          <h3 className="text-base font-semibold text-gray-900">
            {ApplicationString.module.user.userViewDetails.viewContent.email}
          </h3>
          <span className="text-md text-gray-500">{email}</span>
        </div>
        <div className="flex gap-2">
          <h3 className="text-base font-semibold text-gray-900">
            {ApplicationString.module.user.userViewDetails.viewContent.mobile}
          </h3>
          <span className="text-md text-gray-500">{usersData?.mobile}</span>
        </div>

        <div className="flex gap-2">
          <h3 className="text-base font-semibold text-gray-900">
            {ApplicationString.module.user.userViewDetails.viewContent.address1}
          </h3>
          <span className="text-md text-gray-500">{address}</span>
        </div>

        <div className="flex gap-2">
          <h3 className="text-base font-semibold text-gray-900">
            {ApplicationString.module.user.userViewDetails.viewContent.address2}
          </h3>
          <span className="text-md text-gray-500">{address2}</span>
        </div>
        <div className="flex gap-2">
          <h3 className="text-base font-semibold text-gray-900">
            {ApplicationString.module.user.userViewDetails.viewContent.isOwner}
          </h3>
          <span className="text-md text-gray-500">
            {usersData?.isOwner
              ? ApplicationString.module.booking.table.filters.options.yes
              : ApplicationString.module.booking.table.filters.options.no}
          </span>
        </div>
        <div className="flex gap-2">
          <h3 className="text-base font-semibold text-gray-900">
            {
              ApplicationString.module.user.userViewDetails.viewContent
                .isEnabled
            }
          </h3>
          <span className="text-md text-gray-500">
            {usersData?.isEnabled
              ? ApplicationString.module.booking.table.filters.options.yes
              : ApplicationString.module.booking.table.filters.options.no}
          </span>
        </div>
        <div className="flex gap-2 flex-col">
          <h3 className="text-base font-semibold text-gray-900">
            {
              ApplicationString.module.user.userViewDetails.viewContent
                .avatarPath
            }
          </h3>
          <span className="text-md text-gray-500">
            <div className="group relative">
              <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 sm:h-64">
                <img
                  alt=""
                  src={addHttpsToUrl(usersData?.avatarPath)}
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </div>
          </span>
        </div>
      </div>
    </DrawerWrapper>
  );
};

export default UserView;
