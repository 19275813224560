/* eslint-disable @typescript-eslint/default-param-last */
// -- TODO: disabling this error as redux is not accepting the state as 2nd parameter
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// TODO: disabling this error as this file is not being used as we are not using redux in this project

import {
  REMOVE_USER_SETTINGS,
  SET_USER_SETTINGS,
} from '../../Constants/actionType';
import { IUserSettingsData } from '../../Interfaces/Settings';

interface IActionType {
  type: string;
  payload: IUserSettingsData;
}

export const defaultSettingValue = {
  version: 0,
  setting: {
    radius: {
      max: 0,
      min: 0,
    },
    country: [],
    amenities: [],
    engineSize: [],
    minorVersion: 1,
    bookingStatus: [],
    futureBooking: {},
    vehicleListing: {},
    vehicleCategory: [],
    allowedOnVehicle: [],
    minimumAgeToRide: 18,
    '12hourlyTimeFrame': [],
    manufacturingYear: {
      max: 0,
      min: 0,
    },
    restrictedOnVehicle: [],
  },
};

const initialState = {
  userSettingsData: defaultSettingValue,
};
const settingsReducer = (state = initialState, action: IActionType) => {
  switch (action.type) {
    case SET_USER_SETTINGS:
      return {
        ...state,
        userSettingsData: action.payload,
      };
    case REMOVE_USER_SETTINGS:
      return {
        ...state,
        userSettingsData: defaultSettingValue,
      };
    default:
      return state;
  }
};

export default settingsReducer;
