import { LoginPasswordMinLength, updateProfileEnums } from '../Utils/enums';

const ApplicationString = {
  // Main component Menu
  menu: {
    sidebar: {
      dashboard: 'Dashboard',
      logout: 'Logout',
    },
  },
  table: {
    dynamicSearch: {
      search: 'Search',
      reset: 'Reset',
      filter: 'Filter',
    },
    pagination: {
      totalPages: 'Total Records',
      pageSize: 'Page Size',
      nextButton: 'Next',
      previousButton: 'Previous',
    },
    noData: {
      text: 'No data',
    },
    actions: {
      confirm: {
        cancelButton: 'Cancel',
        confirmButton: 'Confirm',
      },
      edit: {
        confirm: 'Are you sure you want to edit?',
      },
      delete: {
        confirm: 'Are you sure you want to delete?',
      },
    },
    heading: {
      userManagement: 'User Management',
      bookingManagement: 'Booking Management',
      vehicleManagement: 'Vehicle Management',
      contactManagement: 'Contact Us',
    },
  },
  dashboardTitles: {
    welcome: {
      text: 'Welcome to dashboard',
    },
    maintenancePage: {
      text: 'Site is under construction',
      subheading:
        "We're working hard to improve the user experience. Stay tuned!",
    },
  },
  common: {
    notAvailable: 'N/A',
  },
  module: {
    loader: {
      title: 'Loader Example',
      buttonLabel: {
        showGlobalLoader: 'Show Global loader',
        showLocalLoader: 'Show local loader',
      },
    },

    tableAction: {
      confirmationMessage: {
        delete: {
          value: 'Delete #{text}',
          message: `Are you sure you want to delete #{text}?`,
        },
        enable: {
          value: 'Enable #{text}',
          message: 'Are you sure you want to Enable User #{text}?',
        },
        changeStatus: {
          value: '#{text} Status Change',
          message: 'Are you sure you want to Change Status for #{text}?',
        },
        changePopularStatus: {
          value: ' Popular #{text} Change',
          message: 'Are you sure you want to Change Status for #{text}?',
        },
        approve: {
          value: 'Approve #{textApprove}',
          message: 'Are you sure you want to Approve #{textApprove}?',
        },
        resetPassword: {
          value: 'Reset Password',
          message: 'Are you sure you want to Reset password?',
        },
        view: {
          value: 'View #{text}',
          message: 'Are you sure you want to view #{text}?',
        },
      },
    },
    dashboard: {
      title: 'Dashboard',
      subTitle: 'Home',
      section: {
        notification: {
          title: 'Notification Example',
          buttonLabel: {
            showError: 'Show Error Notification',
            showSuccess: 'Show Success Notification',
            showInfo: 'Show Info Notification',
            showWarning: 'Show Warning Notification',
          },
        },
        loader: {
          title: 'Loader Example',
          buttonLabel: {
            showGlobalLoader: 'Show Global loader',
            showLocalLoader: 'Show local loader',
          },
        },
      },
    },
    user: {
      title: 'User',
      userViewDetails: {
        title: 'View User Details',
        viewContent: {
          name: 'Name:',
          mobile: 'Mobile:',
          email: 'Email:',
          address1: 'Address 1:',
          address2: 'Address 2:',
          isOwner: 'Is Owner Approved:',
          isEnabled: 'Is Enabled:',
          avatarPath: 'Avatar:',
        },
      },
      table: {
        columns: {
          name: 'Name',
          email: 'Email',
          mobile: 'Mobile',
          address1: 'Address 1',
          address2: 'Address 2',
          isOwnerApproved: 'Approve as Owner',
          isEnabled: 'Is Enabled',
          avatar: 'Avatar',
          action: 'Action',
          owner: 'Owner',
        },
        filters: {
          name: 'Name',
          email: 'Email',
          isOwnerApproved: 'Is Owner Approved ?',
          isEnabled: 'Is User Enabled ?',
          Options: {
            yes: 'Yes',
            no: 'No',
          },
        },
        actions: {
          title: {
            approveOwner: 'Approve or Disapprove Owner',
            enableUser: 'Enable or Disable User',
            subTitle: {
              approveOwner: 'Update status of Owner ',
              enableUser: 'Update status of User',
            },
          },
          approveOwner: 'Approve Owner',
          resetUsersPassword: 'Reset User Passwords',
          enableUser: 'Enable User',
        },
      },
      message: {
        approveOwner: 'Owners Approved Successfully.',
        userEnabled: 'Users Enabled Successfully.',
        userDeleted: 'User deleted successfully.',
        resetPassword: 'Mail sent for resetting passwords successfully.',
      },
    },
    vehicle: {
      title: 'Vehicle',
      table: {
        columns: {
          id: 'Id',
          description: 'Description',
          category: 'Category',
          subCategory: 'Sub Category',
          isEnabled: 'Is Enabled',
          isPopular: 'Is Popular',
          popular: 'Popular Vehicle',
          maker: 'Maker',
          model: 'model',
          manufacturingYear: 'Manufacturing Year',
          country: 'Country',
          state: 'State',
          city: 'City',
          zip: 'Zip',
          address: 'Address',
          latLong: 'LatLong',
          bookingSlotType: 'Booking Slot Type',
          action: 'Action',
        },
        filters: {
          country: 'Country',
          state: 'State',
          city: 'City',
          maker: 'Maker',
          model: 'Model',
          manufacturingYear: 'Manufacturing Year',
          user: 'User',
          category: 'Category',
          subCategory: 'Sub Category',
          isEnabled: 'Is Vehicle Enabled ?',
        },
      },
      vehicleViewDetails: {
        title: 'View Vehicle Details',
        vehicleDetailsTitle: 'Vehicle Details',
        vehicleLocationDetailsTitle: 'Location Details',
        vehicleBookingDetailsTitle: 'Booking Details',
        viewContent: {
          id: 'ID:',
          category: 'Category:',
          subCategory: 'Sub Category:',
          title: 'Title:',
          description: 'Description:',
          manufacturingYear: 'Manufacturing Year:',
          maker: 'Maker:',
          model: 'Model:',
          capacity: 'Capacity:',
          size: 'Vehicle Length:',
          country: 'Country:',
          dropCountry: 'Country:',
          state: 'State:',
          dropState: 'State:',
          city: 'City:',
          dropCity: 'City:',
          zip: 'Zip:',
          dropZip: 'Zip:',
          address: 'Address:',
          address2: 'Address 2:',
          dropAddress: 'Address:',
          dropAddress2: 'Address 2:',
          latLong: 'Latitude Longitude:',
          dropLatLong: 'Latitude Longitude:',
          bookingSlotType: 'Booking Slot Type:',
          bookingGap: 'Booking Gap:',
          shortNoticePeriod: 'Short Notice Period:',
          isMultipleBookingSameDay: 'Is Multiple Booking Same Day:',
          isEnabled: 'Is Enabled:',
          rating: 'Rating:',
          bookingRateHourly: 'Booking Rate Hourly:',
          bookingRate12Hourly: 'Booking Rate 12 Hourly:',
          timeFrameFor12Hourly: 'Time Frame For 12 Hourly:',
          bookingRate24Hourly: 'Booking Rate 24 Hourly:',
          maxDaysFor24Hourly: 'Max Days For 24 Hourly:',
          hr: ' hr',
          hrs: ' hrs',
          dollarSign: '$',
          days: ' days',
          ft: ' ft',
          metaDataTitle: 'MetaData -',
          metaData: {
            ownershipType: 'Ownership Type:',
            amenitiesDescription: 'Amenities Description:',
            amenities: 'Amenities:',
            restricted: 'Restricted:',
            engineSize: 'Engine Size:',
            hitchSize: 'Hitch Size:',
            isTrailerIncluded: 'Is Trailer Included:',
          },
          images: 'Images:',
          ownerDetailsTitle: 'Owner Details -',
          ownerDetails: {
            name: 'Owner Name:',
            avatarPath: 'Owner Avatar:',
            joinedYear: 'Owner Joined Year:',
          },
        },
      },
      action: {
        title: 'Activate or Deactivate Vehicle',
        form: {
          label: 'Change Status For Vehicle',
          placeholder: 'Select value to activate or deactivate vehicle',
        },
      },
      action1: {
        title: 'Popular Listing',
        form: {
          label:
            'Select the below checkbox to add a vehicle in Popular Listing',
          placeholder: 'Select value to popular or not popular vehicle',
        },
      },
      message: {
        vehicleEnabled: 'Vehicle Status Updated Successfully.',
        vehiclePopular: 'Vehicle Popular Status Updated Successfully.',
        vehicleDeleted: 'Vehicle deleted successfully.',
      },
    },
    booking: {
      title: 'Booking',
      table: {
        columns: {
          id: 'Id',
          vehicleId: 'Vehicle ID',
          vehicleTitle: 'Vehicle Title',
          vehicleMaker: 'Vehicle Maker',
          vehicleModel: 'Vehicle Model',
          isEnabled: 'Is Enabled',
          vehicleYear: 'Vehicle Year',
          startDate: 'Start Date',
          endDate: 'End Date',
          specialRequest: 'Special Request',
          numberOfPersons: 'Number Of Persons',
          rate: 'Rate',
          type: 'Status',
          tax: 'Tax',
          deposit: 'Deposit',
          total: 'Total',
          action: 'Action',
          renterName: 'Renter Name',
          ownerName: 'Owner Name',
          actionB: 'Action',
          status: {
            requested: 'Requested',
            active: 'Active',
            rejected: 'Rejected',
            cancelled: 'Cancelled',
            completed: 'Completed',
          },
        },
        filters: {
          startDate: 'Start Date',
          endDate: 'End Date',
          ownerName: 'Owner Name',
          renterName: 'Renter Name',
          user: 'User',
          vehicleTitle: 'Vehicle Title',
          type: 'Status',
          excludeSelfBooking: 'Exclude Self Booking ?',
          options: {
            yes: 'Yes',
            no: 'No',
          },
        },
      },
      bookingViewDetails: {
        title: 'View Booking Details',
        bookingDetailsTitle: 'Booking Details',
        bookingLocationDetailsTitle: 'Location Details',
        bookingOwnerDetailsTitle: 'Owner Details',
        bookingRenterDetailsTitle: 'Renter Details',
        bookingDetails: {
          confirmationCode: 'Confirmation Code:',
          startDate: 'Start Date:',
          endDate: 'End Date',
          type: 'Status:',
          rate: 'Rate:',
          size: 'Vehicle Length:',
          tax: 'Tax:',
          deposit: 'Deposit:',
          specialRequest: 'Special Request:',
          numberOfPerson: 'Number of Person:',
          total: 'Total:',
          country: 'Country:',
          dropCountry: 'Drop Country:',
          state: 'State:',
          dropState: 'Drop State:',
          city: 'City:',
          dropCity: ' Drop City:',
          zip: 'Zip:',
          dropZip: 'Drop Zip:',
          address: 'Address:',
          address2: 'Address 2:',
          dropAddress: 'Drop Address:',
          dropAddress2: 'Drop Address 2:',
          latLong: 'Latitude Longitude:',
          dropLatLong: 'Drop Latitude Longitude:',
          dollarSign: '$',
          ft: ' ft',
          ownerDetails: {
            id: 'Id:',
            name: 'Owner Name:',
            avatarPath: 'Owner Avatar:',
          },
          renterDetails: {
            id: 'Id:',
            name: 'Renter Name:',
            avatarPath: 'Renter Avatar:',
          },
          notApplicable: 'N/A',
          pickUp: 'Pick Up',
          dropOff: 'Drop Off',
        },
      },
      action: {
        title: 'Booking Status',
        form: {
          label: 'Change Status For Booking',
          placeholder: 'Select value to activate or deactivate booking',
        },
      },
      message: {
        bookingEnabled: 'Booking Status Updated Successfully.',
        bookingDeleted: 'Booking deleted successfully.',
      },
    },
    login: {
      title: 'Sign In',
      label: {
        email: 'Email',
        password: 'Password',
      },
      placeholder: {
        email: 'Enter your email',
        password: `${LoginPasswordMinLength}+ Characters`,
      },
      validationMessages: {
        email: {
          regexMatch: 'Invalid email',
          required: 'Email is required',
        },
        password: {
          required: 'Password is required',
          regexMatch:
            'Password must contain one uppercase letter, one lowercase letter, one number, and one special character',
          min: 'Password must be at least 6 characters',
        },
      },
    },
    dropdownUser: {
      options: {
        logout: 'Log Out',
        updateProfile: 'Update Profile',
      },
    },
    vehicleUpdateForm: {
      titles: {
        title: 'Vehicle Information',
        subTitle: 'Update Your Vehicle Details',
        vehicleDetailsTitle: 'Vehicle Details ',
        vehicleOwnershipTitle: 'Vehicle Ownership',
        vehicleAdditionalDetails: 'Add Additional Details  ',
        VehicleLocationDetails: 'Vehicle Location Details ',
        VehicleBookingVehicle: 'Booking Details ',
      },
      subtitles: {
        pickup: ' Pick-Up Location',
        DropOff: ' Drop-Off Location',
      },
      toastMsg: {
        success: 'Vehicle Updated Successfully',
      },
      fields: {
        label: {
          title: 'Title',
          description: 'Description',
          Category: 'Select Category',
          subCategory: 'Select Sub Category',
          model: 'Vehicle Model',
          maker: 'Maker',
          manufacturingYear: 'Manufacturing Year',
          timeFrameFor12Hourly: 'Select time frame',
          RecommendedEngineSize: 'Recommended Engine Size',
          address1: 'Address Line 1',
          address2: 'Address Line 2',
          Zip: 'Zip Code',
          capacity: 'Max No. of person allowed',
          size: 'Vehicle Length',
          amenities: 'Select Amenities',
          restrictions: 'Select Restrictions',
          country: 'Select Country',
          state: 'Select State',
          city: 'City',
          sameLocation: 'Same as pick-up location',
          bookingSlotHourly: 'Hourly',
          bookingSlot12Hourly: '12Hourly',
          bookingSlot24Hourly: '24Hourly',
          bookingSlots: 'Select Booking Slots',
          multipleBookings: 'Is Multiple Booking Same Day',
          timeInBetweenBookings: 'Time Needed In Between Bookings',
          shortNoticePeriod: 'Short Notice Period',
        },
        placeHolder: {
          title: 'Add Title',
          description: 'Add Description',
          Category: 'Select Category',
          subCategory: 'Select Sub Category',
          model: 'Add Vehicle Model',
          address1: 'Enter Your Address ',
          bookingRateHourly: 'Enter booking rate per hour',
          bookingRate12Hourly: 'Enter rate',
          shortNoticePeriod: 'Enter Short Notice Period',
          Zip: 'Enter Zip Code',
          address2: 'Enter Your Address ',
          capacity: 'Max No. of person allowed',
          size: 'Vehicle Length',
          amenities: 'Select Amenities',
          addAmenities: 'Add Amenities',
          restrictions: 'Select Restrictions',
          addRestrictions: 'Add Restrictions',
          country: 'Select country',
          state: 'Select State',
          city: 'Enter city',
          maxDays: 'Add No.of Days',
        },
        buttonLabels: {
          addAmenities: 'Add Amenities',
          addRestrictions: 'Add Restrictions',
          save: 'Save',
          cancel: 'Cancel',
        },
        bookingGapHours: {
          oneHour: ' 1 hour',
          twoHour: ' 2 hour',
          fourHour: ' 4 hour',
          eightHour: ' 8 hour',
          twelveHour: ' 12 hour',
          twentyFourHour: ' 24 hour',
        },
        validationMsg: {
          title: {
            required: 'Title is required',
            min: 'Title is too short',
            max: 'Title is too long',
          },
          manufacturingYear: {
            required: 'Year is required',
          },
          model: {
            required: 'Model name is required',
            min: 'Model name is too short',
            max: 'Model name is too long',
          },
          address: {
            required: 'Address1 is required',
            min: 'Address1 is too short',
            max: 'Address1 is too long',
          },
          bookingGap: {
            required: 'Booking Gap is required',
          },
          isMultipleBookingSameDay: {
            required: 'Is Multiple Booking Same Day is required',
          },
          address2: {
            min: 'Address2 is too short',
            max: 'Address2 is too long',
          },
          country: {
            required: 'Country is required',
            max: `Country name should be less than ${updateProfileEnums.country.max} characters `,
          },
          state: {
            required: 'State is required',
            max: `State name should be less than ${updateProfileEnums.state.max} characters `,
          },
          city: {
            required: 'City is required',
            max: `City name should be less than ${updateProfileEnums.city.max} characters `,
          },
          zip: {
            required: 'Zip Code is required',
            onlyDigit: 'Must be only digits',
            noSpecialCharacters: 'No special characters allowed',
            min: `Zip code should be greater than ${updateProfileEnums.zipCode.min} characters / numbers `,
            max: `Zip code should be less than ${updateProfileEnums.zipCode.max} characters / numbers`,
          },
        },
        radioValues: {
          ownership: {
            commercial: 'Commercially Owned',
            private: 'Privately Owned',
          },
          bookingSlots: {
            bookingSlotHourly: 'Hourly',
            bookingSlot12Hourly: '12 Hours',
            bookingSlot24Hourly: '24 Hours',
          },
          bookingOnSameDay: {
            yes: 'Yes',
            no: 'No',
          },
        },
      },
    },
    updateProfile: {
      titles: {
        title: 'Profile',
        subTitle: 'Manage Your Account Information',
      },
      label: {
        name: 'Full Name',
        email: 'Email Address',
        address1: 'Address Line 1',
        address2: 'Address Line 2',
        country: 'Country',
        city: 'City',
        state: 'State / Province',
        zip: 'ZIP / Postal code',
        upload: 'Profile Photo',
      },
      placeHolder: {
        name: 'Enter your full name',
        address1: 'Enter your primary address',
        address2: 'Address Line 2',
        country: 'Select a country',
        city: 'Enter your city',
        state: 'Enter your state/province',
        zip: 'Enter your zip/postal code',
        upload: {
          uploadFileText: 'Upload a file',
          uploadTypeSizeText: 'PNG, JPG, JPEG up to 10MB',
        },
      },
      button: {
        submit: 'Save',
        reset: 'Reset',
        update_photo: 'Edit',
        uploading: 'Uploading...',
      },
      tostMsg: {
        success: 'Profile updated successfully',
        fileFormat: 'Please upload a valid file format (PNG, JPG, JPEG)',
        fileMaxSize: 'File is size must be less then or equal to 5 MB',
      },
      unsavedChangeWarning: {
        description:
          'It seems that you have made changes. Any unsaved modifications will be lost. Please save before leaving the page.',
        button: 'Save',
      },
      validationMsg: {
        fullName: {
          required: 'Full name is required',
          min: 'Full name is too short',
          max: 'Full name is too long',
        },
        address: {
          required: 'Address1 is required',
          min: 'Address1 is too short',
          max: 'Address1 is too long',
        },
        address2: {
          min: 'Address2 is too short',
          max: 'Address2 is too long',
        },
        country: {
          required: 'Country is required',
          max: `Country name should be less than ${updateProfileEnums.country.max} characters `,
        },
        state: {
          required: 'State is required',
          max: `State name should be less than ${updateProfileEnums.state.max} characters `,
        },
        city: {
          required: 'City is required',
          max: `City name should be less than ${updateProfileEnums.city.max} characters `,
        },
        zip: {
          required: 'Zip Code is required',
          onlyDigit: 'Must be only digits',
          noSpecialCharacters: 'No special characters allowed',
          min: `Zip code should be greater than ${updateProfileEnums.zipCode.min} characters / numbers `,
          max: `Zip code should be less than ${updateProfileEnums.zipCode.max} characters / numbers`,
        },
      },
    },
    notFoundPage: {
      title: '404',
      subTitle: 'Not Found',
      description1: `Whoops! That page doesn’t exist.`,
      description2: `Sorry about that! Please visit our homepage to get where you need to
      go.`,
      button: 'Back to Homepage',
    },
    statusModalObject: {
      userStatus: {
        statusKey: 'isEnabled',
        successMsg: 'User is enabled successfully',
        failureMsg: 'User is disabled successfully',
      },
      ownerStatus: {
        statusKey: 'isOwnerApproved',
        successMsg: 'Owner is approved successfully',
        failureMsg: 'Owner is disapproved successfully',
      },
      vehicleStatus: {
        statusKey: 'isVehicleEnabled',
        successMsg: 'Vehicle is activated successfully',
        failureMsg: 'Vehicle is deactivated successfully',
      },
      bookingStatus: {
        statusKey: 'isBookingEnabled',
        successMsg: 'Booking is activated successfully',
        failureMsg: 'Booking is deactivated successfully',
      },
    },
    contact: {
      title: 'Contact Us',
      table: {
        columns: {
          name: 'Name',
          email: 'Email',
          message: 'Message',
          attachmentPath: 'Attachment',
          id: 'ID',
          noData: 'No Data',
        },
      },
      attachmentPath: {
        pdf: 'Click here to view pdf',
      },
    },
  },
  API: {
    refreshToken: {
      error: 'Invalid response from server',
    },
  },
};

export default ApplicationString;
