/* eslint-disable react-hooks/exhaustive-deps */
// TODO: When I add this dependency specified in eslint warning, my API is calling in infinite time because of adding pagination only as we have to add pagnation.page and paginatio.pagesize which are already added.
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import UserListingComponent from '../../Components/UserListing';
import { ApiRequest } from '../../Api/api';
import { URLS } from '../../Constants/urls';
import {
  DELETE_USERS,
  HIDE_LOADER,
  SET_ALL_USERS,
  SET_USER_ENABLED,
  // SET_USER_ENABLED,
  SET_USER_OWNER_APPROVED,
  SET_USER_RESET_PASSWORD,
  SHOW_LOADER,
} from '../../Constants/actionType';
import { useToast } from '../../Services/ToastService';
import { buildQueryString, handleApiError } from '../../Utils/utils';
import { IApiErrorResponse } from '../../Interfaces/Common/api.interface';
import {
  IPaginationValues,
  ISortParamValues,
} from '../../Interfaces/interfaces';
import { IAllUsersData } from '../../Interfaces/Users/index';
import { FormValues } from '../../Interfaces/form';
import {
  DefaultBooleanValue,
  DefaultPaginationValues,
  DefaultSortParams,
  DefaultStringValue,
} from '../../Constants/commonConfig';
import ApplicationString from '../../Constants/applicationString';

const UserListingContainer: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [allUsersData, setAllUsersData] = useState<IAllUsersData[]>([]);
  const [pagination, setPagination] = useState<IPaginationValues>({
    page: DefaultPaginationValues.PAGE,
    pageSize: DefaultPaginationValues.PAGE_SIZE,
    totalItems: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [paginationSortAndSearchTrigger, setPaginationSortAndSearchTrigger] =
    useState<boolean>(DefaultBooleanValue);
  const [sortParams, setSortParams] = useState<ISortParamValues>({
    sortField: 'id',
    sortDirection: DefaultSortParams.DESC,
  });
  const [searchValues, setSearchValues] = useState({});
  const [usersData, setUsersData] = useState<IAllUsersData>({
    id: DefaultStringValue,
    name: DefaultStringValue,
    email: DefaultStringValue,
    mobile: DefaultStringValue,
    isEnabled: DefaultBooleanValue,
    address: DefaultStringValue,
    address2: DefaultStringValue,
    isOwner: DefaultBooleanValue,
    avatarPath: DefaultStringValue,
  });
  const [isUserDetailViewVisible, setIsUserDetailViewVisible] =
    useState<boolean>(DefaultBooleanValue);
  // const [showCheckboxItems, setShowCheckboxItems] =
  //   useState<boolean>(DefaultBooleanValue);
  // const [headerCheckboxState, setHeaderCheckboxState] =
  //   useState<boolean>(DefaultBooleanValue);
  // const [selectedCheckboxDataIds, setSelectedCheckboxDataIds] = useState<
  //   string[]
  // >([]);
  const handleDynamicActionClick = async (
    id: string,
    value: string,
    url: string,
    payloadKey: string,
    successMessage: string,
    failureMessage: string,
    setState: (value: boolean) => void // Assuming you're using a toast library
  ): Promise<void> => {
    try {
      const booleanValue = value === 'true';
      const stringId = id;
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.put(
        url.replace('#{userId}', stringId),
        { [payloadKey]: booleanValue }
      );
      if (response?.status === HttpStatusCode.NoContent) {
        setState(true);
        if (booleanValue) {
          toast.success(successMessage);
        } else {
          toast.success(failureMessage);
        }
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };
  const getAllUsers = useCallback(
    async (searchParams: object): Promise<void> => {
      try {
        const sortField = sortParams.sortField || '';
        const sortDirection = sortParams.sortDirection || '';
        const searchValueParams = buildQueryString(searchParams);
        dispatch({ type: SHOW_LOADER });
        const response: AxiosResponse = await ApiRequest.get(
          `${URLS.USER_MANAGEMENT}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&sortBy=${sortField}&sortDirection=${sortDirection}&${searchValueParams}`
        );
        if (response?.status === HttpStatusCode.Ok) {
          dispatch({ type: HIDE_LOADER });
          dispatch({ type: SET_ALL_USERS, payload: response?.data?.data });
          setAllUsersData(response?.data?.data);
          setPagination({
            ...pagination,
            page: response?.data?.pageNumber,
            pageSize: response?.data?.pageSize,
            totalItems: response?.data?.total,
          });
        }
      } catch (error) {
        dispatch({ type: HIDE_LOADER });
        const axiosError = error as AxiosError<IApiErrorResponse>;
        handleApiError(axiosError, toast);
      }
    },
    [
      sortParams.sortField,
      sortParams.sortDirection,
      pagination.page,
      pagination.pageSize,
      dispatch,
      toast,
    ]
  );

  useEffect(() => {
    getAllUsers(searchValues);
  }, [getAllUsers, searchValues]);

  useEffect(() => {
    if (paginationSortAndSearchTrigger) {
      getAllUsers(searchValues);
    }
    setPaginationSortAndSearchTrigger(false);
  }, [getAllUsers, paginationSortAndSearchTrigger, searchValues]);

  const fetchUserById = async (userId: string): Promise<void> => {
    try {
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.get(
        URLS.USER_MANAGEMENT_BY_ID.replace('#{userId}', userId)
      );
      if (response?.status === HttpStatusCode.Ok) {
        dispatch({ type: HIDE_LOADER });
        setUsersData(response.data);
        setIsUserDetailViewVisible(true);
      }
    } catch (error) {
      dispatch({ type: HIDE_LOADER });
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    }
  };

  // const handleApproveOwnerClick = async () => {
  //   try {
  //     await Promise.all(
  //       selectedCheckboxDataIds.map(async (id) => {
  //         await processApprovalRequest(id);
  //       })
  //     );
  //     setPaginationSortAndSearchTrigger(true);
  //     toast.success(ApplicationString.module.user.message.approveOwner);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     dispatch({ type: HIDE_LOADER });
  //     setSelectedCheckboxDataIds([]);
  //     setHeaderCheckboxState(false);
  //     setShowCheckboxItems(false);
  //   }
  // };

  const handleApproveOwnerClick = async (userId: string): Promise<void> => {
    try {
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.put(
        URLS.USER_MANAGEMENT_APPROVE_OWNER.replace('#{userId}', userId),
        { isOwnerApproved: true }
      );
      if (response?.status === HttpStatusCode.NoContent) {
        dispatch({ type: SET_USER_OWNER_APPROVED });
        toast.success(ApplicationString.module.user.message.approveOwner);
        setPaginationSortAndSearchTrigger(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };

  // const handleUserEnableClick = async () => {
  //   try {
  //     await Promise.all(
  //       selectedCheckboxDataIds.map(async (id) => {
  //         await processEnableRequest(id);
  //       })
  //     );
  //     setPaginationSortAndSearchTrigger(true);
  //     toast.success(ApplicationString.module.user.message.userEnabled);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     dispatch({ type: HIDE_LOADER });
  //     setSelectedCheckboxDataIds([]);
  //     setHeaderCheckboxState(false);
  //     setShowCheckboxItems(false);
  //   }
  // };

  const handleUserEnableClick = async (userId: string): Promise<void> => {
    try {
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.put(
        URLS.USER_MANAGEMENT_ENABLE.replace('#{userId}', userId),
        { isEnabled: true }
      );
      if (response?.status === HttpStatusCode.NoContent) {
        dispatch({ type: SET_USER_ENABLED });
        toast.success(ApplicationString.module.user.message.userEnabled);
        setPaginationSortAndSearchTrigger(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };
  // const handleResetPasswordClick = async () => {
  //   try {
  //     await Promise.all(
  //       selectedCheckboxDataIds.map(async (userId) => {
  //         await resetPassword(userId);
  //       })
  //     );
  //     toast.success(ApplicationString.module.user.message.resetPassword);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     dispatch({ type: HIDE_LOADER });
  //     setSelectedCheckboxDataIds([]);
  //     setHeaderCheckboxState(false);
  //     setShowCheckboxItems(false);
  //   }
  // };

  const handleConfirm = async (
    userId: string,
    statusValue: string,
    url: string,
    statusKey: string,
    successMessage: string,
    failureMessage: string
  ) => {
    await handleDynamicActionClick(
      userId,
      statusValue,
      url,
      statusKey,
      successMessage,
      failureMessage,
      setPaginationSortAndSearchTrigger
    );
  };

  const handleResetPasswordClick = async (userId: string): Promise<void> => {
    try {
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.put(
        URLS.USER_MANAGEMENT_RESET_PASSWORD.replace('#{userId}', userId)
      );
      if (response?.status === HttpStatusCode.NoContent) {
        dispatch({ type: SET_USER_RESET_PASSWORD });
        toast.success(ApplicationString.module.user.message.resetPassword);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };

  const handlePageChange = (pageNumber: number): void => {
    setPagination({ ...pagination, page: pageNumber });
    setPaginationSortAndSearchTrigger(true);
    // setSelectedCheckboxDataIds([]);
    // setHeaderCheckboxState(false);
    // setShowCheckboxItems(false);
  };

  const handlePageSizeChange = (newSize: number): void => {
    setPagination({
      ...pagination,
      page: DefaultPaginationValues.PAGE,
      pageSize: newSize,
    });
    setPaginationSortAndSearchTrigger(true);
    // setSelectedCheckboxDataIds([]);
    // setHeaderCheckboxState(false);
    // setShowCheckboxItems(false);
  };

  const handleUserDeleteClick = async (userId: string): Promise<void> => {
    try {
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.delete(
        URLS.USER_MANAGEMENT_DELETE.replace('#{userId}', userId)
      );
      if (response?.status === HttpStatusCode.Ok) {
        dispatch({ type: HIDE_LOADER });
        dispatch({ type: DELETE_USERS, payload: response?.data });
        toast.success(ApplicationString.module.user.message.userDeleted);
        setPaginationSortAndSearchTrigger(true);
      }
    } catch (error) {
      dispatch({ type: HIDE_LOADER });
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    }
  };
  const handleUserViewClick = (data: IAllUsersData): void => {
    fetchUserById(data?.id);
  };
  const handleSearch = (values: FormValues): void => {
    setSearchValues(values);
    setPaginationSortAndSearchTrigger(true);
    // setSelectedCheckboxDataIds([]);
    // setHeaderCheckboxState(false);
    // setShowCheckboxItems(false);
  };
  return (
    <UserListingComponent
      allUsersData={allUsersData}
      pagination={pagination}
      setPagination={setPagination}
      setPaginationSortAndSearchTrigger={setPaginationSortAndSearchTrigger}
      setSortParams={setSortParams}
      sortParams={sortParams}
      searchValues={searchValues}
      usersData={usersData}
      isUserDetailViewVisible={isUserDetailViewVisible}
      setIsUserDetailViewVisible={setIsUserDetailViewVisible}
      handleApproveOwnerClick={(userId: string) => {
        handleApproveOwnerClick(userId);
      }}
      handleUserEnableClick={(userId: string) => {
        handleUserEnableClick(userId);
      }}
      handleResetPasswordClick={(userId: string) => {
        handleResetPasswordClick(userId);
      }}
      // selectedCheckboxDataIds={selectedCheckboxDataIds}
      // setSelectedCheckboxDataIds={setSelectedCheckboxDataIds}
      // headerCheckboxState={headerCheckboxState}
      // setHeaderCheckboxState={setHeaderCheckboxState}
      // showCheckboxItems={showCheckboxItems}
      // setShowCheckboxItems={setShowCheckboxItems}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      handleUserDeleteClick={(userID: string) => {
        handleUserDeleteClick(userID);
      }}
      handleSearch={(values: FormValues) => {
        handleSearch(values);
      }}
      handleUserViewClick={(data: IAllUsersData) => handleUserViewClick(data)}
      handleStatusClick={(
        id: string,
        isEnabledValue: string,
        url: string,
        statusKey: string,
        successMessage: string,
        failureMessage: string
      ) => {
        handleConfirm(
          id,
          isEnabledValue,
          url,
          statusKey,
          successMessage,
          failureMessage
        );
      }}
    />
  );
};

export default UserListingContainer;
