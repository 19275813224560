/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Formik, Field, Form } from 'formik';
import React from 'react';
import ApplicationString from '../../../Constants/applicationString';

interface StatusFormComponentProps {
  statusTitle: string;
  statusSubTitle: string;
  isPopular: boolean;
  onConfirm: (statusValue: boolean) => void;
  onCancel: () => void;
}
const PopularTagForm = ({
  statusTitle,
  statusSubTitle,
  isPopular,
  onConfirm,
  onCancel,
}: StatusFormComponentProps): JSX.Element => {
  const initialValues = {
    status: isPopular,
  };
  return (
    <div className="fixed z-10 inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-30 w-full">
      <div className="bg-white p-6 rounded-lg shadow-xl w-1/4">
        <p className="text-lg font-medium mb-4">{statusTitle}</p>
        <Formik
          initialValues={initialValues}
          validateOnChange
          enableReinitialize
          onSubmit={(values) => {
            onConfirm(values.status);
          }}
        >
          <Form>
            <div className="mt-5 grid gap-x-6 gap-y-8">
              <div className="w-full">
                <label className="block text-sm font-medium leading-6 text-gray-900 text-wrap">
                  {statusSubTitle}
                </label>
                <div className="mt-2">
                  <div role="group" aria-labelledby="checkbox-group">
                    <label className="flex items-center" htmlFor="status">
                      <Field
                        type="checkbox"
                        name="status"
                        id="status"
                        className="mr-2"
                      />
                      {ApplicationString.module.vehicle.table.columns.popular}
                    </label>
                  </div>
                </div>
                <span className="mt-1 text-red-500">
                  <ErrorMessage name="status" />
                </span>
              </div>
            </div>
            <div className="mt-5 flex justify-end gap-4">
              <button
                type="button"
                onClick={onCancel}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {ApplicationString.table.actions.confirm.cancelButton}
              </button>
              <button
                type="submit"
                className="text-red-500 hover:text-red-700 focus:outline-none"
              >
                {ApplicationString.table.actions.confirm.confirmButton}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default PopularTagForm;
