/* eslint-disable react/require-default-props */
import React, { ReactNode, useState } from 'react';

const TooltipComponent = ({
  message = 'Default Tooltip',
  children,
  additionalClass,
}: {
  message?: string;
  children: ReactNode;
  additionalClass?: string;
}): JSX.Element => {
  const [show, setShow] = useState(false);
  return (
    <div className="relative flex flex-col items-center group">
      <span
        className="flex justify-center"
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {children}
      </span>
      <div
        className={`absolute whitespace-wrap bottom-full flex flex-col items-center justify-left group-hover:flex ${!show ? 'hidden' : null} ${additionalClass}`}
      >
        <span className="w-full relative p-2 z-30 m-2 text-xs bg-zinc-700 text-white whitespace-normal leading-4 bg-gray-600 shadow-lg rounded-md border-transparent border-b-black">
          {message}
        </span>
        {/* <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600" /> */}
      </div>
    </div>
  );
};

export default TooltipComponent;
