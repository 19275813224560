/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ApiRequest } from '../../Api/api';
import ContactComponent from '../../Components/Contact';
import { HIDE_LOADER, SHOW_LOADER } from '../../Constants/actionType';
import {
  DefaultBooleanValue,
  DefaultPaginationValues,
  DefaultSortParams,
} from '../../Constants/commonConfig';
import { URLS } from '../../Constants/urls';
import { IApiErrorResponse } from '../../Interfaces/Common/api.interface';
import { IAllContactData } from '../../Interfaces/Contact';
import {
  IPaginationValues,
  ISortParamValues,
} from '../../Interfaces/interfaces';
import { useToast } from '../../Services/ToastService';
import { handleApiError } from '../../Utils/utils';

const ContactContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [pagination, setPagination] = useState<IPaginationValues>({
    page: DefaultPaginationValues.PAGE,
    pageSize: DefaultPaginationValues.PAGE_SIZE,
    totalItems: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [paginationSortAndSearchTrigger, setPaginationSortAndSearchTrigger] =
    useState<boolean>(DefaultBooleanValue);
  const [sortParams, setSortParams] = useState<ISortParamValues>({
    sortField: 'id',
    sortDirection: DefaultSortParams.DESC,
  });
  const [contactData, setContactData] = useState<IAllContactData[]>([]);
  const getAllContacts = useCallback(async (): Promise<void> => {
    try {
      const sortField = sortParams.sortField || '';
      const sortDirection = sortParams.sortDirection || '';
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.get(
        `${URLS.CONTACT_MANAGEMENT}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&sortBy=${sortField}&sortDirection=${sortDirection}`
      );
      if (response?.status === HttpStatusCode.Ok) {
        dispatch({ type: HIDE_LOADER });
        setContactData(response?.data?.data);
        setPagination({
          ...pagination,
          page: response?.data?.pageNumber,
          pageSize: response?.data?.pageSize,
          totalItems: response?.data?.total,
        });
      }
    } catch (error) {
      dispatch({ type: HIDE_LOADER });
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    }
  }, [
    sortParams.sortField,
    sortParams.sortDirection,
    pagination.page,
    pagination.pageSize,
    dispatch,
    toast,
  ]);

  useEffect(() => {
    getAllContacts();
  }, []);

  useEffect(() => {
    if (paginationSortAndSearchTrigger) {
      getAllContacts();
    }
    setPaginationSortAndSearchTrigger(false);
  }, [getAllContacts, paginationSortAndSearchTrigger]);

  const handlePageChange = (pageNumber: number): void => {
    setPagination({ ...pagination, page: pageNumber });
    setPaginationSortAndSearchTrigger(true);
  };

  const handlePageSizeChange = (newSize: number): void => {
    setPagination({
      ...pagination,
      page: DefaultPaginationValues.PAGE,
      pageSize: newSize,
    });
    setPaginationSortAndSearchTrigger(true);
  };

  return (
    <ContactComponent
      contactData={contactData}
      pagination={pagination}
      setPagination={setPagination}
      setPaginationSortAndSearchTrigger={setPaginationSortAndSearchTrigger}
      setSortParams={setSortParams}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      sortParams={sortParams}
    />
  );
};

export default ContactContainer;
