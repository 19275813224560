import React, { useState } from 'react';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { IVehiclesData, MetaData } from '../../../Interfaces/Vehicles';
import {
  ICountry,
  IEngineSize,
  IState,
  ISubCategory,
  IThe12HourlyTimeFrame,
  IUserSettingsData,
  IVehicleCategory,
} from '../../../Interfaces/Settings';
import cssUtils from '../../../Utils/cssUtils';
import { localStorageEnums, updateProfileEnums } from '../../../Utils/enums';
import regexConfig from '../../../Constants/RegexConfig';
import {
  checkIfArrayIsTruthyOrNot,
  createSelectOptions,
  getLocalStorageAccountInfo,
  populateYears,
} from '../../../Utils/utils';
import ApplicationString from '../../../Constants/applicationString';

const updateVehicleFormSchema = Yup.object().shape({
  title: Yup.string()
    .required(
      ApplicationString.module.vehicleUpdateForm.fields.validationMsg.title
        .required
    )
    .min(
      2,
      ApplicationString.module.vehicleUpdateForm.fields.validationMsg.title.min
    )
    .max(
      100,
      ApplicationString.module.vehicleUpdateForm.fields.validationMsg.title.max
    ),
  address: Yup.string()
    .required(
      ApplicationString.module.vehicleUpdateForm.fields.validationMsg.address
        .required
    )
    .min(
      updateProfileEnums.address.min,
      ApplicationString.module.vehicleUpdateForm.fields.validationMsg.address
        .min
    )
    .max(
      updateProfileEnums.address.max,
      ApplicationString.module.vehicleUpdateForm.fields.validationMsg.address
        .max
    ),
  manufacturingYear: Yup.string().required(
    ApplicationString.module.vehicleUpdateForm.fields.validationMsg
      .manufacturingYear.required
  ),
  model: Yup.string().required(
    ApplicationString.module.vehicleUpdateForm.fields.validationMsg.model
      .required
  ),
  city: Yup.string().max(
    updateProfileEnums.city.max,
    ApplicationString.module.vehicleUpdateForm.fields.validationMsg.city.max
  ),
  state: Yup.string().max(
    updateProfileEnums.state.max,
    ApplicationString.module.vehicleUpdateForm.fields.validationMsg.state.max
  ),
  zip: Yup.string()
    .matches(
      regexConfig.alphaNumeric,
      ApplicationString.module.vehicleUpdateForm.fields.validationMsg.zip
        .noSpecialCharacters
    )
    .min(
      updateProfileEnums.zipCode.min,
      ApplicationString.module.vehicleUpdateForm.fields.validationMsg.zip.min
    )
    .max(
      updateProfileEnums.zipCode.max,
      ApplicationString.module.vehicleUpdateForm.fields.validationMsg.zip.max
    ),
  bookingGap: Yup.number().required(
    ApplicationString.module.vehicleUpdateForm.fields.validationMsg.bookingGap
  ),
  isMultipleBookingSameDay: Yup.boolean().required(
    ApplicationString.module.vehicleUpdateForm.fields.validationMsg
      .isMultipleBookingSameDay.required
  ),
});

interface VehicleProps {
  vehicleData: IVehiclesData | null;
  vehicleUpdateDetails: (values: IVehiclesData) => Promise<void>;
  getVehicleUpdateDetails: () => Promise<void>;
}

interface InitialValueConst extends IVehiclesData {
  sameLocation: boolean;
  amenities: MetaData['amenities'];
  restrictions: MetaData['restricted'];
}

const VehicleUpdateComponent = ({
  vehicleData,
  vehicleUpdateDetails,
  getVehicleUpdateDetails,
}: VehicleProps): JSX.Element => {
  const [openAddAmenities, setOpenAddAmenities] = useState(false);
  const [openAddRestrictions, setOpenAddRestrictions] = useState(false);
  const [amenities, setAmenities] = useState<string[]>([]);
  const [restrictions, setRestrictions] = useState<string[]>([]);
  const [newAmenity, setNewAmenity] = useState('');
  const [newRestrictions, setNewRestrictions] = useState('');

  const initialValueConst: InitialValueConst = {
    ...(vehicleData as IVehiclesData),
    bookingSlotType: `${vehicleData?.bookingSlotType}`.split(','),
    sameLocation: false,
    amenities,
    restrictions,
  };

  const userSettingsData = getLocalStorageAccountInfo<IUserSettingsData>(
    localStorageEnums.settings
  )?.setting;

  const twelveHourlyTimeFrameData: IThe12HourlyTimeFrame[] =
    checkIfArrayIsTruthyOrNot<IThe12HourlyTimeFrame[]>(
      userSettingsData?.['12hourlyTimeFrame']
    );
  const twelveHourlyTimeFrameOptions = createSelectOptions<
    IThe12HourlyTimeFrame,
    IThe12HourlyTimeFrame[],
    'value',
    'label'
  >(twelveHourlyTimeFrameData, 'value', 'label');
  const startYearForManufacturing = userSettingsData?.manufacturingYear.min;

  const categoriesData: IVehicleCategory[] = checkIfArrayIsTruthyOrNot<
    IVehicleCategory[]
  >(userSettingsData?.vehicleCategory);
  const vehicleCategoryOptions = createSelectOptions<
    IVehicleCategory,
    IVehicleCategory[],
    'id',
    'name'
  >(categoriesData, 'id', 'name');

  const subCategoryData = categoriesData.flatMap((cat) =>
    checkIfArrayIsTruthyOrNot<ISubCategory[]>(cat?.subCategory)
  );
  const subCategoryDataOptions = createSelectOptions<
    ISubCategory,
    ISubCategory[],
    'id',
    'name'
  >(subCategoryData, 'id', 'name');

  const engineSizeData = checkIfArrayIsTruthyOrNot<IEngineSize[]>(
    userSettingsData?.engineSize
  );

  const makerData = categoriesData.flatMap((mak) =>
    checkIfArrayIsTruthyOrNot<string[]>(mak?.maker)
  );

  const countriesData: ICountry[] = checkIfArrayIsTruthyOrNot<ICountry[]>(
    userSettingsData?.country
  );
  const countriesDataOptions = createSelectOptions<
    ICountry,
    ICountry[],
    'code',
    'name'
  >(countriesData, 'code', 'name');

  const statesData = countriesData.flatMap((con) =>
    checkIfArrayIsTruthyOrNot<IState[]>(con?.state)
  );
  const stateDataOptions = createSelectOptions<
    IState,
    IState[],
    'code',
    'name'
  >(statesData, 'code', 'name');

  const handleIsMultipleSameDayBookingRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: boolean | string) => void
  ) => {
    setFieldValue('isMultipleBookingSameDay', event.target.value === 'true');
  };
  const handleBookingGapRadioChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: number) => void
  ) => {
    setFieldValue('bookingGap', Number(e.target.value));
  };

  const handleNewAmenities = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAmenity(e.target.value);
  };
  const handleNewRestrictions = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewRestrictions(e.target.value);
  };
  const handleSaveAmenity = () => {
    setAmenities((prevAmenities) => [...prevAmenities, newAmenity]);
    setOpenAddAmenities(false);
  };

  const handleSaveRestrictions = () => {
    setRestrictions((prevRestrictions) => [
      ...prevRestrictions,
      newRestrictions,
    ]);
    setOpenAddRestrictions(false);
  };
  const handleSameDropOffLocation = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: boolean | string) => void,
    values: IVehiclesData
  ) => {
    setFieldValue('sameLocation', e.target.checked);
    if (e.target.checked) {
      setFieldValue('dropAddress', values.address);
      setFieldValue('dropAddress2', values.address2);
      setFieldValue('dropCity', values.city);
      setFieldValue('dropState', values.state);
      setFieldValue('dropZip', values.zip);
    } else {
      setFieldValue('dropAddress', '');
      setFieldValue('dropAddress2', '');
      setFieldValue('dropCity', '');
      setFieldValue('dropState', '');
      setFieldValue('dropZip', '');
    }
  };

  const handleAdd24HourDays = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: number) => void
  ) => {
    setFieldValue('maxDaysFor24Hourly', Number(e.target.value));
  };

  return (
    <div className="pb-12">
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        {ApplicationString.module.vehicleUpdateForm.titles.title}
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        {ApplicationString.module.vehicleUpdateForm.titles.subTitle}
      </p>
      <Formik
        initialValues={initialValueConst}
        validateOnChange
        enableReinitialize
        validationSchema={updateVehicleFormSchema}
        onSubmit={(values: IVehiclesData) => {
          const formData = values;
          delete formData.sameLocation;
          delete formData.amenities;
          delete formData.restrictions;

          let timeSlotValues: string[] = [];
          if (Array.isArray(formData.bookingSlotType)) {
            timeSlotValues = formData.bookingSlotType.filter(
              (value) => value !== '' && value.length > 1
            );
          } else if (
            typeof formData.bookingSlotType === 'string' &&
            formData.bookingSlotType.length > 1
          ) {
            timeSlotValues = [formData.bookingSlotType];
          }

          const joinedString = timeSlotValues.join(',').toLowerCase();

          vehicleUpdateDetails({
            ...formData,
            bookingSlotType: joinedString,
          });
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="vehicle-details-form-group">
                  <h1 className="text-base font-semibold leading-7 text-gray-900 mt-4">
                    {
                      ApplicationString.module.vehicleUpdateForm.titles
                        .vehicleDetailsTitle
                    }
                  </h1>
                  <div className="col-span-full">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .title
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="title"
                        id="title"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.title
                        }
                        autoComplete="title"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                    <span className="mt-1 text-red-500">
                      <ErrorMessage name="title" />
                    </span>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .description
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="description"
                        id="description"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.description
                        }
                        autoComplete="description"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="model"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .model
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="model"
                        id="model"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.model
                        }
                        autoComplete="model"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                    <span className="mt-1 text-red-500">
                      <ErrorMessage name="model" />
                    </span>
                  </div>

                  <div className="sm:col-span-3 sm:col-start-1">
                    <label
                      htmlFor="category"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .Category
                      }
                    </label>
                    <div className="mt-2">
                      <Select
                        options={vehicleCategoryOptions}
                        name="country"
                        className="mt-1 p-2  rounded-md w-full bg-white shadow-sm focus:outline-none focus:border-blue-500 appearance-none"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3 sm:col-start-1">
                    <label
                      htmlFor="subCategory"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .subCategory
                      }
                    </label>
                    <div className="mt-2">
                      <Select
                        options={subCategoryDataOptions}
                        name="country"
                        className="mt-1 p-2  rounded-md w-full bg-white shadow-sm focus:outline-none focus:border-blue-500 appearance-none"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3 sm:col-start-1">
                    <label
                      htmlFor="RecommendedEngineSize"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .RecommendedEngineSize
                      }
                    </label>
                    <div className="mt-2">
                      <Select
                        options={engineSizeData.map((i) => ({
                          label: i,
                          value: i,
                        }))}
                        name="country"
                        className="mt-1 p-2  rounded-md w-full bg-white shadow-sm focus:outline-none focus:border-blue-500 appearance-none"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3 sm:col-start-1">
                    <label
                      htmlFor="maker"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .maker
                      }
                    </label>
                    <div className="mt-2">
                      <Select
                        options={makerData.map((i) => ({
                          label: i,
                          value: i,
                        }))}
                        name="country"
                        className="mt-1 p-2  rounded-md w-full bg-white shadow-sm focus:outline-none focus:border-blue-500 appearance-none"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3 sm:col-start-1">
                    <label
                      htmlFor="manufacturingYear"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .manufacturingYear
                      }
                    </label>
                    <div className={` mt-2`}>
                      <Select
                        options={populateYears(startYearForManufacturing)}
                        name="country"
                        className="mt-1 p-2  rounded-md w-full bg-white shadow-sm focus:outline-none focus:border-blue-500 appearance-none"
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="capacity"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .capacity
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="capacity"
                        id="capacity"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.capacity
                        }
                        autoComplete="capacity"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="size"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .size
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="size"
                        id="size"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.size
                        }
                        autoComplete="size"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                  </div>
                </div>
                <div className="vehicle-ownership-form-group">
                  <h1 className="text-base font-semibold leading-7 text-gray-900 mt-8">
                    {
                      ApplicationString.module.vehicleUpdateForm.titles
                        .vehicleOwnershipTitle
                    }
                  </h1>

                  <div className="grid grid-cols-2 mt-5 gap-4 w-1/2">
                    <div className="mt-2 ">
                      <label
                        className={`${cssUtils.inputClasses} bg-white`}
                        htmlFor="radio"
                      >
                        <Field
                          type="radio"
                          name="metaData.ownershipType"
                          value="commercial"
                          className="mr-2.5 accent-slate-500 "
                        />
                        {
                          ApplicationString.module.vehicleUpdateForm.fields
                            .radioValues.ownership.commercial
                        }
                      </label>
                    </div>
                    <div className="mt-2 ">
                      <label
                        className={`${cssUtils.inputClasses} bg-white`}
                        htmlFor="radio-amenities"
                      >
                        <Field
                          type="radio"
                          name="metaData.ownershipType"
                          value="private"
                          className="mr-2.5 accent-slate-500 "
                        />
                        {
                          ApplicationString.module.vehicleUpdateForm.fields
                            .radioValues.ownership.private
                        }
                      </label>
                    </div>
                  </div>
                </div>

                <div className="vehicle-additional-details-form-group">
                  <h1 className="text-base font-semibold leading-7 text-gray-900 mt-12">
                    {
                      ApplicationString.module.vehicleUpdateForm.titles
                        .vehicleAdditionalDetails
                    }
                  </h1>
                  <div className="mt-5">
                    <label
                      htmlFor="amenities"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .amenities
                      }
                    </label>
                    <div className="mt-2 grid grid-cols-2 w-1/2 gap-4">
                      {vehicleData?.metaData.amenities
                        .concat(amenities)
                        .map((amenity: string) => (
                          <div
                            key={amenity}
                            className={`${cssUtils.inputClasses} bg-white`}
                          >
                            <label htmlFor="amenities">
                              <Field
                                type="checkbox"
                                name="amenities"
                                id="amenities"
                                className="mr-2.5 accent-slate-500 "
                              />
                              {amenity}
                            </label>
                          </div>
                        ))}
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            setOpenAddRestrictions(true);
                          }
                        }}
                        className={`${cssUtils.button}`}
                        onClick={() => setOpenAddAmenities(true)}
                      >
                        {
                          ApplicationString.module.vehicleUpdateForm.fields
                            .buttonLabels.addAmenities
                        }
                      </div>
                    </div>

                    <div>
                      {openAddAmenities && (
                        <div className="mt-2 flex flex-row justify-between items-center">
                          <Field
                            type="text"
                            name="newAmenityText"
                            id="newAmenityText"
                            placeholder={
                              ApplicationString.module.vehicleUpdateForm.fields
                                .placeHolder.addAmenities
                            }
                            autoComplete="newAmenityText"
                            className={`${cssUtils.inputClasses} bg-white mr-2`}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleNewAmenities(e);
                            }}
                          />
                          <div
                            role="button"
                            tabIndex={0}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                handleSaveAmenity();
                              }
                            }}
                            className={`${cssUtils.button} py-2 mr-2`}
                            onClick={handleSaveAmenity}
                          >
                            {
                              ApplicationString.module.vehicleUpdateForm.fields
                                .buttonLabels.save
                            }
                          </div>
                          <div
                            role="button"
                            tabIndex={0}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                setOpenAddAmenities(false);
                              }
                            }}
                            className="cursor-pointer rounded-lg  p-4 text-black py-2 transition hover:bg-white hover:text-black"
                            onClick={() => setOpenAddAmenities(false)}
                          >
                            {
                              ApplicationString.module.vehicleUpdateForm.fields
                                .buttonLabels.cancel
                            }
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-5">
                    <label
                      htmlFor="restrictions"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .restrictions
                      }
                    </label>
                    <div className="mt-2 grid grid-cols-2 w-1/2 gap-4">
                      {values.metaData?.restricted
                        .concat(restrictions)
                        .map((restriction) => {
                          return (
                            <div
                              key={restriction}
                              className={`${cssUtils.inputClasses} bg-white`}
                            >
                              <label htmlFor="restrictions">
                                <Field
                                  type="checkbox"
                                  name="restrictions"
                                  id="restrictions"
                                  className="mr-2.5 accent-slate-500 "
                                />
                                {restriction}
                              </label>
                            </div>
                          );
                        })}
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            setOpenAddRestrictions(true);
                          }
                        }}
                        className={`${cssUtils.button} `}
                        onClick={() => setOpenAddRestrictions(true)}
                      >
                        {
                          ApplicationString.module.vehicleUpdateForm.fields
                            .buttonLabels.addRestrictions
                        }
                      </div>
                    </div>
                    <div>
                      {openAddRestrictions && (
                        <div className="mt-2 flex flex-row justify-between items-center">
                          <Field
                            type="text"
                            name="newRestrictionText"
                            id="newRestrictionText"
                            placeholder={
                              ApplicationString.module.vehicleUpdateForm.fields
                                .placeHolder.addRestrictions
                            }
                            autoComplete="newAmenityText"
                            className={`${cssUtils.inputClasses} bg-white mr-2`}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleNewRestrictions(e);
                            }}
                          />
                          <div
                            role="button"
                            tabIndex={0}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                handleSaveRestrictions();
                              }
                            }}
                            className={`${cssUtils.button} py-2 mr-2`}
                            onClick={handleSaveRestrictions}
                          >
                            {
                              ApplicationString.module.vehicleUpdateForm.fields
                                .buttonLabels.save
                            }
                          </div>
                          <div
                            role="button"
                            tabIndex={0}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                setOpenAddRestrictions(false);
                              }
                            }}
                            className="cursor-pointer rounded-lg  p-4 text-black py-2 transition hover:bg-white hover:text-black"
                            onClick={() => setOpenAddRestrictions(false)}
                          >
                            {
                              ApplicationString.module.vehicleUpdateForm.fields
                                .buttonLabels.cancel
                            }
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="vehicle-location-details-form-group">
                  <h1 className="text-base font-semibold leading-20 text-gray-900 mt-8">
                    {
                      ApplicationString.module.vehicleUpdateForm.titles
                        .VehicleLocationDetails
                    }
                  </h1>
                  <p className="text-base font-semibold leading-7 text-gray-900 mt-5">
                    {
                      ApplicationString.module.vehicleUpdateForm.subtitles
                        .pickup
                    }
                  </p>

                  <div className="mt-5">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .country
                      }
                    </label>
                    <div className="mt-2">
                      <Select
                        options={countriesDataOptions}
                        name="country"
                        className="mt-1 p-2  rounded-md w-full bg-white shadow-sm focus:outline-none focus:border-blue-500 appearance-none"
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                    <label
                      htmlFor="State"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .state
                      }
                    </label>
                    <div className="mt-2">
                      <Select
                        options={stateDataOptions}
                        name="country"
                        className="mt-1 p-2  rounded-md w-full bg-white shadow-sm focus:outline-none focus:border-blue-500 appearance-none"
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .city
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="city"
                        id="city"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.city
                        }
                        autoComplete="city"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                    <span className="mt-1 text-red-500">
                      <ErrorMessage name="city" />
                    </span>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .address1
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="address"
                        id="address"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.address1
                        }
                        autoComplete="address"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                    <span className="mt-1 text-red-500">
                      <ErrorMessage name="address" />
                    </span>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="address2"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .address2
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="address2"
                        id="address2"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.address2
                        }
                        autoComplete="address2"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="Zip"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .Zip
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="zip"
                        id="zip"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.Zip
                        }
                        autoComplete="zip"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                    <span className="mt-1 text-red-500">
                      <ErrorMessage name="zip" />
                    </span>
                  </div>

                  <div>
                    <label
                      htmlFor="sameLocation"
                      className="block text-sm font-medium leading-6 text-gray-900 mt-4"
                    >
                      <Field
                        name="sameLocation"
                        type="checkbox"
                        className="mr-2.5 accent-slate-500 "
                        checked={values.sameLocation}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleSameDropOffLocation(e, setFieldValue, values);
                        }}
                      />
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .sameLocation
                      }
                    </label>
                  </div>

                  <p className="text-base font-semibold leading-7 text-gray-900 mt-3">
                    {
                      ApplicationString.module.vehicleUpdateForm.subtitles
                        .DropOff
                    }
                  </p>

                  <div className="mt-5">
                    <label
                      htmlFor="dropState"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .state
                      }
                    </label>
                    <div className="mt-2">
                      <Select
                        options={stateDataOptions}
                        name="country"
                        className="mt-1 p-2  rounded-md w-full bg-white shadow-sm focus:outline-none focus:border-blue-500 appearance-none"
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="dropCity"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .city
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="dropCity"
                        id="dropCity"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.city
                        }
                        autoComplete="dropCity"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="dropAddress"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .address1
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="dropAddress"
                        id="dropAddress"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.address1
                        }
                        autoComplete="dropAddress"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="dropAddress2"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .address2
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="dropAddress2"
                        id="dropAddress2"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.address2
                        }
                        autoComplete="dropAddress2"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="dropZip"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .Zip
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="dropZip"
                        id="dropZip"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.Zip
                        }
                        autoComplete="dropZip"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                    <span className="mt-1 text-red-500">
                      <ErrorMessage name="zip" />
                    </span>
                  </div>
                </div>
                <div className="vehicle-booking-details-form-group">
                  <h1 className="text-base font-semibold leading-7 text-gray-900 mt-8">
                    {
                      ApplicationString.module.vehicleUpdateForm.titles
                        .VehicleBookingVehicle
                    }
                  </h1>
                  <p className="text-base font-semibold leading-7 text-gray-900 mt-4">
                    {
                      ApplicationString.module.vehicleUpdateForm.fields.label
                        .bookingSlots
                    }
                  </p>
                  <div className="booking-slots-group grid grid-cols-3 pt-4 gap-5 w-full">
                    <FieldArray
                      name="bookingSlotType"
                      render={(arrayHelpers) => {
                        return (
                          <>
                            <div className="mt-2">
                              <div
                                className={`${cssUtils.inputClasses} bg-white`}
                              >
                                <label htmlFor="hourly">
                                  <Field
                                    type="checkbox"
                                    name="bookingSlotType"
                                    value="hourly"
                                    checked={
                                      Array.isArray(values.bookingSlotType) &&
                                      values.bookingSlotType.includes('hourly')
                                    }
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      if (e.target.checked) {
                                        arrayHelpers.push('hourly');
                                      } else {
                                        const idx =
                                          values.bookingSlotType.indexOf(
                                            'hourly'
                                          );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                    className="mr-2.5 accent-slate-500"
                                  />
                                  {
                                    ApplicationString.module.vehicleUpdateForm
                                      .fields.radioValues.bookingSlots
                                      .bookingSlotHourly
                                  }
                                </label>
                              </div>
                              {Array.isArray(values.bookingSlotType) &&
                                values.bookingSlotType.includes('hourly') && (
                                  <div>
                                    <p className="mt-5 ml-1">
                                      Set per hour price
                                    </p>
                                    <div>
                                      <Field
                                        type="number"
                                        name="bookingRateHourly"
                                        id="bookingRateHourly"
                                        placeholder={
                                          ApplicationString.module
                                            .vehicleUpdateForm.fields
                                            .placeHolder.bookingRateHourly
                                        }
                                        className={`${cssUtils.inputClasses} bg-white mt-2 w-50`}
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div className="mt-2">
                              <div
                                className={`${cssUtils.inputClasses} bg-white`}
                              >
                                <label htmlFor="12hourly">
                                  <Field
                                    type="checkbox"
                                    name="bookingSlotType"
                                    value="12hourly"
                                    checked={
                                      Array.isArray(values.bookingSlotType) &&
                                      values.bookingSlotType.includes(
                                        '12hourly'
                                      )
                                    }
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      if (e.target.checked) {
                                        arrayHelpers.push('12hourly');
                                      } else {
                                        const idx =
                                          values.bookingSlotType.indexOf(
                                            '12hourly'
                                          );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                    className="mr-2.5 accent-slate-500"
                                  />
                                  {
                                    ApplicationString.module.vehicleUpdateForm
                                      .fields.radioValues.bookingSlots
                                      .bookingSlot12Hourly
                                  }
                                </label>
                              </div>
                              {Array.isArray(values.bookingSlotType) &&
                                values.bookingSlotType.includes('12hourly') && (
                                  <div>
                                    <p className="mt-5 ml-1">
                                      Set 12 hour price
                                    </p>
                                    <div>
                                      <Field
                                        type="number"
                                        name="bookingRate12Hourly"
                                        id="bookingRate12Hourly"
                                        placeholder={
                                          ApplicationString.module
                                            .vehicleUpdateForm.fields
                                            .placeHolder.bookingRate12Hourly
                                        }
                                        className={`${cssUtils.inputClasses} bg-white mt-2 w-50`}
                                      />
                                    </div>
                                    <Select
                                      options={twelveHourlyTimeFrameOptions}
                                      name="country"
                                      className="mt-1 p-2 rounded-md w-full bg-white shadow-sm focus:outline-none focus:border-blue-500 appearance-none"
                                    />
                                  </div>
                                )}
                            </div>

                            <div className="mt-2 col-span-1">
                              <div
                                className={`${cssUtils.inputClasses} bg-white`}
                              >
                                <label htmlFor="24hourly">
                                  <Field
                                    type="checkbox"
                                    name="bookingSlotType"
                                    value="24hourly"
                                    checked={
                                      Array.isArray(values.bookingSlotType) &&
                                      values.bookingSlotType.includes(
                                        '24hourly'
                                      )
                                    }
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      if (e.target.checked) {
                                        arrayHelpers.push('24hourly');
                                      } else {
                                        const idx =
                                          values.bookingSlotType.indexOf(
                                            '24hourly'
                                          );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                    className="mr-2.5 accent-slate-500"
                                  />
                                  {
                                    ApplicationString.module.vehicleUpdateForm
                                      .fields.radioValues.bookingSlots
                                      .bookingSlot24Hourly
                                  }
                                </label>
                              </div>
                              {Array.isArray(values.bookingSlotType) &&
                                values.bookingSlotType.includes('24hourly') && (
                                  <div>
                                    <p className="mt-5 ml-1">
                                      Set 24 hour price
                                    </p>
                                    <div>
                                      <Field
                                        type="number"
                                        name="bookingRate24Hourly"
                                        id="bookingRate24Hourly"
                                        value={values.bookingRate24Hourly}
                                        autoComplete="bookingRate24Hourly"
                                        className={`${cssUtils.inputClasses} bg-white mt-2 w-50`}
                                      />
                                    </div>
                                    <Field
                                      type="number"
                                      name="maxDaysFor24Hourly"
                                      id="maxDaysFor24Hourly"
                                      value={values.maxDaysFor24Hourly}
                                      placeholder={
                                        ApplicationString.module
                                          .vehicleUpdateForm.fields.placeHolder
                                          .maxDays
                                      }
                                      autoComplete="maxDaysFor24Hourly"
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        handleAdd24HourDays(e, setFieldValue);
                                      }}
                                      className={`${cssUtils.inputClasses} bg-white w-50 mt-0.5`}
                                    />
                                  </div>
                                )}
                            </div>
                          </>
                        );
                      }}
                    />
                  </div>

                  <p className="text-base font-semibold leading-7 text-gray-900 mt-8">
                    {
                      ApplicationString.module.vehicleUpdateForm.fields.label
                        .multipleBookings
                    }
                  </p>
                  <div className="isMultiple-booking-slots-group grid grid-cols-2 pt-4 gap-5 w-1/2">
                    <div className={`mt-2 ${cssUtils.inputClasses} bg-white`}>
                      <label htmlFor="isMultipleBookingSameDay">
                        <Field
                          type="radio"
                          name="isMultipleBookingSameDayYes"
                          value
                          checked={values.isMultipleBookingSameDay === true}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleIsMultipleSameDayBookingRadioChange(
                              e,
                              setFieldValue
                            );
                          }}
                          className="mr-2.5 accent-slate-500 "
                        />
                        {
                          ApplicationString.module.vehicleUpdateForm.fields
                            .radioValues.bookingOnSameDay.yes
                        }
                      </label>
                      <span className="mt-1 text-red-500">
                        <ErrorMessage name="isMultipleBookingSameDay" />
                      </span>
                    </div>
                    <div className={`mt-2 ${cssUtils.inputClasses} bg-white`}>
                      <label htmlFor="isMultipleBookingSameDayNo">
                        <Field
                          type="radio"
                          name="isMultipleBookingSameDay"
                          value={false}
                          checked={values.isMultipleBookingSameDay === false}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleIsMultipleSameDayBookingRadioChange(
                              e,
                              setFieldValue
                            );
                          }}
                          className="mr-2.5 accent-slate-500 "
                        />
                        {
                          ApplicationString.module.vehicleUpdateForm.fields
                            .radioValues.bookingOnSameDay.no
                        }
                      </label>
                      <span className="mt-1 text-red-500">
                        <ErrorMessage name="isMultipleBookingSameDay" />
                      </span>
                    </div>
                  </div>
                  <p className="text-base font-semibold leading-7 text-gray-900 mt-8">
                    {
                      ApplicationString.module.vehicleUpdateForm.fields.label
                        .timeInBetweenBookings
                    }
                  </p>
                  <div className="time-needed-inBetween-booking-slots-group pt-4 grid grid-cols-3 gap-10">
                    <div className={`${cssUtils.inputClasses} bg-white`}>
                      <label htmlFor="bookingGap1H">
                        <Field
                          type="radio"
                          name="bookingGap"
                          value={1}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleBookingGapRadioChange(e, setFieldValue);
                          }}
                          className="mr-2.5 accent-slate-500 "
                        />
                        {
                          ApplicationString.module.vehicleUpdateForm.fields
                            .bookingGapHours.oneHour
                        }
                      </label>
                    </div>
                    <div className={`${cssUtils.inputClasses} bg-white`}>
                      <label htmlFor="bookingGap2H">
                        <Field
                          type="radio"
                          name="bookingGap"
                          value={2}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleBookingGapRadioChange(e, setFieldValue);
                          }}
                          className="mr-2.5 accent-slate-500 "
                        />
                        {
                          ApplicationString.module.vehicleUpdateForm.fields
                            .bookingGapHours.twoHour
                        }
                      </label>
                    </div>
                    <div className={`${cssUtils.inputClasses} bg-white`}>
                      <label htmlFor="bookingGap4H">
                        <Field
                          type="radio"
                          name="bookingGap"
                          value={4}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleBookingGapRadioChange(e, setFieldValue);
                          }}
                          className="mr-2.5 accent-slate-500 "
                        />
                        {
                          ApplicationString.module.vehicleUpdateForm.fields
                            .bookingGapHours.fourHour
                        }
                      </label>
                    </div>
                    <div className={`${cssUtils.inputClasses} bg-white`}>
                      <label htmlFor="bookingGap8H">
                        <Field
                          type="radio"
                          name="bookingGap"
                          value={8}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleBookingGapRadioChange(e, setFieldValue);
                          }}
                          className="mr-2.5 accent-slate-500 "
                        />
                        {
                          ApplicationString.module.vehicleUpdateForm.fields
                            .bookingGapHours.eightHour
                        }
                      </label>
                    </div>
                    <div className={`${cssUtils.inputClasses} bg-white`}>
                      <label htmlFor="bookingGap12H">
                        <Field
                          type="radio"
                          name="bookingGap"
                          value={12}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleBookingGapRadioChange(e, setFieldValue);
                          }}
                          className="mr-2.5 accent-slate-500 "
                        />
                        {
                          ApplicationString.module.vehicleUpdateForm.fields
                            .bookingGapHours.twelveHour
                        }
                      </label>
                    </div>
                    <div className={`${cssUtils.inputClasses} bg-white`}>
                      <label htmlFor="bookingGap24H">
                        <Field
                          type="radio"
                          name="bookingGap"
                          value={24}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleBookingGapRadioChange(e, setFieldValue);
                          }}
                          className="mr-2.5 accent-slate-500 "
                        />
                        {
                          ApplicationString.module.vehicleUpdateForm.fields
                            .bookingGapHours.twentyFourHour
                        }
                      </label>
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="shortNoticePeriod"
                      className="block text-sm font-medium leading-6 text-gray-900 mt-5"
                    >
                      {
                        ApplicationString.module.vehicleUpdateForm.fields.label
                          .shortNoticePeriod
                      }
                    </label>
                    <div className="mt-2">
                      <Field
                        type="number"
                        name="shortNoticePeriod"
                        id="shortNoticePeriod"
                        placeholder={
                          ApplicationString.module.vehicleUpdateForm.fields
                            .placeHolder.shortNoticePeriod
                        }
                        autoComplete="shortNoticePeriod"
                        className={`${cssUtils.inputClasses} bg-white`}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-10 flex items-center justify-end gap-x-4">
                  <button type="submit" className={`${cssUtils.button} py-2`}>
                    {ApplicationString.module.updateProfile.button.submit}
                  </button>
                  <button
                    type="button"
                    onClick={getVehicleUpdateDetails}
                    className="cursor-pointer rounded-lg  p-4 text-black py-2 transition hover:bg-slate-400 hover:text-white"
                  >
                    {ApplicationString.module.updateProfile.button.reset}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default VehicleUpdateComponent;
