import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ApplicationString from '../../../Constants/applicationString';
import InternalRoute from '../../../Utils/internalRoutes';

const WelcomePage: React.FC = () => {
  // TODO: Below Navigation will be removed when dashboard is completed --------------------------------------
  const navigate = useNavigate();
  useEffect(() => {
    navigate(InternalRoute.UserManagement);
  }, [navigate]);
  // TODO: Above Navigation will be removed when dashboard is completed --------------------------------------
  return (
    <div className="bg-gray-100 ">
      <div className="flex mt-50 flex-col align-middle justify-center items-center">
        {/* <img
          src="https://www.svgrepo.com/show/426192/cogs-settings.svg"
          alt="Logo"
          className="mb-8 h-40"
        /> */}
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-center text-gray-700 mb-4">
          {ApplicationString.dashboardTitles.welcome.text}
        </h1>
        {/* <p className="text-center text-gray-500 text-lg md:text-xl lg:text-2xl mb-8">
          {`We're working hard to improve the user experience. Stay tuned!`}
        </p> */}
      </div>
    </div>
  );
};

export default WelcomePage;
