import React from 'react';
import AppRouter from './Routes/appRouter';
import './Assets/Css/style.css';
import Spinner from './Components/Common/spinner/spinner';
import Loader from './Components/Common/spinner/loader';

const App: React.FC = () => {
  return (
    <>
      <Spinner />
      <Loader />
      <AppRouter />
    </>
  );
};

export default App;
