/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-expressions */
// Todo - Disabled this error as had to put condition to show loader if path does not contain pdf.
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HIDE_LOADER, SHOW_LOADER } from '../../../Constants/actionType';
import ApplicationString from '../../../Constants/applicationString';

interface IAttachmentModalProps {
  onCancel: () => void;
  path: string;
}
const AttachmentModal: React.FC<IAttachmentModalProps> = ({
  onCancel,
  path,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const noData = ApplicationString.module.contact.table.columns.noData;
  useEffect(() => {
    !path?.includes('pdf') &&
      isLoading &&
      dispatch({ type: SHOW_LOADER }) &&
      noData;
  }, [dispatch, isLoading, path, noData]);

  return (
    <div className="fixed z-10 inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-30 w-full">
      <div className="bg-white p-6 rounded-lg shadow-xl w-1/4">
        <p className="text-lg font-medium mb-4">View Attachment</p>
        {path ? (
          path.includes('pdf') ? (
            <a
              href={path}
              aria-label="PDF"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer p-4 rounded-lg border border-slate-500 bg-slate-500 text-white transition hover:bg-opacity-90 flex items-center text-center justify-center"
            >
              {ApplicationString.module.contact.attachmentPath.pdf}
            </a>
          ) : (
            <img
              src={path}
              alt=""
              style={{ visibility: isLoading ? 'hidden' : 'visible' }}
              onLoad={() => {
                setIsLoading(false);
                dispatch({ type: HIDE_LOADER });
              }}
            />
          )
        ) : (
          <div className="text-center">
            <p>{noData}</p>
          </div>
        )}

        <div className="flex justify-end gap-4 mt-3">
          <button
            type="button"
            onClick={onCancel}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            {ApplicationString.table.actions.confirm.cancelButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AttachmentModal;
