/* eslint-disable react/self-closing-comp */
import React from 'react';

const AscendingArrow = (props: { color: string }): JSX.Element => {
  const { color } = props;
  return (
    <svg
      fill={color}
      width="14px"
      height="14px"
      viewBox="0 0 52 52"
      enableBackground="new 0 0 52 52"
      className="icon flat-color flex items-center justify-center"
    >
      <path d="M43.7,38H8.3c-1,0-1.7-1.3-0.9-2.2l17.3-21.2c0.6-0.8,1.9-0.8,2.5,0l17.5,21.2C45.4,36.7,44.8,38,43.7,38z" />{' '}
    </svg>
  );
};
const DescendingArrow = (props: { color: string }): JSX.Element => {
  const { color } = props;

  return (
    <svg
      fill={color}
      width="14px"
      height="14px"
      viewBox="0 0 52 52"
      enableBackground="new 0 0 52 52"
      className="icon flat-color flex items-center justify-center"
    >
      <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z" />
    </svg>
  );
};

const EditSvgIcon = (): JSX.Element => (
  <svg
    fill="#000000"
    width="14px"
    height="14px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.846 1.403l3.752 3.753.625-.626A2.653 2.653 0 0015.471.778l-.625.625zm2.029 5.472l-3.752-3.753L1.218 15.028 0 19.998l4.97-1.217L16.875 6.875z"
      fill="#5C5F62"
    />
  </svg>
);
const ViewSvgIcon = (): JSX.Element => (
  <svg
    fill="#000000"
    width="15px"
    height="15px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42 42"
    enableBackground="new 0 0 42 42"
  >
    <path
      d="M15.3,20.1c0,3.1,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7s-2.6-5.7-5.7-5.7S15.3,17,15.3,20.1z M23.4,32.4
	C30.1,30.9,40.5,22,40.5,22s-7.7-12-18-13.3c-0.6-0.1-2.6-0.1-3-0.1c-10,1-18,13.7-18,13.7s8.7,8.6,17,9.9
	C19.4,32.6,22.4,32.6,23.4,32.4z M11.1,20.7c0-5.2,4.4-9.4,9.9-9.4s9.9,4.2,9.9,9.4S26.5,30,21,30S11.1,25.8,11.1,20.7z"
    />
  </svg>
);
const CloseSvgIcon = (): JSX.Element => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#000000"
      fillRule="evenodd"
      d="M11.2929,3.29289 C11.6834,2.90237 12.3166,2.90237 12.7071,3.29289 C13.0976,3.68342 13.0976,4.31658 12.7071,4.70711 L9.41421,8 L12.7071,11.2929 C13.0976,11.6834 13.0976,12.3166 12.7071,12.7071 C12.3166,13.0976 11.6834,13.0976 11.2929,12.7071 L8,9.41421 L4.70711,12.7071 C4.31658,13.0976 3.68342,13.0976 3.29289,12.7071 C2.90237,12.3166 2.90237,11.6834 3.29289,11.2929 L6.58579,8 L3.29289,4.70711 C2.90237,4.31658 2.90237,3.68342 3.29289,3.29289 C3.68342,2.90237 4.31658,2.90237 4.70711,3.29289 L8,6.58579 L11.2929,3.29289 Z"
    />
  </svg>
);

const DeleteSvgIcon = (): JSX.Element => (
  <svg
    fill="#000000"
    width="14px"
    height="14px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.755,20.283,4,8H20L18.245,20.283A2,2,0,0,1,16.265,22H7.735A2,2,0,0,1,5.755,20.283ZM21,4H16V3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3V4H3A1,1,0,0,0,3,6H21a1,1,0,0,0,0-2Z" />
  </svg>
);

const VehicleSvgIcon = (): JSX.Element => (
  <svg
    fill="#fff"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    width="28px"
    height="28px"
    viewBox="0 0 583.95 583.95"
  >
    <g>
      <g id="Layer_1_12_">
        <g>
          <path
            d="M379.063,89.25c-14.025,0-25.5,11.475-25.5,25.5s11.475,25.5,25.5,25.5c30.6,0,54.824,24.225,54.824,54.825
				c0,14.025,11.476,25.5,25.5,25.5c14.025,0,25.5-11.475,25.5-25.5C486.163,137.7,437.713,89.25,379.063,89.25z"
          />
          <path
            d="M379.063,0c-14.025,0-25.5,11.475-25.5,25.5s11.475,25.5,25.5,25.5c79.05,0,144.074,65.025,144.074,144.075
				c0,14.025,11.476,25.5,25.5,25.5c14.025,0,25.5-11.475,25.5-25.5C574.138,87.975,486.163,0,379.063,0z"
          />
          <path
            d="M445.363,354.45c-2.55-11.476-11.476-19.125-22.95-19.125h-6.375l-5.1-52.275c-5.101-58.65-53.551-102-112.2-102H175.063
				c-58.65,0-107.1,44.625-112.2,102l-3.825,52.275h-6.375c-11.475,0-20.4,7.649-22.95,19.125L10.588,464.1
				c-2.55,14.025,1.275,26.775,10.2,38.25c8.925,10.2,21.675,16.575,34.425,16.575v17.851c0,25.5,20.4,47.175,45.9,47.175h14.025
				c25.5,0,45.9-20.4,45.9-47.175v-17.851h151.725v17.851c0,25.5,20.4,47.175,47.176,47.175h14.024
				c25.5,0,47.175-20.4,47.175-47.175v-17.851c12.75,0,25.5-6.375,34.426-16.575c8.925-10.199,12.75-24.225,10.199-38.25
				L445.363,354.45z M94.738,476.85c-21.675,0-39.525-17.85-39.525-39.524S73.063,397.8,94.738,397.8
				c21.675,0,39.525,17.851,39.525,39.525S116.413,476.85,94.738,476.85z M111.313,335.325l5.1-47.175
				c2.55-31.875,29.325-54.825,59.925-54.825h123.675c31.875,0,57.375,24.225,59.926,54.825l5.1,47.175H111.313z M380.338,476.85
				c-21.675,0-39.524-17.85-39.524-39.524s17.85-39.525,39.524-39.525s39.525,17.851,39.525,39.525S402.013,476.85,380.338,476.85z"
          />
        </g>
      </g>
    </g>
  </svg>
);
const HomeSvgIcon = (): JSX.Element => (
  <svg
    className="w-3 h-3 me-2.5"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
  </svg>
);
const RightArrowSvgIcon = (): JSX.Element => (
  <svg
    className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 6 10"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m1 9 4-4-4-4"
    />
  </svg>
);

const UserApproveSvg = (): JSX.Element => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.02975 3.3437C10.9834 2.88543 13.0166 2.88543 14.9703 3.3437C17.7916 4.00549 19.9945 6.20842 20.6563 9.02975C21.1146 10.9834 21.1146 13.0166 20.6563 14.9703C19.9945 17.7916 17.7916 19.9945 14.9703 20.6563C13.0166 21.1146 10.9834 21.1146 9.02975 20.6563C6.20842 19.9945 4.0055 17.7916 3.3437 14.9703C2.88543 13.0166 2.88543 10.9834 3.3437 9.02974C4.0055 6.20841 6.20842 4.00549 9.02975 3.3437ZM15.0524 10.4773C15.2689 10.2454 15.2563 9.88195 15.0244 9.6655C14.7925 9.44906 14.4291 9.46159 14.2126 9.6935L11.2678 12.8487L9.77358 11.3545C9.54927 11.1302 9.1856 11.1302 8.9613 11.3545C8.73699 11.5788 8.73699 11.9425 8.9613 12.1668L10.8759 14.0814C10.986 14.1915 11.1362 14.2522 11.2919 14.2495C11.4477 14.2468 11.5956 14.181 11.7019 14.0671L15.0524 10.4773Z"
      fill="#363853"
    />
  </svg>
);

const UserEnableSvg = (): JSX.Element => (
  <svg
    width="15px"
    height="15px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#444"
      d="M7.5 14.4c-0.8-0.8-0.8-2 0-2.8s2-0.8 2.8 0l0.6 0.6 1.9-2.1c-0.7-0.4-1.3-0.4-2-0.4-0.7-0.1-1.4-0.3-1.4-0.9s0.8-0.4 1.4-1.5c0 0 2.7-7.3-2.9-7.3-5.5 0-2.8 7.3-2.8 7.3 0.6 1 1.4 0.8 1.4 1.5s-0.7 0.7-1.4 0.8c-1.1 0.1-2.1-0.1-3.1 1.7-0.6 1.1-0.9 4.7-0.9 4.7h8l-1.6-1.6z"
    />
    <path fill="#444" d="M12.8 16h2.1c0 0-0.1-0.9-0.2-2l-1.9 2z" />
    <path
      fill="#444"
      d="M11 16c-0.3 0-0.5-0.1-0.7-0.3l-2-2c-0.4-0.4-0.4-1 0-1.4s1-0.4 1.4 0l1.3 1.3 3.3-3.6c0.4-0.4 1-0.4 1.4-0.1 0.4 0.4 0.4 1 0.1 1.4l-4 4.3c-0.3 0.3-0.5 0.4-0.8 0.4 0 0 0 0 0 0z"
    />
  </svg>
);

const ResetPasswordSvg = (): JSX.Element => (
  <svg
    width="15px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      stroke="#000"
      strokeWidth="2"
      d="M20,8 C18.5974037,5.04031171 15.536972,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 L12,21 C16.9705627,21 21,16.9705627 21,12 M21,3 L21,9 L15,9"
    />
  </svg>
);
const StatusChangeSvg = (): JSX.Element => (
  <svg
    fill="none"
    height="15px"
    viewBox="0 0 24 24"
    width="15px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 17.75C3 19.5449 4.45507 21 6.25 21H11.1651L11.5209 19.5768C11.6829 18.9288 12.018 18.3371 12.4903 17.8648L18.3927 11.9624C19.1066 11.2485 20.0672 10.9318 21 11.0122V6.25C21 4.45507 19.5449 3 17.75 3H11V7.75C11 9.54493 9.54493 11 7.75 11H3V17.75ZM9.5 3.44L3.44 9.5H7.75C8.7165 9.5 9.5 8.7165 9.5 7.75V3.44ZM19.0999 12.6695L13.1974 18.5719C12.8533 18.916 12.6092 19.3472 12.4911 19.8194L12.0334 21.6501C11.8344 22.4462 12.5556 23.1674 13.3517 22.9683L15.1824 22.5106C15.6545 22.3926 16.0857 22.1485 16.4299 21.8043L22.3323 15.9019C23.2249 15.0093 23.2249 13.5621 22.3323 12.6695C21.4397 11.7768 19.9925 11.7768 19.0999 12.6695Z"
      fill="#212121"
    />
  </svg>
);

const PopularStatusChangeSvg = (): JSX.Element => (
  <svg
    fill="#000000"
    height="15px"
    width="15px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.001 512.001"
    transform="rotate(90)"
  >
    <path d="M506.513,311.066L253.87,58.423c-2.024-2.026-4.486-3.559-7.195-4.483L140.15,17.593 c-6.758-2.305-14.242-0.568-19.294,4.483L84.709,58.222L31.977,5.491c-7.314-7.315-19.176-7.315-26.49,0 c-7.315,7.315-7.315,19.175,0,26.49l52.732,52.731l-36.14,36.141c-5.051,5.05-6.79,12.534-4.483,19.294L53.943,246.67 c0.924,2.71,2.458,5.172,4.483,7.197L311.071,506.51c7.314,7.315,19.175,7.315,26.49,0l168.954-168.954 C513.83,330.241,513.83,318.382,506.513,311.066z M227.241,227.238c-21.817,21.819-57.132,21.82-78.952,0 c-21.768-21.768-21.768-57.185,0.001-78.953c21.817-21.819,57.132-21.82,78.953,0C249.009,170.053,249.009,205.47,227.241,227.238 z"></path>{' '}
  </svg>
);

const ProfilePicturePlaceholder = (): JSX.Element => (
  <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
    <svg
      className="absolute w-12 h-12 text-gray-400 -left-1"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);

export {
  AscendingArrow,
  DescendingArrow,
  EditSvgIcon,
  DeleteSvgIcon,
  VehicleSvgIcon,
  HomeSvgIcon,
  RightArrowSvgIcon,
  ViewSvgIcon,
  CloseSvgIcon,
  UserEnableSvg,
  UserApproveSvg,
  ResetPasswordSvg,
  ProfilePicturePlaceholder,
  StatusChangeSvg,
  PopularStatusChangeSvg,
};
