import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import { useDispatch } from 'react-redux';
import VehicleUpdateComponent from '../../../Components/VehicleListing/VehicleUpdate';
import { URLS } from '../../../Constants/urls';
import { ApiRequest } from '../../../Api/api';
import { HIDE_LOADER, SHOW_LOADER } from '../../../Constants/actionType';
import { IApiErrorResponse } from '../../../Interfaces/Common/api.interface';
import { handleApiError } from '../../../Utils/utils';
import { useToast } from '../../../Services/ToastService';
import { IVehiclesData } from '../../../Interfaces/Vehicles';
import ApplicationString from '../../../Constants/applicationString';
import InternalRoute from '../../../Utils/internalRoutes';

const VehicleUpdateContainer: React.FC = () => {
  const { vehicleId } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const [vehicleData, setVehicleData] = useState<IVehiclesData | null>(null);
  // const [vehicleSettingsData, setVehicleSettingsData] =
  //   useState<IUserSettingsData | null>(null);

  const getVehicleById = useCallback(async () => {
    try {
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.get(
        URLS.VEHICLES_MANAGEMENT_BY_ID.replace('#{vehicleId}', `${vehicleId}`)
      );
      if (response?.status === HttpStatusCode.Ok) {
        setVehicleData(response?.data);
        // setVehicleSettingsData(
        //   JSON.parse(localStorage.getItem('userSettings') || '{}')
        // );
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  }, [dispatch, toast, vehicleId]);

  useEffect(() => {
    getVehicleById();
  }, [getVehicleById, vehicleId]);

  const vehicleUpdateDetails = async (values: IVehiclesData): Promise<void> => {
    try {
      dispatch({ type: SHOW_LOADER });
      const res = await ApiRequest.put(
        URLS.VEHICLES_MANAGEMENT_BY_ID.replace(`#{vehicleId}`, `${vehicleId}`),
        values
      );
      if (res?.status === HttpStatusCode.NoContent) {
        toast.success(
          ApplicationString.module.vehicleUpdateForm.toastMsg.success
        );
        navigate(InternalRoute.VehicleManagement);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };

  return (
    <VehicleUpdateComponent
      vehicleData={vehicleData}
      // vehicleSettingsData={vehicleSettingsData}
      vehicleUpdateDetails={vehicleUpdateDetails}
      getVehicleUpdateDetails={getVehicleById}
    />
  );
};

export default VehicleUpdateContainer;
