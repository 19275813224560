import React from 'react';
import { IVehiclesData } from '../../../Interfaces/Vehicles';
import {
  addHttpsToUrl,
  getLocalStorageAccountInfo,
  greaterThanOne,
  renderNAonEmpty,
} from '../../../Utils/utils';
import { IUserSettingsData } from '../../../Interfaces/Settings';
import AccordionItem from '../VehicleAccordian';
import DrawerWrapper from '../../Common/DrawerWrapper';
import ApplicationString from '../../../Constants/applicationString';

const VehicleView: React.FC<{
  onDrawerClose: () => void;
  vehicleData: IVehiclesData;
  title: string;
}> = ({ onDrawerClose, vehicleData, title }) => {
  const userSettingsData =
    getLocalStorageAccountInfo<IUserSettingsData>('settings');
  const categoryRecord =
    userSettingsData &&
    userSettingsData.setting.vehicleCategory.find(
      (i: { id: number }) => i.id === Number(vehicleData.category)
    );
  const subCategoryRecord = categoryRecord?.subCategory.find(
    (i: { id: number }) => i.id === Number(vehicleData.subCategory)
  )?.name;

  const Hourly12TimeFrameLabels = Array.isArray(
    vehicleData?.timeFrameFor12Hourly
  )
    ? vehicleData.timeFrameFor12Hourly.map((item2) => {
        const timeFrames = userSettingsData?.setting?.['12hourlyTimeFrame'];
        const match = Array.isArray(timeFrames)
          ? timeFrames.find(
              (item1) =>
                item1?.value?.to === item2?.to &&
                item1?.value?.from === item2?.from
            )
          : null;
        return (
          match?.label ||
          ApplicationString.module.booking.bookingViewDetails.bookingDetails
            .notApplicable
        );
      })
    : [
        ApplicationString.module.booking.bookingViewDetails.bookingDetails
          .notApplicable,
      ];

  const description = renderNAonEmpty(vehicleData.description);

  const country = renderNAonEmpty(vehicleData.country);

  const dropCountry = renderNAonEmpty(vehicleData.dropCountry);

  const state = renderNAonEmpty(vehicleData.state);

  const dropState = renderNAonEmpty(vehicleData.dropState);

  const city = renderNAonEmpty(vehicleData.city);

  const dropCity = renderNAonEmpty(vehicleData.dropCity);

  const zip = renderNAonEmpty(vehicleData.zip);

  const dropZip = renderNAonEmpty(vehicleData.dropZip);

  const address = renderNAonEmpty(vehicleData.address);

  const dropAddress = renderNAonEmpty(vehicleData.dropAddress);

  const dropAddress2 = renderNAonEmpty(vehicleData.dropAddress2);

  const address2 = renderNAonEmpty(vehicleData.address2);

  const latLong = renderNAonEmpty(vehicleData.latLong);

  const dropLatLong = renderNAonEmpty(vehicleData.dropLatLong);

  const capacity = renderNAonEmpty(vehicleData.capacity);

  const size = vehicleData?.size
    ? `${vehicleData.size}${ApplicationString.module.vehicle.vehicleViewDetails.viewContent.ft}`
    : ApplicationString.module.booking.bookingViewDetails.bookingDetails
        .notApplicable;

  const rating = renderNAonEmpty(vehicleData.rating);

  const bookingRateHourly = vehicleData?.bookingRateHourly
    ? `${ApplicationString.module.vehicle.vehicleViewDetails.viewContent.dollarSign}${vehicleData.bookingRateHourly}`
    : ApplicationString.module.booking.bookingViewDetails.bookingDetails
        .notApplicable;

  const bookingRate12Hourly = vehicleData?.bookingRate12Hourly
    ? `${ApplicationString.module.vehicle.vehicleViewDetails.viewContent.dollarSign}${vehicleData.bookingRate12Hourly}`
    : ApplicationString.module.booking.bookingViewDetails.bookingDetails
        .notApplicable;

  const bookingRate24Hourly = vehicleData?.bookingRate24Hourly
    ? `${ApplicationString.module.vehicle.vehicleViewDetails.viewContent.dollarSign}${vehicleData.bookingRate24Hourly}`
    : ApplicationString.module.booking.bookingViewDetails.bookingDetails
        .notApplicable;

  const maxDaysFor24Hourly = vehicleData?.maxDaysFor24Hourly
    ? `${vehicleData.maxDaysFor24Hourly}${ApplicationString.module.vehicle.vehicleViewDetails.viewContent.days}`
    : ApplicationString.module.booking.bookingViewDetails.bookingDetails
        .notApplicable;

  const amenitiesDescription = renderNAonEmpty(
    vehicleData.metaData.amenitiesDescription
  );

  const engineSize = renderNAonEmpty(vehicleData.metaData.engineSize);

  const hitchSize = renderNAonEmpty(vehicleData.metaData.hitchSize);

  const shortNoticePeriod = greaterThanOne(vehicleData.shortNoticePeriod);

  const bookingGap = greaterThanOne(vehicleData.bookingGap);

  return (
    <DrawerWrapper visible onClose={onDrawerClose} title={title}>
      <div className="w-full flex flex-col gap-5 mt-4">
        <div className="border rounded-lg overflow-hidden">
          <AccordionItem
            title={
              ApplicationString.module.vehicle.vehicleViewDetails
                .vehicleDetailsTitle
            }
          >
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.id
                }
              </h3>
              <span className="text-md text-gray-500">{vehicleData?.id}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.category
                }
              </h3>
              <span className="text-md text-gray-500">
                {
                  userSettingsData?.setting?.vehicleCategory.find(
                    (i: { id: number }) =>
                      i.id === Number(vehicleData?.category)
                  )?.name
                }
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.subCategory
                }
              </h3>
              <span className="text-md text-gray-500">{subCategoryRecord}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.title
                }
              </h3>
              <span className="text-md text-gray-500">
                {vehicleData?.title}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.isEnabled
                }
              </h3>
              <span className="text-md text-gray-500">
                {vehicleData?.isEnabled
                  ? ApplicationString.module.booking.table.filters.options.yes
                  : ApplicationString.module.booking.table.filters.options.no}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.description
                }
              </h3>
              <span className="text-md text-gray-500">{description}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.manufacturingYear
                }
              </h3>
              <span className="text-md text-gray-500">
                {vehicleData?.manufacturingYear}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.maker
                }
              </h3>
              <span className="text-md text-gray-500">
                {vehicleData?.maker}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.model
                }
              </h3>
              <span className="text-md text-gray-500">
                {vehicleData?.model}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.capacity
                }
              </h3>
              <span className="text-md text-gray-500">{capacity}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.size
                }
              </h3>
              <span className="text-md text-gray-500">{size}</span>
            </div>
          </AccordionItem>
        </div>
        <div className="border rounded-lg overflow-hidden">
          <AccordionItem
            title={
              ApplicationString.module.vehicle.vehicleViewDetails
                .vehicleLocationDetailsTitle
            }
          >
            <div className="mt-1 mb-2 text-center font-bold border-y-2">
              <h2>
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.pickUp
                }
              </h2>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.country
                }
              </h3>
              <span className="text-md text-gray-500">{country}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.state
                }
              </h3>
              <span className="text-md text-gray-500">{state}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.city
                }
              </h3>
              <span className="text-md text-gray-500">{city}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.zip
                }
              </h3>
              <span className="text-md text-gray-500">{zip}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.address
                }
              </h3>
              <span className="text-md text-gray-500">{address}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.address2
                }
              </h3>
              <span className="text-md text-gray-500">{address2}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.latLong
                }
              </h3>
              <span className="text-md text-gray-500">{latLong}</span>
            </div>
            <div className="mt-4 mb-2 text-center font-bold border-y-2">
              <h2>
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.dropOff
                }
              </h2>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropCountry
                }
              </h3>
              <span className="text-md text-gray-500">{dropCountry}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropState
                }
              </h3>
              <span className="text-md text-gray-500">{dropState}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropCity
                }
              </h3>
              <span className="text-md text-gray-500">{dropCity}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropZip
                }
              </h3>
              <span className="text-md text-gray-500">{dropZip}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropAddress
                }
              </h3>
              <span className="text-md text-gray-500">{dropAddress}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropAddress2
                }
              </h3>
              <span className="text-md text-gray-500">{dropAddress2}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropLatLong
                }
              </h3>
              <span className="text-md text-gray-500">{dropLatLong}</span>
            </div>
          </AccordionItem>
        </div>

        <div className="border rounded-lg overflow-hidden">
          <AccordionItem
            title={
              ApplicationString.module.vehicle.vehicleViewDetails
                .vehicleBookingDetailsTitle
            }
          >
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.bookingGap
                }
              </h3>
              <span className="text-md text-gray-500">{bookingGap}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.shortNoticePeriod
                }
              </h3>
              <span className="text-md text-gray-500">{shortNoticePeriod}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.isMultipleBookingSameDay
                }
              </h3>
              <span className="text-md text-gray-500">
                {vehicleData?.isMultipleBookingSameDay
                  ? ApplicationString.module.booking.table.filters.options.yes
                  : ApplicationString.module.booking.table.filters.options.no}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.rating
                }
              </h3>
              <span className="text-md text-gray-500">{rating}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.bookingRateHourly
                }
              </h3>
              <span className="text-md text-gray-500">{bookingRateHourly}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.bookingRate12Hourly
                }
              </h3>
              <span className="text-md text-gray-500">
                {bookingRate12Hourly}
              </span>
            </div>
            <div className="grid gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.timeFrameFor12Hourly
                }
              </h3>
              <span className="text-md text-gray-500">
                <ul className="list-disc list-inside">
                  {Hourly12TimeFrameLabels?.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.bookingRate24Hourly
                }
              </h3>
              <span className="text-md text-gray-500">
                {bookingRate24Hourly}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.maxDaysFor24Hourly
                }
              </h3>
              <span className="text-md text-gray-500">
                {maxDaysFor24Hourly}
              </span>
            </div>
          </AccordionItem>
        </div>

        <div className="border rounded-lg overflow-hidden">
          <AccordionItem
            title={
              ApplicationString.module.vehicle.vehicleViewDetails.viewContent
                .metaDataTitle
            }
          >
            <div className="grid gap-2">
              <h3 className="text-xl font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.metaDataTitle
                }
              </h3>
              <div className="flex gap-2">
                <h3 className="text-base font-semibold text-gray-900">
                  {
                    ApplicationString.module.vehicle.vehicleViewDetails
                      .viewContent.metaData.ownershipType
                  }
                </h3>
                <span className="text-md text-gray-500">
                  {vehicleData?.metaData?.ownershipType}
                </span>
              </div>
              <div className="flex gap-2">
                <h3 className="text-base font-semibold text-gray-900">
                  {
                    ApplicationString.module.vehicle.vehicleViewDetails
                      .viewContent.metaData.amenitiesDescription
                  }
                </h3>
                <span className="text-md text-gray-500">
                  {amenitiesDescription}
                </span>
              </div>
              <div className="grid gap-2">
                <h3 className="text-base font-semibold text-gray-900">
                  {
                    ApplicationString.module.vehicle.vehicleViewDetails
                      .viewContent.metaData.amenities
                  }
                </h3>
                <span className="text-md text-gray-500">
                  <ul className="list-disc list-inside">
                    {vehicleData?.metaData?.amenities?.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </span>
              </div>
              <div className="grid gap-2">
                <h3 className="text-base font-semibold text-gray-900">
                  {
                    ApplicationString.module.vehicle.vehicleViewDetails
                      .viewContent.metaData.restricted
                  }
                </h3>
                <span className="text-md text-gray-500">
                  <ul className="list-disc list-inside">
                    {vehicleData?.metaData?.restricted?.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </span>
              </div>
              <div className="flex gap-2">
                <h3 className="text-base font-semibold text-gray-900">
                  {
                    ApplicationString.module.vehicle.vehicleViewDetails
                      .viewContent.metaData.engineSize
                  }
                </h3>
                <span className="text-md text-gray-500">{engineSize}</span>
              </div>
              <div className="flex gap-2">
                <h3 className="text-base font-semibold text-gray-900">
                  {
                    ApplicationString.module.vehicle.vehicleViewDetails
                      .viewContent.metaData.hitchSize
                  }
                </h3>
                <span className="text-md text-gray-500">{hitchSize}</span>
              </div>
              <div className="flex gap-2">
                <h3 className="text-base font-semibold text-gray-900">
                  {
                    ApplicationString.module.vehicle.vehicleViewDetails
                      .viewContent.metaData.isTrailerIncluded
                  }
                </h3>
                <span className="text-md text-gray-500">
                  {vehicleData?.metaData?.isTrailerIncluded
                    ? ApplicationString.module.booking.table.filters.options.yes
                    : ApplicationString.module.booking.table.filters.options.no}
                </span>
              </div>
            </div>
          </AccordionItem>
        </div>
        <div className="border rounded-lg overflow-hidden">
          <AccordionItem
            title={
              ApplicationString.module.vehicle.vehicleViewDetails.viewContent
                .ownerDetailsTitle
            }
          >
            <div className="grid gap-4">
              <h3 className="text-xl font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.ownerDetailsTitle
                }
              </h3>
              <div className="flex gap-2">
                <h3 className="text-base font-semibold text-gray-900">
                  {
                    ApplicationString.module.vehicle.vehicleViewDetails
                      .viewContent.ownerDetails.name
                  }
                </h3>
                <span className="text-md text-gray-500">
                  {vehicleData?.ownerDetails?.name}
                </span>
              </div>
              <div className="flex gap-2">
                <h3 className="text-base font-semibold text-gray-900">
                  {
                    ApplicationString.module.vehicle.vehicleViewDetails
                      .viewContent.ownerDetails.joinedYear
                  }
                </h3>
                <span className="text-md text-gray-500">
                  {vehicleData?.ownerDetails?.joinedYear}
                </span>
              </div>
              <div className="flex gap-2 flex-col">
                <h3 className="text-base font-semibold text-gray-900">
                  {
                    ApplicationString.module.vehicle.vehicleViewDetails
                      .viewContent.ownerDetails.avatarPath
                  }
                </h3>
                <span className="text-md text-gray-500">
                  <div className="group relative">
                    <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 sm:h-64">
                      <img
                        src={addHttpsToUrl(
                          vehicleData?.ownerDetails?.avatarPath
                        )}
                        className="h-full w-full object-cover object-center"
                        alt=""
                      />
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </AccordionItem>
        </div>
      </div>
    </DrawerWrapper>
  );
};

export default VehicleView;
