/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-self-compare */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
// TODO: Disabling this error because I found a solution by adding defaultProps but it is deprecated
// and I couldn't find another solution yet.
import React, { useEffect, useState } from 'react';
import Select, { InputActionMeta } from 'react-select';
import { Filters, FormValues } from '../../../Interfaces/form';
import { IPaginationValues } from '../../../Interfaces/interfaces';
import { DefaultPaginationValues } from '../../../Constants/commonConfig';
import useDebouncedCallback from '../../../hooks/useDebounce';
import ApplicationString from '../../../Constants/applicationString';

interface Iprops {
  inputColumn: Filters[];
  onSearch: (values: FormValues) => void;
  savedFilteredValues?: FormValues;
  isLoadingUserData?: boolean;
  isLoadingVehicleData?: boolean;
  setPagination: (val: IPaginationValues) => void;
  pagination: IPaginationValues;
  handleFilterUserSearch?: (
    newValue: string,
    actionMeta: InputActionMeta,
    filterName?: string
  ) => void;
  handleFilterDropdownSearch?: (
    newValue: string,
    actionMeta: InputActionMeta
  ) => void;
}

const DynamicSearchForm: React.FC<Iprops> = ({
  inputColumn,
  onSearch,
  savedFilteredValues,
  setPagination,
  pagination,
  handleFilterUserSearch,
  handleFilterDropdownSearch,
  isLoadingUserData,
  // isLoadingVehicleData,
}) => {
  const [formValues, setFormValues] = useState<FormValues>({});
  useEffect(() => {
    if (savedFilteredValues) {
      setFormValues(savedFilteredValues);
    }
  }, [savedFilteredValues]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const debouncedHandleFilterUserSearch =
    handleFilterUserSearch && useDebouncedCallback(handleFilterUserSearch, 500);

  // const debouncedHandleFilterDropdownSearch =
  //   handleFilterDropdownSearch &&
  //   useDebouncedCallback(handleFilterDropdownSearch, 500);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPagination({ ...pagination, page: DefaultPaginationValues.PAGE });
    const trimmedFormValues = Object.fromEntries(
      Object.entries(formValues).map(([key, value]) => [
        key,
        typeof value === 'string' ? value.trim() : value,
      ])
    );
    onSearch(trimmedFormValues);
  };

  const handleReset = () => {
    setFormValues({});
    setPagination({ ...pagination, page: DefaultPaginationValues.PAGE });
    onSearch({});
    if (handleFilterDropdownSearch) {
      handleFilterDropdownSearch('', {
        action: 'input-change',
        prevInputValue: '',
      });
    }
  };

  const getFormItems = () => {
    return inputColumn?.map((column) => {
      return (
        <div key={column.key} className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor={column.name}
          >
            {column.label}
          </label>
          {(() => {
            switch (column.type) {
              case 'text':
                return (
                  <input
                    type="text"
                    name={column.name}
                    value={formValues[column.name] || ''}
                    placeholder={`Search by ${column.name}`}
                    onChange={handleChange}
                    className="mt-1 p-2 border-2 border-slate-300 rounded-md w-full border-solid shadow-sm focus:border-blue-500  focus:outline-none"
                  />
                );
              case 'select':
                return (
                  <select
                    name={column.name}
                    value={formValues[column.name] || ''}
                    onChange={handleChange}
                    className="mt-1 p-2 border-2 border-slate-300 rounded-md w-full bg-white shadow-sm focus:outline-none focus:border-blue-500 appearance-none"
                  >
                    <option
                      style={{
                        // Customize the appearance of the options
                        padding: '0.5rem 1rem', // Example padding
                        fontSize: '1rem', // Example font size
                        lineHeight: '1.5rem', // Example line height
                        color: '#333', // Example text color
                        // You can add more styles as needed
                      }}
                      value=""
                      className="text-gray-400"
                    >
                      Select {column.label}
                    </option>
                    {column.options?.map((option) => (
                      <option
                        key={option.key}
                        value={option.value}
                        className="text-gray-900"
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                );
              case 'radio':
                return (
                  <div>
                    {column.options?.map((option) => (
                      <label
                        key={option.key}
                        className="inline-flex items-center mt-1"
                        htmlFor={`${column.name}-search-input`}
                      >
                        <input
                          type="radio"
                          name={column.name}
                          value={option.value}
                          checked={formValues[column.name] === option.value}
                          onChange={handleChange}
                        />
                        <span className="p-2 ml-2 me-2 cursor-pointer">
                          {option.label}
                        </span>
                      </label>
                    ))}
                  </div>
                );

              case 'searchable-dropdown':
                return (
                  <Select
                    className="mt-1"
                    name={column.name}
                    placeholder={`Select by ${column.label.toLocaleLowerCase()}`}
                    isLoading={isLoadingUserData && column.name === column.name}
                    isClearable
                    value={
                      // here added the condition for the value because reselect expect to have value and label object
                      formValues[column.name]
                        ? column.options?.find(
                            (option) => option.value === formValues[column.name]
                          )
                        : null
                    }
                    options={column.options}
                    onChange={(selectedOption) => {
                      // if option is selected then set selected option value to formValues state.
                      if (selectedOption) {
                        setFormValues((prev) => ({
                          ...prev,
                          [`${column.name}`]: `${selectedOption?.value}`,
                        }));
                      } else {
                        setFormValues((prev) => {
                          const newFormValues = { ...prev };
                          delete newFormValues[column.name];
                          return newFormValues;
                        });
                        if (
                          column.name === column.name &&
                          handleFilterUserSearch
                        ) {
                          handleFilterUserSearch('', {
                            action: 'input-change',
                            prevInputValue: '',
                          });
                        }
                      }
                    }}
                    onInputChange={
                      column.name === column.name
                        ? (newValue, actionMeta) => {
                            if (debouncedHandleFilterUserSearch) {
                              debouncedHandleFilterUserSearch(newValue, {
                                ...actionMeta,
                                prevInputValue: '',
                              });
                            }
                            if (actionMeta.action === 'input-change') {
                              setFormValues((prev) => {
                                const newFormValues = { ...prev };
                                delete newFormValues[column.name];
                                return newFormValues;
                              });
                            }
                          }
                        : undefined
                    }
                    onBlur={
                      column.name === column.name
                        ? () => {
                            if (!formValues[column.name]) {
                              // If no value is selected, clear the input and fetch all users
                              setFormValues((prev) => {
                                const newFormValues = { ...prev };
                                delete newFormValues[column.name];
                                return newFormValues;
                              });
                              if (
                                handleFilterUserSearch &&
                                column.options?.length === 0
                              ) {
                                handleFilterUserSearch('', {
                                  action: 'input-change',
                                  prevInputValue: '',
                                });
                              }
                            }
                          }
                        : undefined
                    }
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: state.isFocused
                          ? 'rgb(148 163 184 / 1)'
                          : 'rgb(203 213 225 / 1)',
                        borderWidth: '2px',
                        borderRadius: '0.375rem',
                        padding: '2px',
                        boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                        outline: 'none',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: 'rgb(203 213 225 / 1)',
                      },
                    })}
                  />
                );

              case 'common-dropdown':
                return (
                  <Select
                    className="mt-1"
                    name={column.name}
                    placeholder={`Select by ${column.label.toLocaleLowerCase()}`}
                    // isLoading={isLoadingUserData && column.name === column.name}
                    isClearable
                    value={
                      formValues[column.name]
                        ? column.options?.find(
                            (option) => option.value === formValues[column.name]
                          )
                        : null
                    }
                    options={column.options}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFormValues((prev) => ({
                          ...prev,
                          [`${column.name}`]: `${selectedOption?.value}`,
                        }));
                      } else {
                        setFormValues((prev) => {
                          const newFormValues = { ...prev };
                          delete newFormValues[column.name];
                          return newFormValues;
                        });
                        if (
                          column.name === column.name &&
                          handleFilterDropdownSearch
                        ) {
                          handleFilterDropdownSearch('', {
                            action: 'input-change',
                            prevInputValue: '',
                          });
                        }
                      }
                    }}
                    onInputChange={
                      column.name === column.name
                        ? (newValue, actionMeta) => {
                            if (actionMeta.action === 'input-change') {
                              setFormValues((prev) => {
                                const newFormValues = { ...prev };
                                delete newFormValues[column.name];
                                return newFormValues;
                              });
                            }
                          }
                        : undefined
                    }
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: state.isFocused
                          ? 'rgb(148 163 184 / 1)'
                          : 'rgb(203 213 225 / 1)',
                        borderWidth: '2px',
                        borderRadius: '0.375rem',
                        padding: '2px',
                        boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                        outline: 'none',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: 'rgb(203 213 225 / 1)',
                      },
                    })}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
      );
    });
  };

  return (
    <div className="p-4 border-0 mb-6 bg-white border-solid shadow-xl rounded-2xl bg-clip-border w-full">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-4 gap-5 w-full mr-3 ">
          {getFormItems()}
        </div>
        <div className="flex justify-end mt-4">
          <button
            type="submit"
            className="px-4 bg-slate-600 text-white mr-2 hover:bg-slate-600 rounded"
          >
            {ApplicationString.table.dynamicSearch.search}
          </button>
          <button
            type="button"
            onClick={handleReset}
            className="px-4 rounded-0 py-1 bg-gray-300 text-gray-700 hover:bg-slate-600 hover:text-white rounded"
          >
            {ApplicationString.table.dynamicSearch.reset}
          </button>
        </div>
      </form>
    </div>
  );
};

// DynamicSearchForm.defaultProps = {
//   savedFilteredValues: {},
//   isLoadingUserData: false,
//   handleFilterUserSearch: () => {},
// };
export default DynamicSearchForm;
