import React from 'react';
import { Navigate } from 'react-router-dom';
import DefaultLayout from '../Components/Common/layout/DefaultLayout';
import { IProtectedRoute } from '../Interfaces/interfaces';
import InternalRoute from '../Utils/internalRoutes';
import { getLocalStorageAccountInfo } from '../Utils/utils';
import { LoggedInUserInfoDataType } from '../Interfaces/Login/login.interface';
import { localStorageEnums } from '../Utils/enums';

const protectedRoutes: React.FC<IProtectedRoute> = ({
  element,
  allowedLayout,
  isProtectedRoute,
  userToken,
  allowedRoles,
}: IProtectedRoute): React.ReactElement => {
  const checkLayoutType = (elementParam: IProtectedRoute['element']) => {
    switch (allowedLayout) {
      case 'main':
        return <DefaultLayout>{elementParam}</DefaultLayout>;
      case 'admin':
        return <DefaultLayout>{elementParam}</DefaultLayout>;
      default:
        return elementParam;
    }
  };

  const checkAuthorization = (
    routeElement: IProtectedRoute['element']
  ): React.ReactElement => {
    const userInfo = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
      localStorageEnums.userInfo
    );
    const userRoles = userInfo?.roles;

    if (
      userToken &&
      userRoles &&
      userRoles?.length > 0 &&
      userRoles.some((role) => allowedRoles.includes(role))
    ) {
      return checkLayoutType(routeElement);
    }

    return (
      <Navigate
        to={userToken ? InternalRoute.Unauthorized : InternalRoute.Login}
      />
    );
  };

  return isProtectedRoute ? (
    checkAuthorization(element)
  ) : (
    <>{checkLayoutType(element)}</>
  );
};

export default protectedRoutes;
