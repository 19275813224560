// Common
export const SHOW_LOADER = 'show_loader';
export const HIDE_LOADER = 'hide_loader';
export const GLOBAL_SHOW_LOADER = 'global_show_loader';
export const GLOBAL_HIDE_LOADER = 'global_hide_loader';

// Users
export const SET_ALL_USERS = 'set_all_users';
export const SET_USER_OWNER_APPROVED = 'set_user_owner_approved';
export const SET_USER_ENABLED = 'set_user_enabled';
export const SET_USER_RESET_PASSWORD = 'set_user_reset_password';
export const DELETE_USERS = 'delete_users';
export const SET_USER_SETTINGS = 'set_user_settings';
export const REMOVE_USER_SETTINGS = 'remove_user_settings';
