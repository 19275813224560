import React, { useState } from 'react';
import Table from '../Common/Table';
import { Filters, FormValues, IColumn } from '../../Interfaces/form';
import { IAllUsersData } from '../../Interfaces/Users';
import {
  IPaginationValues,
  ISortParamValues,
} from '../../Interfaces/interfaces';
import UserView from './ViewUserListing';
import { UserDropdownEnum } from '../../Constants/dropdownEnums';
import AvatarComponent from '../Common/Avatar';
import {
  addHttpsToUrl,
  getLocalStorageAccountInfo,
  renderNAonEmpty,
} from '../../Utils/utils';
import TableActions from '../Common/TableAction';
import DynamicSearchForm from '../Common/DynamicSearch';
import TableHeading from '../Common/TableHeading';
import { LoggedInUserInfoDataType } from '../../Interfaces/Login/login.interface';
import ApplicationString from '../../Constants/applicationString';

interface UserListingProps {
  allUsersData: IAllUsersData[];
  pagination: IPaginationValues;
  setPagination: (val: IPaginationValues) => void;
  setPaginationSortAndSearchTrigger: (val: boolean) => void;
  setSortParams: (val: ISortParamValues) => void;
  sortParams: ISortParamValues;
  searchValues: FormValues;
  usersData: IAllUsersData;
  isUserDetailViewVisible: boolean;
  setIsUserDetailViewVisible: (val: boolean) => void;
  handleApproveOwnerClick: (userId: string) => void;
  handleResetPasswordClick: (userId: string) => void;
  handleUserEnableClick: (userId: string) => void;
  handleStatusClick: (
    userId: string,
    isEnabledValue: string,
    url: string,
    statusKey: string,
    successMessage: string,
    failureMessage: string
  ) => void;
  handleUserDeleteClick: (userId: string) => void;
  handleUserViewClick: (data: IAllUsersData) => void;
  handlePageChange: (value: number) => void;
  handlePageSizeChange: (value: number) => void;
  handleSearch: (values: FormValues) => void;
}

const UserListingComponent = ({
  allUsersData,
  pagination,
  setPagination,
  setPaginationSortAndSearchTrigger,
  setSortParams,
  sortParams,
  searchValues,
  usersData,
  isUserDetailViewVisible,
  setIsUserDetailViewVisible,
  handleApproveOwnerClick,
  handleResetPasswordClick,
  handleUserEnableClick,
  handleStatusClick,
  // selectedCheckboxDataIds,
  // setSelectedCheckboxDataIds,
  // setShowCheckboxItems,
  // showCheckboxItems,
  // headerCheckboxState,
  // setHeaderCheckboxState,
  handlePageChange,
  handlePageSizeChange,
  handleUserDeleteClick,
  handleUserViewClick,
  handleSearch,
}: UserListingProps): JSX.Element => {
  const [fieldSearch, setFieldSearch] = useState(false);
  const [zIndex, setZIndex] = useState<boolean>(false);
  const userData =
    getLocalStorageAccountInfo<LoggedInUserInfoDataType>('userInfo');

  const userId = userData && userData?.accountId;
  // const handleCheckboxChange = (id: string, checked: boolean) => {
  //   if (checked) {
  //     setShowCheckboxItems(true);
  //     const ids = selectedCheckboxDataIds || [];
  //     setSelectedCheckboxDataIds([...ids, id]);
  //   } else {
  //     const filterIds = selectedCheckboxDataIds?.filter(
  //       (item: string) => item !== id
  //     );
  //     setSelectedCheckboxDataIds(filterIds);
  //     if (filterIds?.length === 0) {
  //       setShowCheckboxItems(false);
  //     }
  //   }
  // };
  const column: IColumn<IAllUsersData>[] = [
    // {
    //   title: '',
    //   dataIndex: 'checkbox',
    //   key: 'checkbox',
    //   sorter: false,
    //   render: (data) => {
    //     return (
    //       <>
    //         <CheckBoxComponent
    //           handleCheckboxChange={handleCheckboxChange}
    //           recordId={data.id}
    //           checked={selectedCheckboxDataIds?.includes(data.id)}
    //         />
    //       </>
    //     );
    //   },
    // },
    {
      title: ApplicationString.module.user.table.columns.name,
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: ApplicationString.module.user.table.columns.email,
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      render: (data) => {
        const email = renderNAonEmpty(data.email);
        return email;
      },
    },
    {
      title: ApplicationString.module.user.table.columns.mobile,
      dataIndex: 'mobile',
      key: 'mobile',
      sorter: true,
      render: (data) => {
        const mobile = renderNAonEmpty(data.mobile);
        return mobile;
      },
    },
    {
      title: ApplicationString.module.user.table.columns.address1,
      dataIndex: 'address',
      key: 'address',
      sorter: true,
      render: (data) => {
        const address = renderNAonEmpty(data.address);
        return address;
      },
    },
    {
      title: ApplicationString.module.user.table.columns.address2,
      dataIndex: 'address2',
      key: 'address2',
      sorter: true,
      render: (data) => {
        const address2 = renderNAonEmpty(data.address2);
        return address2;
      },
    },
    {
      title: ApplicationString.module.user.table.columns.isOwnerApproved,
      dataIndex: 'isOwner',
      key: 'isOwnerApproved',
      sorter: true,
      render: (data) => {
        return data.isOwner ? 'Yes' : 'No';
      },
    },
    {
      title: ApplicationString.module.user.table.columns.isEnabled,
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      sorter: true,
      render: (data) => {
        const value: string = data.isEnabled ? 'Yes' : 'No';
        return value;
      },
    },
    {
      title: ApplicationString.module.user.table.columns.avatar,
      dataIndex: 'avatarPath',
      key: 'avatar',
      sorter: false,
      render: (data) => {
        return <AvatarComponent source={addHttpsToUrl(data.avatarPath)} />;
      },
    },
    {
      title: ApplicationString.module.user.table.columns.action,
      dataIndex: 'action',
      key: 'action',
      sorter: false,
      fixed: 'right',
      render: (data) => {
        const { id } = data;
        return (
          <TableActions
            onDelete={() => handleUserDeleteClick(data.id)}
            onView={() => handleUserViewClick(data)}
            onApprove={() => handleApproveOwnerClick(data.id)}
            onEnable={() => handleUserEnableClick(data.id)}
            onStatusChange={(
              isEnabledValue,
              statusUrl,
              statusKey,
              successMsg,
              failureMsg
            ) =>
              handleStatusClick(
                data.id,
                isEnabledValue,
                statusUrl,
                statusKey,
                successMsg,
                failureMsg
              )
            }
            onPasswordReset={() => handleResetPasswordClick(data.id)}
            isEnabled={data.isEnabled}
            isPopular={false}
            popularStatus={false}
            setZIndex={setZIndex}
            isOwnerApproved={data.isOwner}
            editBool={false}
            viewBool={data.isEnabled}
            approveBool={userId !== id}
            enableBool={userId !== id}
            deleteBool={false}
            statusBool={false}
            resetPassBool={data.isEnabled}
            text={ApplicationString.module.user.title}
            textApprove={ApplicationString.module.user.table.columns.owner}
          />
        );
      },
    },
  ];

  const fieldCol: Filters[] = [
    {
      key: 'column1',
      label: ApplicationString.module.user.table.filters.name,
      name: 'name',
      type: 'text',
      options: [],
    },
    {
      key: 'column2',
      label: ApplicationString.module.user.table.filters.email,
      name: 'email',
      type: 'text',
      options: [],
    },
    {
      key: 'column3',
      label: ApplicationString.module.user.table.filters.isEnabled,
      name: 'isEnabled',
      type: 'radio',
      options: UserDropdownEnum,
    },
    {
      key: 'column4',
      label: ApplicationString.module.user.table.filters.isOwnerApproved,
      name: 'isOwnerApproved',
      type: 'radio',
      options: UserDropdownEnum,
    },
  ];

  const handleFilterClick = (): void => {
    setFieldSearch(!fieldSearch);
  };

  return (
    <>
      <TableHeading
        hideFilter={false}
        title={ApplicationString.table.heading.userManagement}
        fieldSearch={fieldSearch}
        onFilterClick={() => handleFilterClick()}
        // showCheckboxItems={showCheckboxItems}
        // handleOwnerApproveClick={() => handleApproveOwnerClick()}
        // handleUserEnableClick={() => handleUserEnableClick()}
        // handleResetPasswordClick={() => handleResetPasswordClick()}
      />
      {fieldSearch && (
        <DynamicSearchForm
          inputColumn={fieldCol}
          onSearch={handleSearch}
          setPagination={setPagination}
          pagination={pagination}
          savedFilteredValues={searchValues}
        />
      )}
      <Table<IAllUsersData>
        dataSource={allUsersData}
        columns={column}
        pageSize={pagination.pageSize}
        onPageSizeChange={handlePageSizeChange}
        totalItems={pagination.totalItems}
        onPageChange={handlePageChange}
        totalRecords={pagination.totalItems}
        setSortParams={setSortParams}
        sortParams={sortParams}
        setPaginationSortAndSearchTrigger={setPaginationSortAndSearchTrigger}
        setPagination={setPagination}
        pagination={pagination}
        setZIndex={setZIndex}
        zIndex={zIndex}
        // setSelectedCheckboxDataIds={setSelectedCheckboxDataIds}
        // headerCheckboxState={headerCheckboxState}
        // setHeaderCheckboxState={setHeaderCheckboxState}
        // setShowCheckboxItems={setShowCheckboxItems}
      />
      {isUserDetailViewVisible && (
        <UserView
          onDrawerClose={() => {
            setIsUserDetailViewVisible(false);
          }}
          usersData={usersData}
          title={ApplicationString.module.user.userViewDetails.title}
        />
      )}
    </>
  );
};

export default UserListingComponent;
