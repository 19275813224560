/* eslint-disable react/require-default-props */
import React from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import ApplicationString from '../../../Constants/applicationString';

interface TableHeadingProps {
  title: string;
  onFilterClick?: () => void;
  hideFilter: boolean;
  fieldSearch?: boolean;
  // showCheckboxItems?: boolean;
  // handleOwnerApproveClick?: () => void;
  // handleUserEnableClick?: () => void;
  // handleResetPasswordClick?: () => void;
}

const TableHeading: React.FC<TableHeadingProps> = ({
  title,
  onFilterClick,
  hideFilter,
  fieldSearch,
  // showCheckboxItems,
  // handleOwnerApproveClick,
  // handleUserEnableClick,
  // handleResetPasswordClick,
}) => {
  return (
    <div className="mb-6 flex flex-col w-full h-full min-w-0 break-words bg-white border-0 border-transparent border-solid shadow-xl rounded-2xl bg-clip-border">
      <div className="flex justify-between align-center items-center p-4">
        <div className="bg-white">
          <h6 className="text-xl">{title}</h6>
        </div>
        <div className="flex gap-4">
          {/* {showCheckboxItems && (
            <div className="flex gap-4">
              <button
                type="button"
                onClick={handleOwnerApproveClick}
                className="px-3 py-1 bg-slate-600 text-white hover:bg-slate-600 rounded flex justify-center items-center text-center gap-2"
              >
                {ApplicationString.module.user.table.actions.approveOwner}
              </button>
              <button
                type="button"
                onClick={handleUserEnableClick}
                className="px-3 py-1 bg-slate-600 text-white hover:bg-slate-600 rounded flex justify-center items-center text-center gap-2"
              >
                {ApplicationString.module.user.table.actions.enableUser}
              </button>
              <button
                type="button"
                onClick={handleResetPasswordClick}
                className="px-3 py-1 bg-slate-600 text-white hover:bg-slate-600 rounded flex justify-center items-center text-center gap-2"
              >
                {ApplicationString.module.user.table.actions.resetUsersPassword}
              </button>
            </div>
          )} */}
          {!hideFilter && (
            <div>
              <button
                type="button"
                onClick={onFilterClick}
                className="px-3 py-1 bg-slate-600 text-white hover:bg-slate-600 rounded flex justify-center items-center text-center gap-2"
              >
                {ApplicationString.table.dynamicSearch.filter}{' '}
                {fieldSearch ? <ChevronUp /> : <ChevronDown />}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableHeading;
