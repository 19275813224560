import ApplicationString from './applicationString';

export enum UserEnabledEnum {
  YES = 'true',
  NO = 'false',
}

export const UserDropdownEnum = [
  {
    value: UserEnabledEnum.YES,
    label: ApplicationString.module.user.table.filters.Options.yes,
    key: ApplicationString.module.user.table.filters.Options.yes.toLowerCase(),
  },
  {
    value: UserEnabledEnum.NO,
    label: ApplicationString.module.user.table.filters.Options.no,
    key: ApplicationString.module.user.table.filters.Options.no.toLowerCase(),
  },
];
