/* eslint-disable react/no-array-index-key */
// Todo : Disabling this error as in li we added key with the array index there is no other which will be unique
import { ReactNode, useState } from 'react';
import { IColumn } from '../../../Interfaces/form';
import TotalRecords from '../TotalRecord';
import PageSizeSelector from '../PageSize';
import Pagination from '../Pagination';
import ApplicationString from '../../../Constants/applicationString';
import {
  IPaginationValues,
  ISortParamValues,
} from '../../../Interfaces/interfaces';
import { DefaultSortParams } from '../../../Constants/commonConfig';
import TooltipComponent from '../Tooltip';
import { maxCharString } from '../../../Utils/utils';
import { AscendingArrow, DescendingArrow } from '../../../Assets/Svgs';
import { arr } from '../../../Utils/enums';
// import AvatarComponent from '../Avatar';

interface TableProps<T> {
  dataSource: T[];
  columns: IColumn<T>[];
  pageSize: number;
  onPageChange: (value: number) => void;
  onPageSizeChange: (value: number) => void;
  totalItems: number;
  totalRecords: number;
  setSortParams: (val: ISortParamValues) => void;
  setPaginationSortAndSearchTrigger: (val: boolean) => void;
  setPagination: (val: IPaginationValues) => void;
  pagination: IPaginationValues;
  sortParams: ISortParamValues;
  zIndex: boolean;
  setZIndex: (value: boolean) => void;
  // setSelectedCheckboxDataIds: (val: string[]) => void;
  // setShowCheckboxItems: (val: boolean) => void;
  // headerCheckboxState: boolean;
  // setHeaderCheckboxState: (val: boolean) => void;
}

interface ActiveCell {
  rowIndex: number;
  columnKey: string;
}

const Table = <T extends Record<string, unknown>>({
  dataSource,
  columns,
  pageSize,
  onPageChange,
  onPageSizeChange,
  totalItems,
  totalRecords,
  setSortParams,
  setPaginationSortAndSearchTrigger,
  setPagination,
  pagination,
  sortParams,
  zIndex,
  setZIndex,
  // setSelectedCheckboxDataIds,
  // setShowCheckboxItems,
  // headerCheckboxState,
  // setHeaderCheckboxState,
}: TableProps<T>): JSX.Element => {
  const [sortColumn, setSortColumn] = useState<string | null>(
    sortParams.sortField
  );
  const [sortDirection, setSortDirection] = useState<string>(
    DefaultSortParams.ASC
  );
  const [activeCell, setActiveCell] = useState<ActiveCell | null>(null);

  const handleCellClick = (rowIndex: number, columnKey: string) => {
    setActiveCell({ rowIndex, columnKey });
  };

  const handleSort = (clickedColumn: string) => {
    setSortDirection(
      sortDirection === DefaultSortParams.ASC
        ? DefaultSortParams.DESC
        : DefaultSortParams.ASC
    );
    setSortColumn(clickedColumn);
    setSortParams({
      sortField: clickedColumn,
      sortDirection,
    });
    setPaginationSortAndSearchTrigger(true);
    // setSelectedCheckboxDataIds([]);
    // setHeaderCheckboxState(false);
    // setShowCheckboxItems(false);
  };

  // const handleHeaderCheckboxClick = (checked: boolean) => {
  //   setHeaderCheckboxState(checked);
  //   if (checked) {
  //     setShowCheckboxItems(true);
  //     setSelectedCheckboxDataIds(
  //       Array.isArray(dataSource) ? dataSource.map((item) => item.id) : []
  //     );
  //   } else {
  //     setShowCheckboxItems(false);
  //     setSelectedCheckboxDataIds([]);
  //   }
  // };

  const renderColumn = (data: T, column: IColumn<T>): ReactNode => {
    const { render, dataIndex } = column;
    const propertyName = data[dataIndex];
    if (
      typeof propertyName === 'string' &&
      propertyName?.length > 30 &&
      !arr.includes(dataIndex)
    ) {
      return (
        <TooltipComponent message={propertyName}>
          {maxCharString(propertyName, 20)}
        </TooltipComponent>
      ) as ReactNode;
    }
    if (render) {
      return render(data) as ReactNode;
    }

    return propertyName as ReactNode;
  };

  const getColumnZIndex = (
    isActiveCell: boolean,
    columnKey: string,
    dataIndex: number,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    activeCell: ActiveCell | null
  ) => {
    return isActiveCell ||
      (columnKey === 'action' && activeCell?.rowIndex === dataIndex)
      ? 'z-10'
      : 'z-0';
  };

  return (
    <>
      <div className="flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-xl rounded-2xl bg-clip-border">
        <div className="flex-auto px-0 pt-0 pb-2">
          <div className="p-0 overflow-x-auto rounded-t-2xl">
            <table className=" items-center w-full mb-0 align-top border-gray-200 text-slate-500">
              <thead className="bg-slate-600">
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.key}
                      className={`px-6 py-3 border-gray-10 text-xs flex-col justify-center items-center leading-4 font-medium text-left text-white uppercase tracking-wider w-auto h-auto ${
                        column.fixed
                          ? `sticky ${column.fixed}-0 bg-slate-600`
                          : ''
                      } ${column.sorter && 'cursor-pointer'} `}
                    >
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            if (column.sorter) {
                              handleSort(column.key);
                            }
                          }
                        }}
                        className={`flex ${column.key === 'action' ? 'justify-center' : 'justify-between'}  items-center w-full gap-2 h-full`}
                        onClick={() => column.sorter && handleSort(column.key)}
                      >
                        <span className="flex text-center w-max me-2">
                          {column.title}
                        </span>
                        {column.sorter && (
                          <span
                            key={column.key}
                            className={`${sortDirection === 'asc' ? '' : ' mb-1'}`}
                          >
                            <div className="grid">
                              <AscendingArrow
                                color={
                                  sortDirection === 'desc' &&
                                  sortColumn === column.key
                                    ? '#68AFD4'
                                    : '#ffffff'
                                }
                              />
                              <DescendingArrow
                                color={
                                  sortDirection === 'asc' &&
                                  sortColumn === column.key
                                    ? '#68AFD4'
                                    : '#ffffff'
                                }
                              />

                              {/* <svg
                                  width="14px"
                                  height="14px"
                                  viewBox="0 0 24 24"
                                  fill="#"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={`h-4 w-4 text-gray-400 hover:text-slate-500 ${
                                    sortDirection === 'asc' &&
                                    sortColumn === column.key
                                      ? 'transform rotate-180  text-orange-900'
                                      : ''
                                  }`}
                                >
                                  <path
                                    d="M6.293 4.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1-1.414 1.414L8 7.414V19a1 1 0 1 1-2 0V7.414L3.707 9.707a1 1 0 0 1-1.414-1.414l4-4zM16 16.586V5a1 1 0 1 1 2 0v11.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414L16 16.586z"
                                    fill={
                                      sortDirection === 'asc' &&
                                      sortColumn === column.key
                                        ? '#68AFD4'
                                        : '#ffffff'
                                    }
                                  />
                                </svg> */}
                            </div>
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataSource &&
                Array.isArray(dataSource) &&
                dataSource.length > 0 ? (
                  dataSource?.map((data, dataIndex) => (
                    <tr key={dataIndex}>
                      {columns.map((column) => {
                        const isActiveCell =
                          activeCell?.rowIndex === dataIndex &&
                          activeCell?.columnKey === column.key;
                        return (
                          <td
                            onClick={() =>
                              handleCellClick(dataIndex, column.key)
                            }
                            key={column.key}
                            className={`px-6 ${column.key === 'action' && 'pr-10'} py-3 bg-transparent whitespace-nowrap text-left shadow-transparent  ${
                              column.fixed
                                ? `sticky ${column.fixed}-0 bg-white`
                                : ''
                            } ${zIndex ? getColumnZIndex(isActiveCell, column.key, dataIndex, activeCell) : setZIndex} `}
                          >
                            <span
                              className={`flex ${column.key === 'action' ? 'justify-center' : 'justify-start'}`}
                            >
                              {renderColumn(data, column)}
                            </span>
                          </td>
                        );
                      })}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={columns.length}
                      className="px-6 py-4 text-center"
                    >
                      {ApplicationString.table.noData.text}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-col xl:flex-row items-center justify-between">
        <div className="flex justify-between w-full">
          <TotalRecords totalRecords={totalRecords} />
          <PageSizeSelector
            pageSize={pageSize}
            onPageChange={onPageSizeChange}
          />
        </div>
        <div className="flex sm:w-full w-full xl:w-auto justify-end">
          <Pagination
            pageSize={pageSize}
            pagination={pagination}
            setPagination={setPagination}
            totalItems={totalItems}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </>
  );
};

export default Table;
