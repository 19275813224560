import { createContext, useContext } from 'react';

export interface ToastContextType {
  readonly close: (id: number) => void;
  readonly success: (
    message: string,
    description?: string,
    timeout?: number
  ) => void;
  readonly info: (
    message: string,
    description?: string,
    timeout?: number
  ) => void;
  readonly error: (
    message: string,
    description?: string,
    timeout?: number
  ) => void;
  readonly warning: (
    message: string,
    description?: string,
    timeout?: number
  ) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export default ToastContext;
