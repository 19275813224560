/* eslint-disable react/require-default-props */
// TODO: Disabling this error because I found a solution by adding defaultProps but it is deprecated
// and I couldn't find another solution yet.
import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';

const AccordionItem: React.FC<{
  title: string;
  children?: React.ReactNode;
}> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b">
      <button
        type="button"
        className="flex justify-between items-center w-full px-4 py-2 text-left text-white bg-slate-600 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{title}</span>
        <span>{isOpen ? <ChevronDown /> : <ChevronRight />}</span>
      </button>
      {isOpen && <div className="px-4 py-2 bg-gray-100">{children}</div>}
    </div>
  );
};

// AccordionItem.defaultProps = {
//   children: null,
// };
export default AccordionItem;
