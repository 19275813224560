import React, { useState } from 'react';
import { InputActionMeta } from 'react-select';
// import dayjs from 'dayjs';
import Table from '../Common/Table';
import {
  IPaginationValues,
  ISortParamValues,
} from '../../Interfaces/interfaces';
import { Filters, FormValues, IColumn } from '../../Interfaces/form';
import TableHeading from '../Common/TableHeading';
import DynamicSearchForm from '../Common/DynamicSearch';
import TableActions from '../Common/TableAction';
import BookingViewList from './ViewBookingList';
import { ISelectOptions } from '../../Interfaces/Common/api.interface';
import ApplicationString from '../../Constants/applicationString';
import { IBookingsData } from '../../Interfaces/Bookings';
import { CommonDateFormat } from '../../Constants/commonConst';
import { bookingStatusOptions } from '../../Utils/enums';
import { renderNAonEmpty } from '../../Utils/utils';

const BookingComponent = (props: {
  allBookingData: IBookingsData[];
  allUsersDataOptions: ISelectOptions[];
  pagination: IPaginationValues;
  isLoadingUserData: boolean;
  isLoadingVehicleData: boolean;
  handleFilterUserSearch: (
    newValue: string,
    actionMeta: InputActionMeta
  ) => void;
  setPagination: (val: IPaginationValues) => void;
  setPaginationSortAndSearchTrigger: (val: boolean) => void;
  setSortParams: (val: ISortParamValues) => void;
  sortParams: ISortParamValues;
  searchValues: FormValues;
  handlePageChange: (value: number) => void;
  handlePageSizeChange: (value: number) => void;
  handleSearch: (values: FormValues) => void;
  handleBookingViewClick: (data: IBookingsData) => void;
  isVehicleDetailViewVisible: boolean;
  setIsVehicleDetailViewVisible: (val: boolean) => void;
  bookingData: IBookingsData;
  handleBookingStatusClick: (bookingId: number, typeValue: string) => void;
  handleFilterDropdownSearch: (
    newValue: string,
    actionMeta: InputActionMeta
  ) => void;
}): JSX.Element => {
  const {
    allBookingData,
    allUsersDataOptions,
    handleFilterUserSearch,
    isLoadingUserData,
    isLoadingVehicleData,
    pagination,
    setPagination,
    setPaginationSortAndSearchTrigger,
    setSortParams,
    sortParams,
    searchValues,
    handlePageChange,
    handlePageSizeChange,
    handleSearch,
    handleBookingViewClick,
    isVehicleDetailViewVisible,
    setIsVehicleDetailViewVisible,
    bookingData,
    handleBookingStatusClick,
    handleFilterDropdownSearch,
  } = props;

  const [fieldSearch, setFieldSearch] = useState(false);
  const [zIndex, setZIndex] = useState<boolean>(false);

  const column: IColumn<IBookingsData>[] = [
    {
      title: ApplicationString.module.booking.table.columns.id,
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: ApplicationString.module.booking.table.columns.ownerName,
      dataIndex: 'ownerName',
      key: 'ownerName',
      sorter: true,
      render: (data) => <span>{data?.ownerDetails?.name}</span>,
    },
    {
      title: ApplicationString.module.booking.table.columns.renterName,
      dataIndex: 'renterName',
      key: 'renterName',
      sorter: true,
      render: (data) => <span>{data?.renterDetails?.name}</span>,
    },
    {
      title: ApplicationString.module.booking.table.columns.vehicleTitle,
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      render: (data) => <span>{data?.vehicleDetail?.title}</span>,
    },
    {
      title: ApplicationString.module.booking.table.columns.startDate,
      dataIndex: 'from',
      key: 'from',
      sorter: true,
      render: (data) => {
        const startDate = CommonDateFormat(data?.startDate);
        return startDate;
      },
    },
    {
      title: ApplicationString.module.booking.table.columns.endDate,
      dataIndex: 'to',
      key: 'to',
      sorter: true,
      render: (data) => {
        const endDate = CommonDateFormat(data?.endDate);
        return endDate;
      },
    },
    {
      title: ApplicationString.module.booking.table.columns.rate,
      dataIndex: 'rate',
      key: 'rate',
      sorter: true,
      render: (data) => {
        const rate = renderNAonEmpty(
          `${ApplicationString.module.booking.bookingViewDetails.bookingDetails.dollarSign}${data.rate}`
        );
        return rate;
      },
    },
    {
      title: ApplicationString.module.booking.table.columns.type,
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      render: (data) => {
        let value;
        if (data.type === 'requested') {
          value =
            ApplicationString.module.booking.table.columns.status.requested;
        } else if (data.type === 'active') {
          value = ApplicationString.module.booking.table.columns.status.active;
        } else if (data.type === 'rejected') {
          value =
            ApplicationString.module.booking.table.columns.status.rejected;
        } else if (data.type === 'cancelled') {
          value =
            ApplicationString.module.booking.table.columns.status.cancelled;
        } else if (data.type === 'completed') {
          value =
            ApplicationString.module.booking.table.columns.status.completed;
        } else {
          value = undefined;
        }
        return value;
      },
    },
    {
      title: ApplicationString.module.booking.table.columns.specialRequest,
      dataIndex: 'specialRequest',
      key: 'specialRequest',
      sorter: false,
      render: (data) => {
        const specialRequest = renderNAonEmpty(data.specialRequest);
        return specialRequest;
      },
    },
    {
      title: ApplicationString.module.booking.table.columns.actionB,
      dataIndex: 'action',
      key: 'action',
      sorter: false,
      fixed: 'right',
      render: (data) => {
        return (
          <TableActions
            onView={() => handleBookingViewClick(data)}
            // onEdit={data.id}
            // editId={data.id}
            onStatusChange={(typeValue: string) =>
              handleBookingStatusClick(data.id, typeValue)
            }
            isEnabled={data.vehicleDetail.isEnabled}
            statusDefaultValue={data.type}
            statusBooking
            setZIndex={setZIndex}
            isPopular={false}
            popularStatus={false}
            editBool={false}
            viewBool
            isOwnerApproved={false}
            approveBool={false}
            enableBool={false}
            deleteBool={false}
            resetPassBool={false}
            text={ApplicationString.module.booking.title}
          />
        );
      },
    },
  ];

  const fieldCol: Filters[] = [
    {
      key: 'column1',
      label: ApplicationString.module.booking.table.filters.user,
      name: 'accountId',
      type: 'searchable-dropdown',
      options: allUsersDataOptions,
    },
    {
      key: 'column3',
      label: ApplicationString.module.booking.table.filters.type,
      name: 'type',
      type: 'common-dropdown',
      options: bookingStatusOptions,
    },
  ];

  const handleFilterClick = () => {
    setFieldSearch(!fieldSearch);
  };

  return (
    <>
      <TableHeading
        hideFilter={false}
        title={ApplicationString.table.heading.bookingManagement}
        fieldSearch={fieldSearch}
        onFilterClick={() => handleFilterClick()}
      />
      {fieldSearch && (
        <DynamicSearchForm
          inputColumn={fieldCol}
          onSearch={handleSearch}
          setPagination={setPagination}
          pagination={pagination}
          savedFilteredValues={searchValues}
          isLoadingUserData={isLoadingUserData}
          isLoadingVehicleData={isLoadingVehicleData}
          handleFilterUserSearch={handleFilterUserSearch}
          handleFilterDropdownSearch={handleFilterDropdownSearch}
        />
      )}
      <Table<IBookingsData>
        dataSource={allBookingData}
        columns={column}
        pageSize={pagination.pageSize}
        onPageSizeChange={handlePageSizeChange}
        totalItems={pagination.totalItems}
        onPageChange={handlePageChange}
        totalRecords={pagination.totalItems}
        setSortParams={setSortParams}
        sortParams={sortParams}
        setPaginationSortAndSearchTrigger={setPaginationSortAndSearchTrigger}
        setPagination={setPagination}
        pagination={pagination}
        setZIndex={setZIndex}
        zIndex={zIndex}
      />

      {isVehicleDetailViewVisible && (
        <BookingViewList
          onDrawerClose={() => {
            setIsVehicleDetailViewVisible(false);
          }}
          bookingData={bookingData}
          title={ApplicationString.module.booking.bookingViewDetails.title}
        />
      )}
    </>
  );
};

export default BookingComponent;
