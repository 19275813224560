export const commonConfig = {
  ApiUrl: process.env.REACT_APP_API_URL,
  UploadUrl: process.env.REACT_APP_UPLOAD_URL,
  CompanyName: 'CompanyName',
  CompanyNameSmall: 'CompanyNameSmall',
  Companyaddress: 'Companyaddress',
  Country: 'Country',
  CompanyContactNumber: 'CompanyContactNumber',
  CompanyEmail: 'CompanyEmail',
  CompanyWebsite: 'CompanyWebsite',
  CopyrightText: `© ${new Date().getFullYear()} Company name. All rights reserved.`,
};

/**
 * An array of page size options.
 */

export const PageSizeArray = [10, 20, 50, 100];

/**
 * An object containing default pagination values.
 */
export const DefaultPaginationValues = {
  PAGE_SIZE: 10,
  PAGE: 1,
  TOTAL_PAGES: 1,
};

/**
 * An variable containing default String values.
 */
export const DefaultStringValue = '';
/**
 * An variable containing default number values.
 */
export const DefaultNumberValue = 0;

/**
 * An variable containing default Boolean values.
 */
export const DefaultBooleanValue = false;
/**
 * An variable containing default Object values.
 */
export const DefaultObjectValue = {};
/**
 * An variable containing default Array values.
 */
export const DefaultArrayValue = [];

/**
 * An variable containing default sort params.
 */
export const DefaultSortParams = {
  ASC: 'asc',
  DESC: 'desc',
};
