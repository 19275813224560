import { IBookingsData } from '../Interfaces/Bookings';
import { IVehiclesData } from '../Interfaces/Vehicles/index';
import {
  DefaultArrayValue,
  DefaultBooleanValue,
  DefaultNumberValue,
  DefaultStringValue,
} from './commonConfig';

export const ROLES = {
  admin: 'ADMIN',
  superAdmin: 'SUPER-ADMIN',
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CommonDateFormat = (
  utc: string
  // type: DateTimeTypeForFormat
): string => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function formatDate(utcDate: string) {
    const date = new Date(utcDate);
    const month = months[date.getUTCMonth()];
    const day = date.getDate();
    const year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'

    const strMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${month} ${day}, ${year} ${hours}:${strMinutes} ${ampm}`;
  }

  const formattedDate = formatDate(utc);
  // const formattedEndDate = formatDate(utcEnd);

  return `${formattedDate}`;
};

export enum TableActionEnum {
  edit = 'edit',
  delete = 'delete',
  view = 'view',
  approve = 'approve',
  enabled = 'enabled',
  resetPassword = 'resetPassword',
  statusChange = 'statusChange',
}

export const Actions = {
  APPROVE: {
    value: TableActionEnum.approve,
    message: 'Are you sure you want to approve #{textApprove}?',
  },
  ENABLED: {
    value: TableActionEnum.enabled,
    message: 'Are you sure you want to enable #{text}?',
  },
  DELETE: {
    value: TableActionEnum.delete,
    message: 'Are you sure you want to delete #{text}?',
  },
  RESETPASSWORD: {
    value: TableActionEnum.resetPassword,
    message: 'Are you sure you want to reset user password?',
  },
  STATUSCHANGE: {
    value: TableActionEnum.statusChange,
    message: 'Are you sure you want to change status for #{text}?',
  },
  // Add more actions as needed
};

export const vehiclesDataInitialValues: IVehiclesData = {
  title: DefaultStringValue,
  description: DefaultStringValue,
  manufacturingYear: DefaultNumberValue,
  maker: DefaultStringValue,
  model: DefaultStringValue,
  isEnabled: DefaultBooleanValue,
  isPopular: DefaultBooleanValue,
  capacity: DefaultNumberValue,
  size: DefaultNumberValue,
  country: DefaultStringValue,
  dropCountry: DefaultStringValue,
  state: DefaultStringValue,
  dropState: DefaultStringValue,
  city: DefaultStringValue,
  dropCity: DefaultStringValue,
  zip: DefaultStringValue,
  dropZip: DefaultStringValue,
  address: DefaultStringValue,
  address2: DefaultStringValue,
  dropAddress: DefaultStringValue,
  dropAddress2: DefaultStringValue,
  latLong: DefaultStringValue,
  dropLatLong: DefaultStringValue,
  bookingSlotType: DefaultStringValue,
  bookingGap: DefaultNumberValue,
  shortNoticePeriod: DefaultNumberValue,
  isMultipleBookingSameDay: DefaultBooleanValue,
  bookingRateHourly: DefaultStringValue,
  bookingRate12Hourly: DefaultStringValue,
  timeFrameFor12Hourly: DefaultArrayValue,
  bookingRate24Hourly: DefaultStringValue,
  maxDaysFor24Hourly: DefaultStringValue,
  rating: DefaultStringValue,
  metaData: {
    ownershipType: DefaultStringValue,
    amenitiesDescription: DefaultStringValue,
    amenities: DefaultArrayValue,
    restricted: DefaultArrayValue,
    engineSize: DefaultStringValue,
    hitchSize: DefaultStringValue,
    isTrailerIncluded: DefaultBooleanValue,
  },
  ownerDetails: {
    name: DefaultStringValue,
    avatarPath: DefaultStringValue,
    joinedYear: DefaultStringValue,
  },
  images: [],
  id: DefaultNumberValue,
  category: DefaultNumberValue,
  subCategory: DefaultNumberValue,
};

export const bookingDetailsDataInitialValue: IBookingsData = {
  id: DefaultNumberValue,
  vehicleId: DefaultNumberValue,
  vehicleDetail: vehiclesDataInitialValues,
  startDate: DefaultStringValue,
  endDate: DefaultStringValue,
  type: DefaultStringValue,
  numberOfPerson: DefaultNumberValue,
  specialRequest: DefaultStringValue,
  rate: DefaultNumberValue,
  tax: DefaultNumberValue,
  deposit: DefaultNumberValue,
  total: DefaultNumberValue,
  ownerDetails: {
    id: DefaultNumberValue,
    name: DefaultStringValue,
    avatarPath: DefaultStringValue,
  },
  renterDetails: {
    id: DefaultNumberValue,
    name: DefaultStringValue,
    avatarPath: DefaultStringValue,
  },
  isEnabled: DefaultBooleanValue,
  confirmationCode: DefaultStringValue,
};

export const userQuery = {
  id: 'id',
};
