/* eslint-disable react/no-array-index-key */
// Todo : Disabling this error as in li we added key with the array index there is no other which will be unique

/* eslint-disable react/require-default-props */
// TODO: Disabling this error because I found a solution by adding defaultProps but it is deprecated
// and I couldn't find another solution yet.
import React from 'react';
import { DOTS, usePagination } from '../../../hooks/usePagination';
import { IPaginationValues } from '../../../Interfaces/interfaces';
import ApplicationString from '../../../Constants/applicationString';

interface PaginationProps {
  totalItems: number; // Change totalCount to totalItems
  pageSize: number;
  siblingCount?: number;
  onPageChange: (pageNumber: number) => void;
  setPagination: (val: IPaginationValues) => void;
  pagination: IPaginationValues;
}

type PaginationRange = (number | string)[];

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  onPageChange,
  pageSize,
  siblingCount = 1,
  setPagination,
  pagination,
}) => {
  const currentPage = pagination.page;
  const handleClick = (page: number) => {
    setPagination({ ...pagination, page });
    onPageChange(page);
  };
  const paginationRange: PaginationRange = usePagination({
    totalItems,
    pageSize,
    siblingCount,
    currentPage,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }
  const lastPage = paginationRange[paginationRange.length - 1];

  const renderPageNumbers = () => {
    return paginationRange.map((pageNumber, i) => {
      if (pageNumber === DOTS) {
        return (
          <li key={i + 1} className="pagination-item dots">
            &#8230;
          </li>
        );
      }
      return (
        <li>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleClick(Number(pageNumber));
              }
            }}
            onClick={() => handleClick(Number(pageNumber))}
            className={`p-1 text-gray-700 rounded shadow-4 w-8 align-middle text-center  cursor-pointer ${currentPage === pageNumber ? 'bg-slate-600 text-white' : 'bg-white'}`}
            key={i + 1}
          >
            <button className="pagination-link" type="button">
              {pageNumber}
            </button>
          </div>
        </li>
      );
    });
  };

  return (
    <nav className="pagination flex justify-end items-center mt-4 px-4">
      <button
        type="button"
        onClick={() => handleClick(currentPage - 1)}
        disabled={currentPage === 1}
        className={`p-1 pl-2 pr-2 me-2 text-gray-700 shadow-4 align-middle text-center rounded  ${
          currentPage !== 1
            ? 'cursor-pointer bg-white'
            : 'cursor-not-allowed bg-slate-600 text-white'
        }`}
      >
        {ApplicationString.table.pagination.previousButton}
      </button>
      <ul className="pagination-list flex gap-2 rounded">
        {renderPageNumbers()}
      </ul>
      <button
        type="button"
        onClick={() => handleClick(currentPage + 1)}
        disabled={currentPage === lastPage}
        className={`p-1 pl-2 pr-2 ml-2 text-gray-700 shadow-4 align-middle text-center rounded  ${
          currentPage !== lastPage
            ? 'cursor-pointer bg-white'
            : 'cursor-not-allowed bg-slate-600 text-white'
        }`}
      >
        {ApplicationString.table.pagination.nextButton}
      </button>
    </nav>
  );
};
// Pagination.defaultProps = {
//   siblingCount: 1,
// };
export default Pagination;
