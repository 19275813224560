const InternalRoute = {
  Home: '/',
  Login: '/login',
  Logout: '/logout',
  Dashboard: '/dashboard',
  Unauthorized: '/unauthorized',
  UserManagement: '/user-management',
  VehicleManagement: '/vehicle-management',
  ContactManagement: '/contact-us',
  VehicleUpdate: '/vehicle-management/edit/:vehicleId',
  BookingManagement: '/booking-management',
  Transactions: '/transactions',
  UpdateProfile: '/update-Profile',
};
export default InternalRoute;
