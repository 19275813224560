/* eslint-disable react-hooks/exhaustive-deps */
// TODO: When I add this dependency specified in eslint warning, my select not showing selected value.
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import Select from 'react-select';
import { AlertCircle, Edit } from 'react-feather';
import cssUtils from '../../Utils/cssUtils';
import { localStorageEnums, updateProfileEnums } from '../../Utils/enums';
import regexConfig from '../../Constants/RegexConfig';
import { IUpdateProfileValues } from '../../Interfaces/UpdateProfile';
import { commonConfig } from '../../Constants/commonConfig';
import {
  checkIfArrayIsTruthyOrNot,
  createSelectOptions,
  getLocalStorageAccountInfo,
} from '../../Utils/utils';
import LoadingButton from '../Common/LoadingButton';
import { ICountry, IState, IUserSettingsData } from '../../Interfaces/Settings';
import { ISelectOptions } from '../../Interfaces/Common/api.interface';
import ApplicationString from '../../Constants/applicationString';

interface UpdateProfileComponentProps {
  data: IUpdateProfileValues;
  updateUserDetails: (values: IUpdateProfileValues) => Promise<void>;
  getUserDetails: () => Promise<void>;
  updateProfilePhoto: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  avatarPath: string;
  isUploadingProfilePhoto: boolean;
  setIsUploadingProfilePhoto: (value: boolean) => void;
  isFormTouched: boolean;
  setIsFormTouched: (value: boolean) => void;
}

const updateProfileFormSchema = Yup.object().shape({
  name: Yup.string()
    .required(
      ApplicationString.module.updateProfile.validationMsg.fullName.required
    )
    .min(
      updateProfileEnums.zipCode.min,
      ApplicationString.module.updateProfile.validationMsg.fullName.min
    )
    .max(
      updateProfileEnums.fullName.max,
      ApplicationString.module.updateProfile.validationMsg.fullName.max
    ),
  address: Yup.string()
    .min(
      updateProfileEnums.address.min,
      ApplicationString.module.updateProfile.validationMsg.address.min
    )
    .max(
      updateProfileEnums.address.max,
      ApplicationString.module.updateProfile.validationMsg.address.max
    ),
  address2: Yup.string()
    .min(
      updateProfileEnums.address.min,
      ApplicationString.module.updateProfile.validationMsg.address2.min
    )
    .max(
      updateProfileEnums.address.max,
      ApplicationString.module.updateProfile.validationMsg.address2.max
    ),
  country: Yup.string().max(
    updateProfileEnums.country.max,
    ApplicationString.module.updateProfile.validationMsg.country.max
  ),
  city: Yup.string().max(
    updateProfileEnums.city.max,
    ApplicationString.module.updateProfile.validationMsg.city.max
  ),
  state: Yup.string().max(
    updateProfileEnums.state.max,
    ApplicationString.module.updateProfile.validationMsg.state.max
  ),
  zip: Yup.string()
    .matches(
      regexConfig.alphaNumeric,
      ApplicationString.module.updateProfile.validationMsg.zip
        .noSpecialCharacters
    )
    .min(
      updateProfileEnums.zipCode.min,
      ApplicationString.module.updateProfile.validationMsg.zip.min
    )
    .max(
      updateProfileEnums.zipCode.max,
      ApplicationString.module.updateProfile.validationMsg.zip.max
    ),
});

const UpdateProfileComponent = ({
  data,
  updateUserDetails,
  getUserDetails,
  updateProfilePhoto,
  isUploadingProfilePhoto,
  setIsUploadingProfilePhoto,
  avatarPath,
  setIsFormTouched,
  isFormTouched,
}: UpdateProfileComponentProps): JSX.Element => {
  // const [profilePicture, setProfilePicture] = useState<string | null>(
  //   data.avatarPath
  // );
  const [isLoading, setIsLoading] = useState(true);
  const [countryDropDownToggle, setCountryDropDownToggle] = useState(false);
  const dropDownRef = useRef<HTMLDivElement | null>(null);
  const userSettingsData = getLocalStorageAccountInfo<IUserSettingsData>(
    localStorageEnums.settings
  )?.setting;
  const countriesData: ICountry[] = checkIfArrayIsTruthyOrNot<ICountry[]>(
    userSettingsData?.country
  );
  const [countrySelectedOption, setCountrySelectedOption] =
    useState<ISelectOptions>();
  const [stateSelectedOption, setStateSelectedOption] =
    useState<ISelectOptions>();
  const countriesDataOptions = createSelectOptions<
    ICountry,
    ICountry[],
    'code',
    'name'
  >(countriesData, 'code', 'name');

  const statesData = countriesData.flatMap((con) =>
    checkIfArrayIsTruthyOrNot<IState[]>(con?.state)
  );
  const stateDataOptions = createSelectOptions<
    IState,
    IState[],
    'code',
    'name'
  >(statesData, 'code', 'name');

  useEffect(() => {
    setCountrySelectedOption(
      countriesDataOptions.find((con) => con.value === data.country)
    );
    setStateSelectedOption(
      stateDataOptions.find((state) => state.value === data.state)
    );
    setIsLoading(false);
    // fetchData();
  }, [data]);

  return (
    <>
      {/* {isLoading && <LoadingButton />} */}
      {!isLoading && (
        <>
          {isFormTouched && (
            <div className="w-full bg-slate-400 sticky top-28 transform -translate-y-[2.5rem] left-0 z-999 py-2 px-4 md:px-6 2xl:px-10 text-white flex flex-col sm:flex-row gap-3 justify-between items-center">
              <div className="flex items-center gap-3">
                <AlertCircle className=" min-w-7 min-h-7 " />
                <span>
                  {
                    ApplicationString.module.updateProfile.unsavedChangeWarning
                      .description
                  }
                </span>
              </div>
            </div>
          )}
          <div className="pb-12">
            <div className="flex">
              <div className="flex-grow flex flex-col h-full justify-center items-stretch">
                <span className="text-base font-semibold leading-7 text-gray-900 ">
                  {ApplicationString.module.updateProfile.titles.title}
                </span>
                <span className="mt-1 text-sm leading-6 text-gray-600">
                  {ApplicationString.module.updateProfile.titles.subTitle}
                </span>
              </div>
              {/* <ConfirmationPopup  /> */}

              <div className=" flex flex-col items-center h-full">
                <span className="flex items-center justify-center bg-slate-400 size-20 rounded-full text-white font-bold text-xl ">
                  {avatarPath && (
                    <img
                      src={`${avatarPath.includes('http') ? avatarPath : `${commonConfig.UploadUrl}/${avatarPath}`}`}
                      alt={data?.name}
                      className="size-20 rounded-full"
                      onLoad={() => {
                        setIsUploadingProfilePhoto(false);
                      }}
                    />
                  )}
                </span>
                {isUploadingProfilePhoto ? (
                  <LoadingButton />
                ) : (
                  <label
                    className="w-full flex mt-2 justify-center items-center text-sm bg-slate-500 hover:bg-slate-400 text-white font-bold py-1 px-4 rounded cursor-pointer"
                    htmlFor="avatar"
                  >
                    <Edit className="size-4 mr-2" />
                    {ApplicationString.module.updateProfile.button.update_photo}
                    <input
                      type="file"
                      id="avatar"
                      className="hidden"
                      onChange={updateProfilePhoto}
                    />
                  </label>
                )}
              </div>
            </div>

            {/* <div className="w-full relative rounded-lg h-max border pt-24 py-8 px-4 text-black">
        <div className="absolute border left-1/2 top-0 size-40 rounded-full transform -translate-y-1/2 -translate-x-1/2 flex items-center justify-center bg-slate-100">
           TODO:  Add image here 
        </div>
        <div className={`${cssUtils.button} w-min absolute top-5 right-5 py-2`}>
          <button className="flex gap-2">
            Edit <Edit />
          </button>
        </div>
        <h1 className="w-full flex justify-center font-semibold text-3xl border-b border-slate-400 pb-6">
          {data.name}
        </h1>
        <div>
          <h1 className="w-full text-3xl pt-6 font-semibold">
            Profile Information
          </h1>
          <div className="pt-6 flex flex-col gap-4 text-xl font-medium">
            {Object.entries(data).map(([key, value]) => (
              <UpdateProfileLabelAndData key={key} label={key} value={value} />
            ))}
          </div>
        </div>
      </div> */}

            <Formik
              initialValues={data}
              validateOnChange
              enableReinitialize
              validationSchema={updateProfileFormSchema}
              onSubmit={(values, { resetForm }) => {
                updateUserDetails(values);
                resetForm({ values: data });
                setIsFormTouched(false);
              }}
            >
              {({ values, isSubmitting, setFieldValue }) => {
                let isFormTouchedVar = false;
                // waiting for data from api to compare with form values
                if (values.name && !isSubmitting) {
                  Object.keys(values).forEach((key) => {
                    const keyOfIUpdateProfileValues =
                      key as keyof IUpdateProfileValues;
                    const changedField =
                      values[keyOfIUpdateProfileValues] !==
                      data[keyOfIUpdateProfileValues];

                    if (key === 'avatarPath' && data[key] !== avatarPath) {
                      isFormTouchedVar = true;
                    }
                    if (changedField && key !== 'avatarPath') {
                      isFormTouchedVar = true;
                      if (
                        values[keyOfIUpdateProfileValues] ===
                        data[keyOfIUpdateProfileValues]
                      ) {
                        isFormTouchedVar = false;
                      }
                    }
                    setIsFormTouched(isFormTouchedVar);
                  });
                }
                return (
                  <Form>
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="col-span-full">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {ApplicationString.module.updateProfile.label.name}
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            placeholder={
                              ApplicationString.module.updateProfile.placeHolder
                                .name
                            }
                            autoComplete="name"
                            className={`${cssUtils.inputClasses} bg-white`}
                          />
                        </div>
                        <span className="mt-1 text-red-500">
                          <ErrorMessage name="name" />
                        </span>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="address"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {
                            ApplicationString.module.updateProfile.label
                              .address1
                          }
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="address"
                            id="address"
                            placeHolder={
                              ApplicationString.module.updateProfile.placeHolder
                                .address1
                            }
                            autoComplete="address"
                            className={`${cssUtils.inputClasses} bg-white`}
                          />
                        </div>
                        <span className="mt-1 text-red-500">
                          <ErrorMessage name="address" />
                        </span>
                      </div>
                      <div className="col-span-full">
                        <label
                          htmlFor="address2"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {
                            ApplicationString.module.updateProfile.placeHolder
                              .address2
                          }
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="address2"
                            id="address2"
                            placeHolder={
                              ApplicationString.module.updateProfile.placeHolder
                                .address2
                            }
                            autoComplete="address2"
                            className={`${cssUtils.inputClasses} bg-white`}
                          />
                        </div>
                        <span className="mt-1 text-red-500">
                          <ErrorMessage name="address2" />
                        </span>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {ApplicationString.module.updateProfile.label.city}
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="city"
                            id="city"
                            placeHolder={
                              ApplicationString.module.updateProfile.placeHolder
                                .city
                            }
                            autoComplete="city"
                            className={`${cssUtils.inputClasses} bg-white`}
                          />
                        </div>
                        <span className="mt-1 text-red-500">
                          <ErrorMessage name="city" />
                        </span>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="state"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {ApplicationString.module.updateProfile.label.state}
                        </label>
                        <div className="mt-2">
                          <Select
                            options={stateDataOptions}
                            name="state"
                            value={stateSelectedOption}
                            onChange={(selectedOption) => {
                              setFieldValue('state', selectedOption?.value);
                              if (selectedOption) {
                                setStateSelectedOption(selectedOption);
                              }
                            }}
                            className="mt-1 p-2  rounded-md w-full bg-white shadow-sm focus:outline-none focus:border-blue-500 appearance-none"
                          />
                        </div>
                        <span className="mt-1 text-red-500">
                          <ErrorMessage name="state" />
                        </span>
                      </div>
                      <div className="sm:col-span-3 sm:col-start-1">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {ApplicationString.module.updateProfile.label.country}
                        </label>
                        <div
                          role="button"
                          tabIndex={0}
                          aria-label="select country"
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              setCountryDropDownToggle(!countryDropDownToggle);
                            }
                          }}
                          className="mt-2 relative"
                          onClick={() =>
                            setCountryDropDownToggle(!countryDropDownToggle)
                          }
                          ref={dropDownRef}
                        >
                          <Select
                            options={countriesDataOptions}
                            value={countrySelectedOption}
                            onChange={(selectedOption) => {
                              setFieldValue('country', selectedOption?.value);
                              if (selectedOption) {
                                setCountrySelectedOption(selectedOption);
                              }
                            }}
                            name="country"
                            className="mt-1 p-2  rounded-md w-full bg-white shadow-sm focus:outline-none focus:border-blue-500 appearance-none"
                          />
                        </div>
                        <span className="mt-1 text-red-500">
                          <ErrorMessage name="country" />
                        </span>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="zip"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {ApplicationString.module.updateProfile.label.zip}
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="zip"
                            id="zip"
                            placeHolder={
                              ApplicationString.module.updateProfile.placeHolder
                                .zip
                            }
                            autoComplete="zip"
                            className={`${cssUtils.inputClasses} bg-white`}
                          />
                        </div>
                        <span className="mt-1 text-red-500">
                          <ErrorMessage name="zip" />
                        </span>
                      </div>
                    </div>
                    {/* <div className="col-span-full mt-4">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {ApplicationString.module.updateProfile.label.upload}
                </label>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-transparent font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span>
                          {
                            ApplicationString.module.updateProfile.placeHolder
                              .upload.uploadFileText
                          }
                        </span>
                        <input
                          id="file-upload"
                          name="avatarPath"
                          type="file"
                          className="sr-only"
                        />
                      </label>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      {
                        ApplicationString.module.updateProfile.placeHolder.upload
                          .uploadTypeSizeText
                      }
                    </p>
                  </div>
                </div>
              </div> */}
                    <div className="mt-10 flex items-center justify-end gap-x-4">
                      <button
                        type="submit"
                        disabled={isUploadingProfilePhoto}
                        className={`${cssUtils.button.replace('cursor-pointer', isUploadingProfilePhoto ? 'cursor-not-allowed' : 'cursor-pointer')} py-2`}
                      >
                        {ApplicationString.module.updateProfile.button.submit}
                      </button>
                      <button
                        type="button"
                        disabled={isUploadingProfilePhoto}
                        onClick={getUserDetails}
                        className={`${isUploadingProfilePhoto ? 'cursor-not-allowed' : 'cursor-pointer'} rounded-lg  p-4 text-black py-2 transition hover:bg-slate-400 hover:text-white`}
                      >
                        {ApplicationString.module.updateProfile.button.reset}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </>
  );
};

export default UpdateProfileComponent;
