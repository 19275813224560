/* eslint-disable import/prefer-default-export */
export const URLS = {
  AUTH_API: '/auth/api',
  ACCOUNT_REFRESHTOKEN: '/account/refresh-token',
  GET_USER_SETTINGS: 'settings',
  USER_MANAGEMENT: 'users',
  CONTACT_MANAGEMENT: 'contactus',
  USER_MANAGEMENT_BY_ID: 'users/#{userId}',
  USER_MANAGEMENT_APPROVE_OWNER: 'users/#{userId}/approve',
  USER_MANAGEMENT_ENABLE: 'users/#{userId}/enabled',
  USER_MANAGEMENT_RESET_PASSWORD: 'users/#{userId}/resetPassword',
  USER_MANAGEMENT_DELETE: 'users/#{userId}',
  ACCOUNT_LOGIN: 'accounts/login',
  REFRESH_TOKEN: '/accounts/refreshtoken',
  VEHICLES_MANAGEMENT: 'vehicles',
  VEHICLES_MANAGEMENT_DELETE: 'vehicles/#{vehicleId}',
  VEHICLES_MANAGEMENT_STATUS: 'vehicles/#{vehicleId}/status',
  VEHICLES_MANAGEMENT_IS_POPULAR: 'vehicles/#{vehicleId}/popular',
  VEHICLES_MANAGEMENT_BY_ID: 'vehicles/#{vehicleId}',
  UPDATE_PROFILE: '/accounts/{accountId}',
  UPLOAD_PROFILE_PHOTO: '/files/upload',
  BOOKING_MANAGEMENT: 'bookings',
  BOOKING_MANAGEMENT_BY_ID: 'bookings/#{bookingId}',
  BOOKING_MANAGEMENT_STATUS: 'bookings/#{bookingId}/status',
};
