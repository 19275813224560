/* ---- Common Tailwind Class Utils ----*/

const cssUtils = {
  inputClasses:
    'w-full rounded-lg border flex items-center border-stroke bg-transparent py-4 pl-6 pr-10 text-black focus:outline-stroke focus:border-transparent focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary',
  labelClasses: 'mb-2.5 block font-medium text-black dark:text-white',
  button:
    'cursor-pointer rounded-lg border border-slate-500 bg-slate-500 p-4 text-white transition hover:bg-opacity-90 flex items-center justify-center',
};
export default cssUtils;
