/* eslint-disable react-hooks/exhaustive-deps */
// TODO: When I add this dependency specified in eslint warning, my API is calling in infinite time.
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AlertCircle, AlertTriangle, LogIn, Zap } from 'react-feather';
import './home.css';
import { useToast } from '../../Services/ToastService';
import Loader from '../../Components/Common/spinner/loader';
import ApplicationString from '../../Constants/applicationString';
import {
  GLOBAL_HIDE_LOADER,
  GLOBAL_SHOW_LOADER,
} from '../../Constants/actionType';

const Home: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();

  const showGlobalLoader = () => {
    dispatch({ type: GLOBAL_SHOW_LOADER });
  };
  const showLocalLoader = () => {
    setIsLoading(true);
    // Simulating an API call with a delay
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const fetchData = async () => {
    try {
      dispatch({ type: GLOBAL_SHOW_LOADER });
      // const { data }: any = await ApiRequest.get(URLS.FETCH_DATA);
      dispatch({ type: GLOBAL_HIDE_LOADER });
    } catch (error) {
      // NotificationService.error('Error', 'Something wents Wrong');
      dispatch({ type: GLOBAL_HIDE_LOADER });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFail = () => {
    toast.error('Error', 'This Error miserabl');
  };

  const handleSuccess = () => {
    toast.success('Succeess', 'You are logged in successfully');
  };

  const handleInfo = () => {
    toast.info('Info', 'You are logged in Info');
  };
  const handleWarning = () => {
    toast.warning('Warning', 'You are logged in Warning');
  };
  return (
    <>
      <div className="px-6">
        <div className="p-4 mt-5 bg-white shadow-[0_1px_4px_0_rgba(0,0,0,0.04)] border-b border-solid border-[#067ea7] rounded-sm">
          {ApplicationString.module.dashboard.title}
        </div>
      </div>
      <div className="px-6 ">
        <div className="mt-4 bg-white shadow-[0_2px_4px_0_rgba(35, 46, 60, 0.04))] border-b border-solid border-[rgba(101, 109, 119, 0.16)] rounded-sm">
          <div className="px-5 pt-5 flex flex-row justify-between">
            {ApplicationString.module.dashboard.subTitle}
          </div>
          <div
            className="ag-theme-alpine"
            style={{ width: 'auto', height: '50vh', marginTop: '20px' }}
          >
            <h1>
              {ApplicationString.module.dashboard.section.notification.title}
            </h1>
            <div className="flex  gap-4 items-center justify-center">
              <button
                type="button"
                className="bg-green-800 text-white px-4 py-2"
                onClick={handleSuccess}
              >
                <LogIn />
                {
                  ApplicationString.module.dashboard.section.notification
                    .buttonLabel.showSuccess
                }
              </button>
              <button
                type="button"
                className="bg-red-800 text-white px-4 py-2"
                onClick={handleFail}
              >
                <AlertCircle />
                {
                  ApplicationString.module.dashboard.section.notification
                    .buttonLabel.showError
                }
              </button>
              <button
                type="button"
                className="bg-blue-800 text-white px-4 py-2"
                onClick={handleInfo}
              >
                <Zap />
                {
                  ApplicationString.module.dashboard.section.notification
                    .buttonLabel.showInfo
                }
              </button>
              <button
                type="button"
                className="bg-yellow-800 text-white px-4 py-2"
                onClick={handleWarning}
              >
                <AlertTriangle />
                {
                  ApplicationString.module.dashboard.section.notification
                    .buttonLabel.showWarning
                }
              </button>
            </div>
            <h1>{ApplicationString.module.dashboard.section.loader.title}</h1>
            <div className="flex  gap-4 items-center justify-center">
              <button
                type="button"
                className="bg-yellow-800 text-white px-4 py-2"
                onClick={showGlobalLoader}
              >
                {
                  ApplicationString.module.dashboard.section.loader.buttonLabel
                    .showGlobalLoader
                }
              </button>
              <button
                type="button"
                className="bg-blue-800 text-white px-4 py-2"
                onClick={showLocalLoader}
              >
                {
                  ApplicationString.module.dashboard.section.loader.buttonLabel
                    .showLocalLoader
                }
              </button>
              {isLoading && <Loader />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
