import React from 'react';
import ApplicationString from '../../../Constants/applicationString';

interface ConfirmationPopupProps {
  onConfirm: () => void;
  onCancel: () => void;
  children: React.ReactNode;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  onConfirm,
  onCancel,
  children,
}) => {
  return (
    <div className="fixed z-10 inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-30 w-full">
      <div className="bg-white p-6 rounded-lg shadow-xl w-1/4">
        <p className="text-lg font-medium mb-4 text-wrap">{children}</p>
        <div className="flex justify-end gap-4">
          <button
            type="button"
            onClick={onCancel}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            {ApplicationString.table.actions.confirm.cancelButton}
          </button>
          <button
            type="submit"
            onClick={onConfirm}
            className="text-red-500 hover:text-red-700 focus:outline-none"
          >
            {ApplicationString.table.actions.confirm.confirmButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
