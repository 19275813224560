import React from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from '../../../Interfaces/interfaces';

const Spinner: React.FC = () => {
  const { isLoading } = useSelector((state: RootState) => state.spinner) as {
    isLoading: boolean;
  };

  return isLoading ? (
    <div className="fixed top-0 left-0 w-full min-h-screen flex items-center justify-center bg-slate-900/20 bg-opacity-50 z-50">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-500" />
    </div>
  ) : null;
};

export default Spinner;
