/* eslint-disable @typescript-eslint/default-param-last */
// -- TODO: disabling this error as redux is not accepting the state as 2nd parameter

import { HIDE_LOADER, SHOW_LOADER } from '../../Constants/actionType';
import { ILoaderStateInterface } from '../../Interfaces/interfaces';

const initialState: ILoaderStateInterface = {
  isLoading: false,
};

interface IReduxAction {
  type: string;
}

const LoaderReducer = (
  state = initialState,
  action: IReduxAction
): ILoaderStateInterface => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, isLoading: true };
    case HIDE_LOADER:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default LoaderReducer;
