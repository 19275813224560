import React from 'react';
import { PageSizeArray } from '../../../Constants/commonConfig';
import ApplicationString from '../../../Constants/applicationString';

interface Props {
  pageSize: number;
  onPageChange: (pageSize: number) => void;
}

const PageSizeSelector: React.FC<Props> = ({ pageSize, onPageChange }) => {
  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = parseInt(e.target.value, 10);
    onPageChange(newSize);
  };

  return (
    <div className="flex justify-end items-center mt-4 px-4 p-1 pl-2 pr-2 me-2 text-gray-700 shadow-4 align-middle text-center min-w-fit w-auto h-auto bg-white rounded">
      <span>{ApplicationString.table.pagination.pageSize}: </span>
      <select
        className="ml-2  text-gray-700 border-0 border-slate-200 bg-slate-600 text-white rounded"
        onChange={handlePageSizeChange}
        value={pageSize}
      >
        {PageSizeArray.map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PageSizeSelector;
