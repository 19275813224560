/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import dayjs from 'dayjs';
import AccordionItem from '../../VehicleListing/VehicleAccordian';
import DrawerWrapper from '../../Common/DrawerWrapper';
import ApplicationString from '../../../Constants/applicationString';
import { IBookingsData } from '../../../Interfaces/Bookings';
import {
  addHttpsToUrl,
  getLocalStorageAccountInfo,
  greaterThanZero,
  renderNAonEmpty,
} from '../../../Utils/utils';
import { IUserSettingsData } from '../../../Interfaces/Settings';
import { CommonDateFormat } from '../../../Constants/commonConst';
import { localStorageEnums } from '../../../Utils/enums';

const BookingViewList: React.FC<{
  onDrawerClose: () => void;
  bookingData: IBookingsData;
  title: string;
}> = ({ onDrawerClose, bookingData, title }) => {
  const userSettingsData = getLocalStorageAccountInfo<IUserSettingsData>(
    localStorageEnums.settings
  );
  const categoryRecord = userSettingsData?.setting?.vehicleCategory.find(
    (i: { id: number }) => i.id === Number(bookingData?.vehicleDetail?.category)
  );
  const subCategoryRecord = categoryRecord?.subCategory.find(
    (i: { id: number }) =>
      i.id === Number(bookingData.vehicleDetail.subCategory)
  )?.name;

  const bookingStatusType = () => {
    let bookingStatus;

    switch (bookingData.type) {
      case 'requested':
        bookingStatus =
          ApplicationString.module.booking.table.columns.status.requested;
        break;
      case 'active':
        bookingStatus =
          ApplicationString.module.booking.table.columns.status.active;
        break;
      case 'rejected':
        bookingStatus =
          ApplicationString.module.booking.table.columns.status.rejected;
        break;
      case 'cancelled':
        bookingStatus =
          ApplicationString.module.booking.table.columns.status.cancelled;
        break;
      case 'completed':
        bookingStatus =
          ApplicationString.module.booking.table.columns.status.completed;
        break;
      default:
        bookingStatus = undefined;
        break;
    }
    return bookingStatus;
  };

  const startTime = bookingData?.startDate
    ? CommonDateFormat(bookingData.startDate)
    : ApplicationString.module.booking.bookingViewDetails.bookingDetails
        .notApplicable;

  const endTime = bookingData?.endDate
    ? CommonDateFormat(bookingData.endDate)
    : ApplicationString.module.booking.bookingViewDetails.bookingDetails
        .notApplicable;

  const description = renderNAonEmpty(bookingData.vehicleDetail.description);

  const specialRequest = renderNAonEmpty(bookingData.specialRequest);

  const country = renderNAonEmpty(bookingData.vehicleDetail.country);

  const dropCountry = renderNAonEmpty(bookingData.vehicleDetail.dropCountry);

  const state = renderNAonEmpty(bookingData.vehicleDetail.state);

  const dropState = renderNAonEmpty(bookingData.vehicleDetail.dropState);

  const city = renderNAonEmpty(bookingData.vehicleDetail.city);

  const dropCity = renderNAonEmpty(bookingData.vehicleDetail.dropCity);

  const zip = renderNAonEmpty(bookingData.vehicleDetail.zip);

  const dropZip = renderNAonEmpty(bookingData.vehicleDetail.dropZip);

  const address = renderNAonEmpty(bookingData.vehicleDetail.address);

  const dropAddress = renderNAonEmpty(bookingData.vehicleDetail.dropAddress);

  const dropAddress2 = renderNAonEmpty(bookingData.vehicleDetail.dropAddress2);

  const address2 = renderNAonEmpty(bookingData.vehicleDetail.address2);

  const latLong = renderNAonEmpty(bookingData.vehicleDetail.latLong);

  const dropLatLong = renderNAonEmpty(bookingData.vehicleDetail.dropLatLong);

  const capacity = renderNAonEmpty(bookingData.vehicleDetail.capacity);

  const size = bookingData?.vehicleDetail?.size
    ? `${bookingData.vehicleDetail.size}${ApplicationString.module.booking.bookingViewDetails.bookingDetails.ft}`
    : ApplicationString.module.booking.bookingViewDetails.bookingDetails
        .notApplicable;

  const rate = greaterThanZero(bookingData.rate);

  const tax = greaterThanZero(bookingData.tax);

  const total = greaterThanZero(bookingData.total);

  const deposit = greaterThanZero(bookingData.deposit);

  return (
    <DrawerWrapper visible onClose={onDrawerClose} title={title}>
      <div className="w-full flex flex-col gap-5 mt-4">
        <div className="border rounded-lg overflow-hidden">
          <AccordionItem
            title={
              ApplicationString.module.booking.bookingViewDetails
                .bookingDetailsTitle
            }
          >
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.startDate
                }
              </h3>
              <span className="text-md text-gray-500">{startTime}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.endDate
                }
              </h3>
              <span className="text-md text-gray-500">{endTime}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.numberOfPerson
                }
              </h3>
              <span className="text-md text-gray-500">
                {bookingData?.numberOfPerson}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.type
                }
              </h3>
              <span className="text-md text-gray-500">
                {bookingStatusType()}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.rate
                }
              </h3>
              <span className="text-md text-gray-500">{rate}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.deposit
                }
              </h3>
              <span className="text-md text-gray-500">{deposit}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.tax
                }
              </h3>
              <span className="text-md text-gray-500">{tax}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.total
                }
              </h3>
              <span className="text-md text-gray-500">{total}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.confirmationCode
                }
              </h3>
              <span className="text-md text-gray-500">
                {bookingData?.confirmationCode}
              </span>
            </div>
            <div className="flex gap-2 flex-wrap">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.specialRequest
                }
              </h3>
              <span className="text-md text-gray-500">{specialRequest}</span>
            </div>
          </AccordionItem>
        </div>
        <div className="border rounded-lg overflow-hidden">
          <AccordionItem
            title={
              ApplicationString.module.vehicle.vehicleViewDetails
                .vehicleDetailsTitle
            }
          >
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.id
                }
              </h3>
              <span className="text-md text-gray-500">
                {bookingData?.vehicleDetail?.id}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.category
                }
              </h3>
              <span className="text-md text-gray-500">
                {categoryRecord?.name}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.subCategory
                }
              </h3>
              <span className="text-md text-gray-500">{subCategoryRecord}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.title
                }
              </h3>
              <span className="text-md text-gray-500">
                {bookingData?.vehicleDetail?.title}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.isEnabled
                }
              </h3>
              <span className="text-md text-gray-500">
                {bookingData?.vehicleDetail?.isEnabled
                  ? ApplicationString.module.booking.table.filters.options.yes
                  : ApplicationString.module.booking.table.filters.options.no}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.description
                }
              </h3>
              <span className="text-md text-gray-500">{description}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.manufacturingYear
                }
              </h3>
              <span className="text-md text-gray-500">
                {bookingData?.vehicleDetail?.manufacturingYear}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.maker
                }
              </h3>
              <span className="text-md text-gray-500">
                {bookingData?.vehicleDetail?.maker}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.model
                }
              </h3>
              <span className="text-md text-gray-500">
                {bookingData?.vehicleDetail?.model}
              </span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.capacity
                }
              </h3>
              <span className="text-md text-gray-500">{capacity}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.size
                }
              </h3>
              <span className="text-md text-gray-500">{size}</span>
            </div>
          </AccordionItem>
        </div>
        <div className="border rounded-lg overflow-hidden">
          <AccordionItem
            title={
              ApplicationString.module.vehicle.vehicleViewDetails
                .vehicleLocationDetailsTitle
            }
          >
            <div className="mt-1 mb-2 text-center font-bold border-y-2">
              <h2>
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.pickUp
                }
              </h2>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.country
                }
              </h3>
              <span className="text-md text-gray-500">{country}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.state
                }
              </h3>
              <span className="text-md text-gray-500">{state}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.city
                }
              </h3>
              <span className="text-md text-gray-500">{city}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.zip
                }
              </h3>
              <span className="text-md text-gray-500">{zip}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.address
                }
              </h3>
              <span className="text-md text-gray-500">{address}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.address2
                }
              </h3>
              <span className="text-md text-gray-500">{address2}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.latLong
                }
              </h3>
              <span className="text-md text-gray-500">{latLong}</span>
            </div>
            <div className="mt-4 mb-2 text-center font-bold border-y-2">
              <h2>
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.dropOff
                }
              </h2>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropCountry
                }
              </h3>
              <span className="text-md text-gray-500">{dropCountry}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropState
                }
              </h3>
              <span className="text-md text-gray-500">{dropState}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropCity
                }
              </h3>
              <span className="text-md text-gray-500">{dropCity}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropZip
                }
              </h3>
              <span className="text-md text-gray-500">{dropZip}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropAddress
                }
              </h3>
              <span className="text-md text-gray-500">{dropAddress}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropAddress2
                }
              </h3>
              <span className="text-md text-gray-500">{dropAddress2}</span>
            </div>
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.vehicle.vehicleViewDetails
                    .viewContent.dropLatLong
                }
              </h3>
              <span className="text-md text-gray-500">{dropLatLong}</span>
            </div>
          </AccordionItem>
        </div>
        <div className="border rounded-lg overflow-hidden">
          <AccordionItem
            title={
              ApplicationString.module.booking.bookingViewDetails
                .bookingOwnerDetailsTitle
            }
          >
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.ownerDetails.name
                }
              </h3>
              <span className="text-md text-gray-500">
                {bookingData?.ownerDetails?.name}
              </span>
            </div>
            <div className="flex gap-2 flex-col">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.ownerDetails.avatarPath
                }
              </h3>
              <span className="text-md text-gray-500">
                <div className="group relative">
                  <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 sm:h-64">
                    <img
                      src={addHttpsToUrl(bookingData?.ownerDetails?.avatarPath)}
                      className="h-full w-full object-cover object-center"
                      alt=""
                    />
                  </div>
                </div>
              </span>
            </div>
          </AccordionItem>
        </div>
        <div className="border rounded-lg overflow-hidden">
          <AccordionItem
            title={
              ApplicationString.module.booking.bookingViewDetails
                .bookingRenterDetailsTitle
            }
          >
            <div className="flex gap-2">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.renterDetails.name
                }
              </h3>
              <span className="text-md text-gray-500">
                {bookingData?.renterDetails?.name}
              </span>
            </div>
            <div className="flex gap-2 flex-col">
              <h3 className="text-base font-semibold text-gray-900">
                {
                  ApplicationString.module.booking.bookingViewDetails
                    .bookingDetails.renterDetails.avatarPath
                }
              </h3>
              <span className="text-md text-gray-500">
                <div className="group relative">
                  <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 sm:h-64">
                    <img
                      src={addHttpsToUrl(
                        bookingData?.renterDetails?.avatarPath
                      )}
                      className="h-full w-full object-cover object-center"
                      alt=""
                    />
                  </div>
                </div>
              </span>
            </div>
          </AccordionItem>
        </div>
      </div>
    </DrawerWrapper>
  );
};

export default BookingViewList;
