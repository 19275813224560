/* eslint-disable react/require-default-props */
// TODO: Disabling this error because I found a solution by adding defaultProps but it is deprecated
// and I couldn't find another solution yet.
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  DeleteSvgIcon,
  EditSvgIcon,
  PopularStatusChangeSvg,
  ResetPasswordSvg,
  StatusChangeSvg,
  UserApproveSvg,
  UserEnableSvg,
  ViewSvgIcon,
} from '../../../Assets/Svgs';
import ConfirmationPopup from '../ConfirmationModal';
import InternalRoute from '../../../Utils/internalRoutes';
import TooltipComponent from '../Tooltip';
import { Actions, TableActionEnum } from '../../../Constants/commonConst';
import StatusForm from '../StatusComponent/StatusForm';
import {
  approveStatusOptions,
  bookingStatusOptions,
  enableStatusOptions,
  vehicleStatusOptions,
} from '../../../Utils/enums';
import { URLS } from '../../../Constants/urls';
import ApplicationString from '../../../Constants/applicationString';
import PopularTagForm from '../PopularTagComponent';

interface TableActionsProps {
  onEdit?: () => void;
  editId?: number;
  onDelete?: () => void;
  onView?: () => void;
  onApprove?: () => void;
  onEnable?: () => void;
  onPasswordReset?: () => void;
  isEnabled: boolean;
  isPopular: boolean;
  popularStatus: boolean;
  statusDefaultValue?: string;
  isOwnerApproved: boolean;
  onStatusChange?: (
    isEnabledValue: string,
    statusKey: string,
    statusUrl: string,
    successMsg: string,
    failureMsg: string
  ) => void;
  onPopularStatusChange?: (
    isPopularValue: boolean,
    statusKey: string,
    statusUrl: string,
    successMsg: string,
    failureMsg: string
  ) => void;
  statusBool?: boolean;
  statusBooking?: boolean;
  editBool?: boolean;
  approveBool?: boolean;
  viewBool?: boolean;
  enableBool?: boolean;
  resetPassBool?: boolean;
  deleteBool?: boolean;
  text: string;
  textApprove?: string;
  setZIndex: (value: boolean) => void;
}
interface ActionTypes {
  value: string;
  message: string;
}

const TableActions: React.FC<TableActionsProps> = ({
  onEdit,
  editId,
  onDelete,
  onView,
  onApprove,
  onEnable,
  onStatusChange,
  onPopularStatusChange,
  onPasswordReset,
  isEnabled,
  isPopular,
  popularStatus,
  statusDefaultValue = '',
  isOwnerApproved,
  statusBool,
  statusBooking,
  editBool,
  approveBool,
  viewBool,
  enableBool,
  deleteBool,
  resetPassBool,
  text,
  textApprove = '',
  setZIndex,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showStatusConfirm, setShowStatusConfirm] = useState(false);
  const [showPopularStatusConfirm, setShowPopularStatusConfirm] =
    useState(false);
  const [bookingStatusConfirm, setShowBookingStatusConfirm] = useState(false);
  const [enableStatusConfirm, setShowEnableStatusConfirm] = useState(false);
  const [approveStatusConfirm, setShowApproveStatusConfirm] = useState(false);
  const [currentAction, setCurrentAction] = useState({
    value: '',
    message: '',
  });
  // const [statusKeyValue, setStatusKeyValue] = useState<string>('');

  const handleEdit = (): void => {
    if (onEdit) {
      setShowConfirm(true);
    }
  };
  const handleView = (): void => {
    if (onView) {
      onView();
    }
  };

  const handleConfirmation = (action: ActionTypes): void => {
    setCurrentAction({
      ...action,
      message:
        action.value === TableActionEnum.approve
          ? action.message.replace('#{textApprove}', textApprove?.toLowerCase())
          : action.message.replace('#{text}', text?.toLowerCase()),
    });
    setShowConfirm(true);
    setZIndex(true);
  };
  const handleConfirm = () => {
    setShowConfirm(false);
    setZIndex(false);
    switch (currentAction.value) {
      case TableActionEnum.delete:
        if (onDelete) onDelete();
        break;
      case TableActionEnum.approve:
        if (onApprove) onApprove();
        break;
      case TableActionEnum.enabled:
        if (onEnable) onEnable();
        break;
      case TableActionEnum.resetPassword:
        if (onPasswordReset) onPasswordReset();
        break;
      default:
        break;
    }
  };

  const handleVehicleStatusOpenConfirm = (): void => {
    setShowStatusConfirm(true);
    setZIndex(true);
  };
  const handleVehicleStatusConfirm = (statusValue: string) => {
    if (onStatusChange) {
      onStatusChange(
        statusValue,
        URLS.VEHICLES_MANAGEMENT_STATUS,
        ApplicationString.module.statusModalObject.vehicleStatus.statusKey,
        ApplicationString.module.statusModalObject.vehicleStatus.successMsg,
        ApplicationString.module.statusModalObject.vehicleStatus.failureMsg
      );
    }
    setShowStatusConfirm(false);
    setZIndex(false);
  };
  const handleVehiclePopularStatusOpenConfirm = (): void => {
    setShowPopularStatusConfirm(true);
    setZIndex(true);
  };
  const handleVehiclePopularStatusConfirm = (statusValue: boolean) => {
    if (onPopularStatusChange) {
      onPopularStatusChange(
        statusValue,
        URLS.VEHICLES_MANAGEMENT_IS_POPULAR,
        ApplicationString.module.statusModalObject.vehicleStatus.statusKey,
        ApplicationString.module.statusModalObject.vehicleStatus.successMsg,
        ApplicationString.module.statusModalObject.vehicleStatus.failureMsg
      );
    }
    setShowPopularStatusConfirm(false);
    setZIndex(false);
  };
  const handleBookingStatusOpenConfirm = (): void => {
    setShowBookingStatusConfirm(true);
    setZIndex(true);
  };
  const handleBookingStatusConfirm = (statusValue: string) => {
    if (onStatusChange) {
      onStatusChange(
        statusValue,
        URLS.BOOKING_MANAGEMENT_STATUS,
        ApplicationString.module.statusModalObject.bookingStatus.statusKey,
        ApplicationString.module.statusModalObject.bookingStatus.successMsg,
        ApplicationString.module.statusModalObject.bookingStatus.failureMsg
      );
    }
    setShowBookingStatusConfirm(false);
    setZIndex(false);
  };
  const handleStatusEnableOpenConfirm = (): void => {
    setShowEnableStatusConfirm(true);
    setZIndex(true);
  };
  const handleStatusApproveOpenConfirm = (): void => {
    setShowApproveStatusConfirm(true);
    setZIndex(true);
  };

  const handleEnableStatusConfirm = (statusValue: string) => {
    if (onStatusChange) {
      onStatusChange(
        statusValue,
        URLS.USER_MANAGEMENT_ENABLE,
        ApplicationString.module.statusModalObject.userStatus.statusKey,
        ApplicationString.module.statusModalObject.userStatus.successMsg,
        ApplicationString.module.statusModalObject.userStatus.failureMsg
      );
    }
    setShowEnableStatusConfirm(false);
    setZIndex(false);
  };

  const handleApproveStatusConfirm = (statusValue: string): void => {
    if (onStatusChange) {
      onStatusChange(
        statusValue,
        URLS.USER_MANAGEMENT_APPROVE_OWNER,
        ApplicationString.module.statusModalObject.ownerStatus.statusKey,
        ApplicationString.module.statusModalObject.ownerStatus.successMsg,
        ApplicationString.module.statusModalObject.ownerStatus.failureMsg
      );
    }
    setShowApproveStatusConfirm(false);
    setZIndex(false);
  };
  const handleCancel = (): void => {
    setShowConfirm(false);
    setZIndex(false);
  };
  const handleVehicleStatusCancel = (): void => {
    setShowStatusConfirm(false);
    setZIndex(false);
  };
  const handleVehiclePopularStatusCancel = (): void => {
    setShowPopularStatusConfirm(false);
    setZIndex(false);
  };
  const handleBookingStatusCancel = (): void => {
    setShowBookingStatusConfirm(false);
    setZIndex(false);
  };
  const handleEnableStatusCancel = (): void => {
    setShowEnableStatusConfirm(false);
    setZIndex(false);
  };
  const handleApproveStatusCancel = (): void => {
    setShowApproveStatusConfirm(false);
    setZIndex(false);
  };

  return (
    <>
      <div className="flex gap-2 justify-center">
        {enableBool && (
          <button
            type="button"
            aria-label="enable"
            onClick={() => {
              // setStatusKeyValue('enable');
              handleStatusEnableOpenConfirm();
            }}
            className="text-black-500 z-10 hover:text-black-400 focus:outline-none"
          >
            <TooltipComponent
              message={ApplicationString.module.tableAction.confirmationMessage.enable.value.replace(
                '#{text}',
                text
              )}
            >
              <UserEnableSvg />
            </TooltipComponent>
          </button>
        )}

        {viewBool && (
          <button
            type="button"
            aria-label="view"
            onClick={handleView}
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
          >
            <TooltipComponent
              message={ApplicationString.module.tableAction.confirmationMessage.view.value.replace(
                '#{text}',
                text
              )}
            >
              <ViewSvgIcon />
            </TooltipComponent>
          </button>
        )}
        {editBool && (
          <Link
            to={InternalRoute.VehicleUpdate.replace(':vehicleId', `${editId}`)}
          >
            <button
              type="button"
              aria-label="edit"
              onClick={handleEdit}
              className="text-blue-500 hover:text-blue-700 focus:outline-none"
            >
              <EditSvgIcon />
            </button>
          </Link>
        )}
        {approveBool && (
          <button
            type="button"
            aria-label="approve"
            onClick={() => {
              // setStatusKeyValue('approve');
              handleStatusApproveOpenConfirm();
            }}
            className="text-black-500 duration-300 ease-in-out hover:text-primary"
          >
            <TooltipComponent
              message={ApplicationString.module.tableAction.confirmationMessage.approve.value.replace(
                '#{textApprove}',
                textApprove
              )}
            >
              <UserApproveSvg />
            </TooltipComponent>
          </button>
        )}
        {resetPassBool && (
          <button
            type="button"
            aria-label="reset_password"
            onClick={() => {
              handleConfirmation(Actions.RESETPASSWORD);
            }}
            className="text-black-500 hover:text-black-400 focus:outline-none"
          >
            <TooltipComponent
              message={
                ApplicationString.module.tableAction.confirmationMessage
                  .resetPassword.value
              }
            >
              <ResetPasswordSvg />
            </TooltipComponent>
          </button>
        )}

        {deleteBool && (
          <button
            type="button"
            aria-label="delete"
            onClick={() => {
              handleConfirmation(Actions.DELETE);
            }}
            className="text-red-500 hover:text-red-700 focus:outline-none"
          >
            <TooltipComponent
              message={ApplicationString.module.tableAction.confirmationMessage.delete.value.replace(
                '#{text}',
                text
              )}
            >
              <DeleteSvgIcon />
            </TooltipComponent>
          </button>
        )}

        {statusBool && (
          <button
            type="button"
            aria-label="status"
            onClick={() => {
              handleVehicleStatusOpenConfirm();
            }}
            className="text-black-500 hover:text-black-400 focus:outline-none"
          >
            <TooltipComponent
              message={ApplicationString.module.tableAction.confirmationMessage.changeStatus.value.replace(
                '#{text}',
                text
              )}
            >
              <StatusChangeSvg />
            </TooltipComponent>
          </button>
        )}
        {popularStatus && (
          <button
            type="button"
            aria-label="status"
            onClick={() => {
              handleVehiclePopularStatusOpenConfirm();
            }}
            className="text-black-500 hover:text-black-400 focus:outline-none"
          >
            <TooltipComponent
              message={ApplicationString.module.tableAction.confirmationMessage.changePopularStatus.value.replace(
                '#{text}',
                text
              )}
            >
              <PopularStatusChangeSvg />
            </TooltipComponent>
          </button>
        )}
        {statusBooking && (
          <button
            type="button"
            aria-label="status"
            onClick={() => {
              handleBookingStatusOpenConfirm();
            }}
            className="text-black-500 hover:text-black-400 focus:outline-none"
          >
            <TooltipComponent
              message={ApplicationString.module.tableAction.confirmationMessage.changeStatus.value.replace(
                '#{text}',
                text
              )}
            >
              <StatusChangeSvg />
            </TooltipComponent>
          </button>
        )}
      </div>
      {showConfirm && (
        <ConfirmationPopup onConfirm={handleConfirm} onCancel={handleCancel}>
          <p className="text-lg font-medium mb-4">{currentAction.message}</p>
        </ConfirmationPopup>
      )}
      {showPopularStatusConfirm && (
        <PopularTagForm
          statusTitle={ApplicationString.module.vehicle.action1.title}
          statusSubTitle={ApplicationString.module.vehicle.action1.form.label}
          isPopular={isPopular}
          onConfirm={(statusValue: boolean) => {
            handleVehiclePopularStatusConfirm(statusValue);
          }}
          onCancel={handleVehiclePopularStatusCancel}
        />
      )}
      {showStatusConfirm && (
        <StatusForm
          statusTitle={ApplicationString.module.vehicle.action.title}
          statusSubTitle={ApplicationString.module.vehicle.action.form.label}
          statusOptions={vehicleStatusOptions}
          isEnabled={isEnabled}
          // statusKey={statusKeyValue}
          onConfirm={(statusValue: string) => {
            handleVehicleStatusConfirm(statusValue);
          }}
          onCancel={handleVehicleStatusCancel}
        />
      )}
      {bookingStatusConfirm && (
        <StatusForm
          statusTitle={ApplicationString.module.booking.action.title}
          statusSubTitle={ApplicationString.module.booking.action.form.label}
          statusOptions={bookingStatusOptions}
          isEnabled={statusDefaultValue}
          // statusKey={statusKeyValue}
          onConfirm={(statusValue: string) => {
            handleBookingStatusConfirm(statusValue);
          }}
          onCancel={handleBookingStatusCancel}
        />
      )}
      {enableStatusConfirm && (
        <StatusForm
          statusTitle={
            ApplicationString.module.user.table.actions.title.enableUser
          }
          statusSubTitle={
            ApplicationString.module.user.table.actions.title.subTitle
              .enableUser
          }
          statusOptions={enableStatusOptions}
          isEnabled={isEnabled}
          // statusKey={statusKeyValue}
          onConfirm={(statusValue: string) => {
            handleEnableStatusConfirm(statusValue);
          }}
          onCancel={handleEnableStatusCancel}
        />
      )}
      {approveStatusConfirm && (
        <StatusForm
          statusTitle={
            ApplicationString.module.user.table.actions.title.approveOwner
          }
          statusSubTitle={
            ApplicationString.module.user.table.actions.title.subTitle
              .approveOwner
          }
          statusOptions={approveStatusOptions}
          isEnabled={isOwnerApproved}
          // statusKey={statusKeyValue}
          onConfirm={(statusValue: string) => {
            handleApproveStatusConfirm(statusValue);
          }}
          onCancel={handleApproveStatusCancel}
        />
      )}
    </>
  );
};

// TableActions.defaultProps = {
//   onView: () => {},
//   onEdit: () => {},
//   onDelete: () => {},
//   onEnable: () => {},
//   onApprove: () => {},
//   onPasswordReset: () => {},
//   onStatusChange: () => {},
//   editId: 0,
//   statusBool: false,
//   editBool: false,
//   approveBool: false,
//   viewBool: false,
//   enableBool: false,
//   resetPassBool: false,
//   deleteBool: false,
//   textApprove: '',
// };

export default TableActions;
