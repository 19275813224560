import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ProtectedRoute from './protectedRoutes';
import {
  getLocalStorageAccountInfo,
  removeLocalStorageAccountInfo,
} from '../Utils/utils';
import RouteConfig from './appRouteConfig';
import InternalRoute from '../Utils/internalRoutes';
import { LoggedInUserInfoDataType } from '../Interfaces/Login/login.interface';
import { localStorageEnums } from '../Utils/enums';

const AppRouter: React.FC = () => {
  const userInfo = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const match = useLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (match.pathname === InternalRoute.Logout) {
      removeLocalStorageAccountInfo(dispatch, navigate);
    }
  }, [dispatch, match, navigate]);
  return (
    <>
      {/* {match.pathname === '/login' ? (
          isNullOrEmpty(userID) ? (
            <Routes>
              <Route path="/login" element={<LoginScreen />} />
            </Routes>
          ) : (
            <ProtectedRoute requiredRole={['master']}>
              <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/dashboard" element={<HomeScreen />} />
              </Routes>
            </ProtectedRoute>
          )
        ) : (
          <ProtectedRoute requiredRole={['master']}>
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/dashboard" element={<HomeScreen />} />
              <Route path="*" element={<Notfound />} />
              <Route path="/unauthorized" element={<NotAuthorized />} />
            </Routes>
          </ProtectedRoute>
        )} */}

      <Routes>
        {RouteConfig?.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute
                allowedLayout={route.allowedLayout}
                isProtectedRoute={route.isProtectedRoute}
                userToken={userInfo?.accessToken}
                element={route.element}
                allowedRoles={route.allowedRoles}
              />
            }
          />
        ))}
      </Routes>
    </>
  );
};

export default AppRouter;
