import React from 'react';
import ApplicationString from '../../../Constants/applicationString';

interface Props {
  totalRecords: number;
}

const TotalRecords: React.FC<Props> = ({ totalRecords }) => {
  return (
    <div className=" flex justify-end items-center mt-4 px-4 p-1 pl-2 pr-2 me-2 text-gray-700 shadow-4 align-middle text-center w-auto min-w-fit h-auto bg-white rounded">
      {ApplicationString.table.pagination.totalPages}: {totalRecords}
    </div>
  );
};

export default TotalRecords;
