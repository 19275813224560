import { combineReducers } from 'redux';
import authReducer from './authReducer';
import SpinnerReducer from './spinnerSlice';
import UsersReducer from './users';
import LoaderReducer from './loader';
import settingsReducer from './setting';

export default combineReducers({
  auth: authReducer,
  spinner: SpinnerReducer,
  users: UsersReducer,
  loader: LoaderReducer,
  setting: settingsReducer,
});
