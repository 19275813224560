import React from 'react';

interface AvatarProps {
  source: string;
}

const AvatarComponent: React.FC<AvatarProps> = ({ source }) => {
  return (
    <div className="flex-space-x-2 overflow-hidden object-cover">
      <img
        className="inline-block h-12 w-12 rounded-full ring-2 ring-white"
        src={source}
        alt=""
      />
    </div>
  );
};

export default AvatarComponent;
