/* eslint-disable react-hooks/exhaustive-deps */
// TODO: When I add this dependency specified in eslint warning, my API is calling in infinite time.

import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import { useDispatch } from 'react-redux';
import { InputActionMeta } from 'react-select';
import VehicleListingComponent from '../../Components/VehicleListing';
import { HIDE_LOADER, SHOW_LOADER } from '../../Constants/actionType';
import { ApiRequest } from '../../Api/api';
import { URLS } from '../../Constants/urls';
import {
  buildQueryString,
  createSelectOptions,
  handleApiError,
} from '../../Utils/utils';
import { useToast } from '../../Services/ToastService';
import {
  IApiErrorResponse,
  ISelectOptions,
} from '../../Interfaces/Common/api.interface';
import { IVehiclesData } from '../../Interfaces/Vehicles';
import {
  IPaginationValues,
  ISortParamValues,
} from '../../Interfaces/interfaces';
import {
  DefaultBooleanValue,
  DefaultPaginationValues,
  DefaultSortParams,
} from '../../Constants/commonConfig';
import { FormValues } from '../../Interfaces/form';
import {
  userQuery,
  vehiclesDataInitialValues,
} from '../../Constants/commonConst';
import { IAllUsersData } from '../../Interfaces/Users';
import ApplicationString from '../../Constants/applicationString';

const VehicleListingContainer: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [allVehiclesData, setAllVehiclesData] = useState<IVehiclesData[]>([]);
  const [pagination, setPagination] = useState<IPaginationValues>({
    page: DefaultPaginationValues.PAGE,
    pageSize: DefaultPaginationValues.PAGE_SIZE,
    totalItems: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [paginationSortAndSearchTrigger, setPaginationSortAndSearchTrigger] =
    useState<boolean>(false);
  const [sortParams, setSortParams] = useState<ISortParamValues>({
    sortField: 'id',
    sortDirection: DefaultSortParams.DESC,
  });
  const [searchValues, setSearchValues] = useState({});
  const [isVehicleDetailViewVisible, setIsVehicleDetailViewVisible] =
    useState<boolean>(DefaultBooleanValue);
  const [vehicleData, setVehicleData] = useState<IVehiclesData>(
    vehiclesDataInitialValues
  );

  // User data for filter search
  const [allUsersDataOptions, setAllUsersDataOptions] = useState<
    ISelectOptions[]
  >([]);
  const [isLoadingUserData, setIsLoadingUserData] = useState<boolean>(false);

  const getAllVehicles = async (searchParams: object): Promise<void> => {
    try {
      const sortField = sortParams.sortField || '';
      const sortDirection = sortParams.sortDirection || '';
      const searchValueParams = buildQueryString(searchParams);
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.get(
        `${URLS.VEHICLES_MANAGEMENT}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&sortBy=${sortField}&sortDirection=${sortDirection}&${searchValueParams}`
      );
      if (response?.status === HttpStatusCode.Ok) {
        setAllVehiclesData(response?.data?.data);
        setPagination({
          ...pagination,
          page: response?.data?.pageNumber,
          pageSize: response?.data?.pageSize,
          totalItems: response?.data?.total,
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };

  // Get user data for filter search
  const getAllUsers = async (searchParams: object): Promise<void> => {
    try {
      const searchValueParams = buildQueryString(searchParams);
      const sortField = userQuery.id;
      const sortDirection = sortParams.sortDirection || '';
      const response: AxiosResponse = await ApiRequest.get(
        `${URLS.USER_MANAGEMENT}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&sortBy=${sortField}&sortDirection=${sortDirection}&${searchValueParams}`
      );
      if (response?.status === HttpStatusCode.Ok) {
        setAllUsersDataOptions(
          createSelectOptions<IAllUsersData, IAllUsersData[], 'id', 'name'>(
            response?.data?.data,
            'id',
            'name'
          )
        );
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      setIsLoadingUserData(false);
    }
  };

  // Dynamic search for user
  const handleFilterUserSearch = (
    newValue: string,
    actionMeta: InputActionMeta
  ): void => {
    if (actionMeta.action === 'input-change') {
      if (newValue.trim() === '') {
        getAllUsers({});
      } else {
        setIsLoadingUserData(true);
        getAllUsers({ name: newValue });
      }
    }
  };

  // Dynamic search for all vehicle filters except for user
  const handleFilterDropdownSearch = (
    newValue: string,
    actionMeta: InputActionMeta
  ): void => {
    if (actionMeta.action === 'input-change') {
      if (newValue.trim() === '') {
        // getAllVehicles({});
      } else {
        setIsLoadingUserData(true);
        getAllVehicles({ name: newValue });
      }
    }
  };

  useEffect(() => {
    getAllVehicles(searchValues);
    getAllUsers(searchValues);
  }, []);

  useEffect(() => {
    if (paginationSortAndSearchTrigger) {
      getAllVehicles(searchValues);
    }
    setPaginationSortAndSearchTrigger(false);
  }, [paginationSortAndSearchTrigger]);

  const handleSearch = (values: FormValues): void => {
    setSearchValues(values);
    setPaginationSortAndSearchTrigger(true);
  };

  const handlePageChange = (pageNumber: number): void => {
    setPagination({ ...pagination, page: pageNumber });
    setPaginationSortAndSearchTrigger(true);
  };

  const handlePageSizeChange = (newSize: number): void => {
    setPagination({ ...pagination, page: 1, pageSize: newSize });
    setPaginationSortAndSearchTrigger(true);
  };
  const handleVehicleStatusClick = async (
    vehicleId: number,
    isEnabledValue: string
  ): Promise<void> => {
    try {
      const isEnabledBooleanValue = isEnabledValue === 'true';
      const stringVehicleId = vehicleId.toString();
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.put(
        URLS.VEHICLES_MANAGEMENT_STATUS.replace(
          '#{vehicleId}',
          stringVehicleId
        ),
        { isEnabled: isEnabledBooleanValue }
      );
      if (response?.status === HttpStatusCode.NoContent) {
        toast.success(ApplicationString.module.vehicle.message.vehicleEnabled);
        setPaginationSortAndSearchTrigger(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };

  const handleVehiclePopularStatusClick = async (
    vehicleId: number,
    isPopularValue: boolean
  ): Promise<void> => {
    try {
      const isPopularBooleanValue = isPopularValue;
      const stringVehicleId = vehicleId.toString();
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.put(
        URLS.VEHICLES_MANAGEMENT_IS_POPULAR.replace(
          '#{vehicleId}',
          stringVehicleId
        ),
        { isPopular: isPopularBooleanValue }
      );
      if (response?.status === HttpStatusCode.NoContent) {
        toast.success(ApplicationString.module.vehicle.message.vehiclePopular);
        setPaginationSortAndSearchTrigger(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };

  const fetchVehicleById = async (vehicleId: number): Promise<void> => {
    try {
      const stringVehicleId = vehicleId.toString();
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.get(
        URLS.VEHICLES_MANAGEMENT_BY_ID.replace('#{vehicleId}', stringVehicleId)
      );
      if (response?.status === HttpStatusCode.Ok) {
        setVehicleData(response.data);
        setIsVehicleDetailViewVisible(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };

  const handleVehicleViewClick = (data: IVehiclesData): void => {
    fetchVehicleById(data?.id);
  };

  return (
    <VehicleListingComponent
      allVehiclesData={allVehiclesData}
      isLoadingUserData={isLoadingUserData}
      allUsersDataOptions={allUsersDataOptions}
      handleFilterUserSearch={handleFilterUserSearch}
      handleFilterDropdownSearch={handleFilterDropdownSearch}
      pagination={pagination}
      setPagination={setPagination}
      setPaginationSortAndSearchTrigger={setPaginationSortAndSearchTrigger}
      setSortParams={setSortParams}
      sortParams={sortParams}
      searchValues={searchValues}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      handleSearch={(values: FormValues) => {
        handleSearch(values);
      }}
      handleVehicleViewClick={(data: IVehiclesData) =>
        handleVehicleViewClick(data)
      }
      isVehicleDetailViewVisible={isVehicleDetailViewVisible}
      setIsVehicleDetailViewVisible={setIsVehicleDetailViewVisible}
      vehicleData={vehicleData}
      handleVehicleStatusClick={(vehicleId: number, isEnabledValue: string) => {
        handleVehicleStatusClick(vehicleId, isEnabledValue);
      }}
      handleVehiclePopularStatusClick={(
        vehicleId: number,
        isPopularValue: boolean
      ) => {
        handleVehiclePopularStatusClick(vehicleId, isPopularValue);
      }}
    />
  );
};

export default VehicleListingContainer;
