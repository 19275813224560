/* eslint-disable react-hooks/exhaustive-deps */
// TODO: When I add this dependency specified in eslint warning, my API is calling in infinite time.

import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import { useDispatch } from 'react-redux';
import { InputActionMeta } from 'react-select';
import { HIDE_LOADER, SHOW_LOADER } from '../../Constants/actionType';
import { ApiRequest } from '../../Api/api';
import { URLS } from '../../Constants/urls';
import {
  buildQueryString,
  createSelectOptions,
  handleApiError,
} from '../../Utils/utils';
import { useToast } from '../../Services/ToastService';
import {
  IApiErrorResponse,
  ISelectOptions,
} from '../../Interfaces/Common/api.interface';
import {
  IPaginationValues,
  ISortParamValues,
} from '../../Interfaces/interfaces';
import {
  DefaultBooleanValue,
  DefaultPaginationValues,
  DefaultSortParams,
} from '../../Constants/commonConfig';
import { FormValues } from '../../Interfaces/form';
import {
  bookingDetailsDataInitialValue,
  userQuery,
} from '../../Constants/commonConst';
import ApplicationString from '../../Constants/applicationString';
import BookingComponent from '../../Components/Bookings';
import { IBookingsData } from '../../Interfaces/Bookings';
import { IAllUsersData } from '../../Interfaces/Users';

const BookingListContainer: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [allBookingData, setAllBookingData] = useState<IBookingsData[]>([]);
  const [pagination, setPagination] = useState<IPaginationValues>({
    page: DefaultPaginationValues.PAGE,
    pageSize: DefaultPaginationValues.PAGE_SIZE,
    totalItems: DefaultPaginationValues.TOTAL_PAGES,
  });
  const [paginationSortAndSearchTrigger, setPaginationSortAndSearchTrigger] =
    useState<boolean>(false);
  const [sortParams, setSortParams] = useState<ISortParamValues>({
    sortField: 'id',
    sortDirection: DefaultSortParams.DESC,
  });
  const [searchValues, setSearchValues] = useState({});
  const [isVehicleDetailViewVisible, setIsVehicleDetailViewVisible] =
    useState<boolean>(DefaultBooleanValue);
  const [bookingData, setBookingData] = useState<IBookingsData>(
    bookingDetailsDataInitialValue
  );

  // User data for filter search
  const [allUsersDataOptions, setAllUsersDataOptions] = useState<
    ISelectOptions[]
  >([]);

  const [isLoadingUserData, setIsLoadingUserData] = useState<boolean>(false);
  const [isLoadingVehicleData, setIsLoadingVehicleData] =
    useState<boolean>(false);

  const getAllBookings = async (searchParams: object): Promise<void> => {
    try {
      const sortField = sortParams.sortField || '';
      const sortDirection = sortParams.sortDirection || '';
      const searchValueParams = buildQueryString(searchParams);
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.get(
        `${URLS.BOOKING_MANAGEMENT}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&sortBy=${sortField}&sortDirection=${sortDirection}&${searchValueParams}`
      );
      if (response?.status === HttpStatusCode.Ok) {
        setAllBookingData(response?.data?.data);
        setPagination({
          ...pagination,
          page: response?.data?.pageNumber,
          pageSize: response?.data?.pageSize,
          totalItems: response?.data?.total,
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };

  // const getAllVehicles = async (searchParams: object): Promise<void> => {
  //   try {
  //     const sortField = sortParams.sortField || '';
  //     const sortDirection = sortParams.sortDirection || '';
  //     const searchValueParams = buildQueryString(searchParams);
  //     dispatch({ type: SHOW_LOADER });
  //     const response: AxiosResponse = await ApiRequest.get(
  //       `${URLS.VEHICLES_MANAGEMENT}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&sortBy=${sortField}&sortDirection=${sortDirection}&${searchValueParams}`
  //     );
  //     if (response?.status === HttpStatusCode.Ok) {
  //     }
  //   } catch (error) {
  //     const axiosError = error as AxiosError<IApiErrorResponse>;
  //     handleApiError(axiosError, toast);
  //   } finally {
  //     dispatch({ type: HIDE_LOADER });
  //   }
  // };

  // Get user data for filter search
  const getAllUsers = async (searchParams: object): Promise<void> => {
    try {
      const sortField = userQuery.id;
      const sortDirection = sortParams.sortDirection || '';
      const searchValueParams = buildQueryString(searchParams);

      const response: AxiosResponse = await ApiRequest.get(
        `${URLS.USER_MANAGEMENT}?pageNumber=${pagination.page}&pageSize=${pagination.pageSize}&sortBy=${sortField}&sortDirection=${sortDirection}&${searchValueParams}`
      );
      if (response?.status === HttpStatusCode.Ok) {
        setAllUsersDataOptions(
          createSelectOptions<IAllUsersData, IAllUsersData[], 'id', 'name'>(
            response?.data?.data,
            'id',
            'name'
          )
        );
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      setIsLoadingUserData(false);
      setIsLoadingVehicleData(false);
    }
  };

  // Dynamic search for user
  const handleFilterUserSearch = (
    newValue: string,
    actionMeta: InputActionMeta
  ): void => {
    if (actionMeta.action === 'input-change') {
      if (newValue.trim() === '') {
        getAllUsers({});
        // getAllVehicles({});
      } else {
        setIsLoadingUserData(true);
        setIsLoadingVehicleData(true);
        getAllUsers({ name: newValue });
        // getAllVehicles({ title: newValue });
      }
    }
  };

  // Dynamic search for all filters except for user
  const handleFilterDropdownSearch = (
    newValue: string,
    actionMeta: InputActionMeta
  ): void => {
    if (actionMeta.action === 'input-change') {
      if (newValue.trim() === '') {
        // getAllBookings({});
      } else {
        setIsLoadingUserData(true);
        getAllBookings({ name: newValue });
      }
    }
  };

  useEffect(() => {
    getAllBookings(searchValues);
    getAllUsers(searchValues);
    // getAllVehicles(searchValues);
  }, []);

  useEffect(() => {
    if (paginationSortAndSearchTrigger) {
      getAllBookings(searchValues);
    }
    setPaginationSortAndSearchTrigger(false);
  }, [paginationSortAndSearchTrigger]);

  const handleSearch = (values: FormValues): void => {
    setSearchValues(values);
    setPaginationSortAndSearchTrigger(true);
  };

  const handlePageChange = (pageNumber: number): void => {
    setPagination({ ...pagination, page: pageNumber });
    setPaginationSortAndSearchTrigger(true);
  };

  const handlePageSizeChange = (newSize: number): void => {
    setPagination({ ...pagination, page: 1, pageSize: newSize });
    setPaginationSortAndSearchTrigger(true);
  };
  const handleBookingStatusClick = async (
    bookingId: number,
    typeValue: string
  ): Promise<void> => {
    try {
      const isStatusTypeValue = typeValue?.toString();
      const stringbookingId = bookingId?.toString();
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.put(
        URLS.BOOKING_MANAGEMENT_STATUS.replace('#{bookingId}', stringbookingId),
        { type: isStatusTypeValue }
      );
      if (response?.status === HttpStatusCode.NoContent) {
        toast.success(ApplicationString.module.booking.message.bookingEnabled);
        setPaginationSortAndSearchTrigger(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };

  const fetchBookingById = async (bookingId: number): Promise<void> => {
    try {
      const stringBookingId = bookingId.toString();
      dispatch({ type: SHOW_LOADER });
      const response: AxiosResponse = await ApiRequest.get(
        URLS.BOOKING_MANAGEMENT_BY_ID.replace('#{bookingId}', stringBookingId)
      );
      if (response?.status === HttpStatusCode.Ok) {
        setBookingData(response.data);
        setIsVehicleDetailViewVisible(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError, toast);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  };

  const handleBookingViewClick = (data: IBookingsData): void => {
    fetchBookingById(data?.id);
  };

  return (
    <BookingComponent
      allBookingData={allBookingData}
      isLoadingUserData={isLoadingUserData}
      isLoadingVehicleData={isLoadingVehicleData}
      allUsersDataOptions={allUsersDataOptions}
      // allVehiclesDataOptions={allVehiclesDataOptions}
      handleFilterUserSearch={handleFilterUserSearch}
      pagination={pagination}
      setPagination={setPagination}
      setPaginationSortAndSearchTrigger={setPaginationSortAndSearchTrigger}
      setSortParams={setSortParams}
      sortParams={sortParams}
      searchValues={searchValues}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      handleSearch={(values: FormValues) => {
        handleSearch(values);
      }}
      handleBookingViewClick={(data: IBookingsData) =>
        handleBookingViewClick(data)
      }
      isVehicleDetailViewVisible={isVehicleDetailViewVisible}
      setIsVehicleDetailViewVisible={setIsVehicleDetailViewVisible}
      bookingData={bookingData}
      handleBookingStatusClick={(bookingId: number, typeValue: string) => {
        handleBookingStatusClick(bookingId, typeValue);
      }}
      handleFilterDropdownSearch={handleFilterDropdownSearch}
    />
  );
};

export default BookingListContainer;
