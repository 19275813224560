import { ErrorMessage, Formik, Field, Form } from 'formik';
import React from 'react';
import ApplicationString from '../../../../Constants/applicationString';

interface StatusOption {
  value: string;
  label: string;
}
interface StatusFormComponentProps {
  statusTitle: string;
  statusSubTitle: string;
  statusOptions: StatusOption[];
  isEnabled: boolean | string;
  onConfirm: (statusValue: string) => void;
  onCancel: () => void;
}
const StatusForm = ({
  statusTitle,
  statusSubTitle,
  statusOptions,
  isEnabled,
  onConfirm,
  onCancel,
}: StatusFormComponentProps): JSX.Element => {
  const initialValues = {
    status: isEnabled?.toString(),
  };
  return (
    <div className="fixed z-10 inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-30 w-full">
      <div className="bg-white p-6 rounded-lg shadow-xl w-1/4">
        <p className="text-lg font-medium mb-4 text-wrap">{statusTitle}</p>
        <Formik
          initialValues={initialValues}
          validateOnChange
          enableReinitialize
          onSubmit={(values) => {
            onConfirm(values.status);
          }}
        >
          <Form>
            <div className="mt-5 grid gap-x-6 gap-y-8">
              <div className="w-full">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {statusSubTitle}
                </label>
                <div className="mt-2">
                  <Field
                    as="select"
                    name="status"
                    id="status"
                    autoComplete="status"
                    placeholder={
                      ApplicationString.module.vehicle.action.form.placeholder
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    {statusOptions.map((status: StatusOption) => (
                      <option key={status.value} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </Field>
                </div>
                <span className="mt-1 text-red-500">
                  <ErrorMessage name="name" />
                </span>
              </div>
            </div>
            <div className="mt-5 flex justify-end gap-4">
              <button
                type="button"
                onClick={onCancel}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {ApplicationString.table.actions.confirm.cancelButton}
              </button>
              <button
                type="submit"
                className="text-red-500 hover:text-red-700 focus:outline-none"
              >
                {ApplicationString.table.actions.confirm.confirmButton}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default StatusForm;
